import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "axios";
import SubmitButton from "common/components/Button/SubmitButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import ForkVersionForm from "common/containers/Forms/Version/ForkVersionForm";
import { useAuth } from "common/hooks/useAuth";
import { useProject } from "common/hooks/useProject";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useVersions } from "hooks/useVersions";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./ForkVersionPage.module.css";

export default function ForkVersionPage() {
  // Contexts
  const { currentUser } = useAuth();
  const { projectId, versionId } = useParams();
  const navigate = useNavigate();
  const { projectInfo } = useProject();
  const { versionsInfo } = useVersions();

  // States
  const [loading, setLoading] = useState(false);
  const [allProjectsInformation, setAllProjectsInformation] = useState([]);
  const [allUsersInformation, setAllUsersInformation] = useState({});
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form states
  const formId = useId();

  useEffect(() => {
    const controller = new AbortController();
    const fetchData = async () => {
      try {
        setLoading(true);
        await Promise.all([
          fetchUsersInformation(controller.signal),
          fetchProjectsNames(controller.signal),
        ]);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    return () => controller.abort();
  }, []);

  useEffect(() => {
    if (formData) {
      const payload = {
        source_project_id: formData.source_project_id,
        source_version_id: formData.source_version_id,
        destination_project_id: formData.destination_project_id,
        destination_version_name: formData.destination_version_name,
        owner_ids: formData.owner_ids,
        authorized_user_ids: formData.authorized_user_ids,
        user_id: currentUser.uid,
      };
      duplicateVersion(payload);
    }
  }, [formData]);

  const fetchUsersInformation = async (signal) => {
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getAllUserNames, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
        signal,
      });
      if (response.status === 200) {
        setAllUsersInformation(response.data);
      }
    } catch (error) {
      return ERRORS.error_133;
    }
  };

  const fetchProjectsNames = async (signal) => {
    try {
      setLoading(true);
      const response = await axios.get(BACKEND_URL_CONFIG.getAllProjectsNames, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
        signal,
      });
      if (response.status === 200) {
        setAllProjectsInformation(response.data);
      }
    } catch (error) {
      return ERRORS.error_162;
    }
  };

  const duplicateVersion = async (payload) => {
    try {
      setLoading(true);
      const response = await axios.post(
        BACKEND_URL_CONFIG.duplicateVersion,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response?.status === 201) {
        toast.success("Votre version a été dupliquée.");
        navigate(`/projects/${payload.destination_project_id}`, {
          replace: true,
        });
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      }
      toast.error(ERRORS.error_161);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/projects"
                label="Projets"
              />
              <CustomBreadcrumb
                component="a"
                href={`/projects/${projectId}`}
                label={projectInfo?.name}
              />
              <CustomBreadcrumb
                component="a"
                href={`/projects/${projectId}/version/${versionId}/run/load`}
                label={versionsInfo?.name}
              />
              <CustomBreadcrumb label="Dupliquer une version" href="#" />
            </Breadcrumbs>
          </div>
          <div className={styles["form-container"]}>
            <div className={styles["title-wrapper"]}>
              <div className={styles.title}>Dupliquer une version</div>
            </div>
            <div className={styles["form-wrapper"]}>
              <div className={styles["content"]}>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <ForkVersionForm
                    sourceProjectId={projectId}
                    sourceVersionId={versionId}
                    formId={formId}
                    setFormData={setFormData}
                    allUsersInformation={allUsersInformation}
                    allProjectsInformation={allProjectsInformation}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </div>
            </div>
            <div className={styles["stepper-action-buttons-wrapper"]}>
              <SubmitButton
                disabled={isSubmitting}
                style={{ backgroundColor: "#142637cc", color: "white" }}
                formId={formId}
                value="Enregistrer"
              />
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
