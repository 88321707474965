import { useAuth } from "common/hooks/useAuth";
import { db } from "core/firebase";
import { ERRORS } from "errors";
import { doc, getDoc } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const ProtectedStepper = () => {
  const { currentUser, isUserAdmin } = useAuth();
  const { datasetId } = useParams();
  const [datasetInformation, setDatasetInformation] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const checkDataset = async () => {
      // if there is any changes on dataset name
      try {
        const datasetRef = doc(db, "datasets", datasetId);
        const docRef = await getDoc(datasetRef);

        if (docRef.exists()) {
          if (
            !docRef.data().owner_ids.includes(currentUser.uid) &&
            !isUserAdmin
          ) {
            toast.error(ERRORS.error_114);
            navigate("/datasets");
          }

          setDatasetInformation(docRef.data());
        }
      } catch (error) {
        toast.error(error.message);
        navigate("/datasets");
      }
    };
    checkDataset();
  }, [datasetId, currentUser, navigate, isUserAdmin]);

  return <>{datasetInformation ? <Outlet /> : null}</>;
};

export default ProtectedStepper;
