const ERRORS = {
  error_100:
    "Une erreur est survenue lors de l'enregistrement de l'utilisation dans la DB. (Error code 100)",
  error_101:
    "Une erreur est survenue lors de la génération d'un access token pour GCP. (Error code 101)",
  error_102: "Votre token est expiré. (Error code 102)",
  error_103: "Votre login est échoué. (Error code 103)",
  error_104:
    "Une erreur est survenue lors de l'upload des fichiers vers GCP. (Error code 104)",
  error_105:
    "Une erreur est survenue lors d'initialiser une session d'upload. (Error code 105)",
  error_106:
    "Une erreur est survenue lors de la création du dataset. (Error code 106)",
  error_107: "Le nom du dataset ne peut pas dépasser 99 characters.",
  error_108: "Le nom du dataset ne peut pas être vide.",
  error_109: "Le nom de ce dataset existe déjà dans la base de données.",
  error_110: "Le dataset id n'existe pas.",
  error_111:
    "Une erreur est survenue lors de l'extraction de l'aperçu du dataset. (Error code 111)",
  error_112:
    "Une erreur est survenue lors de l'extraction des erreurs survenues. (Error code 112)",
  error_113:
    "Une erreur est survenue lors de l'extraction des fichiers déjà uploadés. (Error code 113)",
  error_114: "Vous n'êtes pas autorisé à modifier ce dataset.",
  error_115: "Ce dataset est validé et il n'est plus un brouillon.",
  error_116:
    "Une erreur est survenue lors de l'initialisation du dataset. (Error code 116)",
  error_117:
    "Vous avez inséré un ou plusieurs fichier(s) dont l'extension n'est pas autorisé.",
  error_118:
    "Une erreur est survenue lors de la récupération des datasets. (Error code 118)",
  error_119:
    "Une erreur est survenue lors de la suppresion du dataset. (Error code 119)",
  error_120:
    "Une erreur est survenue lors de l'enregistrement des colonnes sensibles. (Error code 120)",
  error_121:
    "Une erreur est survenue lors de l'enregistrement du dataset. (Error code 121)",
  error_122:
    "Une erreur est survenue lors du fetch des colonnes (Error code 122)",
  error_123:
    "Une erreur est survenue lors du changement d'ordre des blocs (Error code 123)",
  error_124:
    "Une erreur est survenue lors de la suppresion du block (Error code 124)",
  error_125:
    "Une erreur est survenue lors de l'activation d'un block (Error code 125)",
  error_126:
    "Une erreur est survenue lors de la désactivation d'un block (Error code 126)",
  error_127:
    "Une erreur est survenue lors de l'ajout d'un bloc. (Error code 127)",
  error_128: `Une erreur est survenue lors du trigger de l'estimation de coût.
    BLOCK TYPE ERROR (Error code 128)`,
  error_129:
    "Vous n'êtes pas autorisé. Contactez un administrateur. (Error code 129)",
  error_130:
    "Une erreur est survenue lors de la récuperation des noms de colonnes. (Error code 130)",
  error_131:
    "Une erreur est survenue lors du téléchargement du dataset de la version. (Error code 131)",
  error_132:
    "Une erreur est survenue lors de la création d'un nouveau projet. (Error code 132)",
  error_133:
    "Une erreur est survenue lors de la récupération des informations sur les utilisateurs. (Error code 133)",
  error_134:
    "Une erreur est survenue lors de la récupération des projets. (Error code 134)",
  error_135:
    "Une erreur est survenue lors de la suppresion du projet. (Error code 135)",
  error_136:
    "Une erreur est survenue lors de la création d'une nouvelle version. (Error code 136)",
  error_137:
    "Une erreur est survenue lors de la récupération des nuages de mots générés. (Error code 137)",
  error_138:
    "Une erreur est survenue lors de la modification du bloc. (Error code 138)",
  error_139:
    "Une erreur est survenue lors de la récupération du bloc. (Error code 139)",
  error_140:
    "Une erreur est survenue lors de la modification du projet. (Error code 140)",
  error_141:
    "Une erreur est survenue lors de la récupération des informations du projet. (Error code 141)",
  error_142:
    "Une erreur est survenue lors de la récupération des informations du dataset. (Error code 142)",
  error_143:
    "Une erreur est survenue lors de la récupération des informations de la version. (Error code 143)",
  error_144:
    "Vous n'êtes pas autorisé à supprimer des blocks. Contactez un administrateur. (Error code 144)",
  error_145:
    "Les deux premiers blocs (choix du dataset et définition de la langue) doivent être executés avant d'ajouter un nouveau bloc. (Error code 145)",
  error_146:
    "Une erreur est survenue lors de la suppresion du fichier uploadé. (Error code 146)",
  error_147:
    "Une erreur est survenue lors du traitement du dataset. (Error code 147)",
  error_148:
    "Le label de votre fichier stopwords existe déjà. (Error code 148)",
  error_149:
    "Une erreur est survenue lors de la sauvegarde du fichier stopwords. (Error code 149)",
  error_150:
    "Une erreur est survenue lors de la récupération des fichiers stopwords. (Error code 150)",
  error_151:
    "Une erreur est survenue lors de la suppression du fichier stopwords. (Error code 151)",
  error_152:
    "Une erreur est survenue lors de la récupération des fichiers de lemmes. (Error code 152)",
  error_153:
    "Une erreur est survenue lors de la suppression du fichier de lemmes. (Error code 153)",
  error_154:
    "Une erreur est survenue lors de l'upload du fichier de masques. (Error code 154)",
  error_155:
    "Une erreur est survenue lors de la récupération des fichiers de masques. (Error code 155)",
  error_156:
    "Une erreur est survenue lors de la suppression du fichier de masques. (Error code 156)",
  error_157:
    "Une erreur est survenue lors de l'upload du fichier de lemmes. (Error code 157)",
  error_158:
    "Une erreur est survenue lors de l'upload du fichier de paramètres. (Error code 158)",
  error_159:
    "Une erreur est survenue lors de la récupération des noms des paramètres. (Error code 159)",
  error_160:
    "Une erreur est survenue lors de la récupération des versions du projet. (Error code 160)",
  error_161:
    "Une erreur est survenue lors de la duplication de la version. (Error code 161)",
  error_162:
    "Une erreur est survenue lors de la récupération des noms des projets. (Error code 162)",
  error_163:
    "Une erreur est survenue lors de la modification du fichier de lemmes. (Error code 163)",
  error_164:
    "Une erreur est survenue lors de la récupération du fichier de lemmes. (Error code 164)",
  error_165:
    "Une erreur est survenue lors de la modification du fichier. (Error code 165)",
  error_166:
    "Une erreur est survenue lors de la récupération du fichier. (Error code 166)",
  error_167:
    "Une erreur est survenue lors de la modification du fichier de masques. (Error code 167)",
  error_168:
    "Une erreur est survenue lors de la récupération du fichier de masques. (Error code 168)",
  error_169:
    "Une erreur est survenue lors de la modification des paramètres. (Error code 169)",
  error_170:
    "Une erreur est survenue lors de la récupération des paramètres. (Error code 170)",
  error_171: "Le nom du fichier existe déjà. (Error code 171)",
  error_172: "Le nom des paramètres existe déjà. (Error code 172)",
  error_173:
    "Une erreur est survenue lors de la modification de l'utilisateur. (Error code 173)",
  error_174:
    "Une erreur est survenue lors de la récupération de l'utilisateur. (Error code 174)",
  error_175: "Vous n'êtes pas autorisé à executer ce bloc. (Error code 175)",
  error_176:
    "Attention, vous n'avez pas executé le premier bloc. Veuillez le faire avant d'ajouter ce bloc.",
  error_177:
    "Attention, ce dataset est déjà utilisé dans un projet. Vous ne pouvez pas le supprimer.",
  error_178:
    "Une erreur est survenue lors de la récupération des fichiers de topics. (Error code 178)",
  error_179:
    "Une erreur est survenue lors de la suppression du fichier de topics. (Error code 179)",
  error_180:
    "Une erreur est survenue lors de l'enregistrement du fichier de topics. (Error code 180)",
  error_181:
    "Une erreur est survenue lors de la récupération des topics enregistrés. (Error code 181)",
  error_182: "Le fichier n'existe pas. (Error code 182)",
  error_183:
    "Une erreur est survenue lors du chargement du fichier output. (Error code 183)",
  error_184: "Le projet n'existe pas. (Error code 184)",
  error_185: "La version n'existe pas. (Error code 185)",
  error_186:
    "Une erreur est survenue lors de la récupération de l'aperçu du dataset. (Error code 186)",
  error_187:
    "Une erreur est survenue lors de la suppression de l'utilisateur. (Error code 187)",
  error_188:
    "Une erreur est survenue lors de la récupération des utilisateurs. (Error code 188)",
  error_189:
    "Une erreur est survenue lors de la récupération des topics détectés. (Error code 189)",
  error_190:
    "Aucun topic détecté pour le moment. Pensez à exécuter un bloc pour détecter les sujets !",
  error_191:
    "Il y a des topics dupliqués. Veuillez les supprimer. (Error code 191)",
  error_192:
    "Une erreur est survenue lors de la suppression du fichier de paramètres. (Error code 192)",
  error_193:
    "Une erreur est survenue lors de la récupération des fichiers de paramètres. (Error code 193)",
  error_194:
    "Une erreur est survenue lors de la récupération des jobs. (Error code 194)",
};

export { ERRORS };
