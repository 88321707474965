import { useRef } from "react";
import styles from "./Modal.module.css";

export default function Modal({ open, setOpen, customStyle, children }) {
  const modalWrapperRef = useRef(0);
  const closeModalHandler = (e) => {
    if (e.target === modalWrapperRef.current) {
      setOpen(false);
    }
  };

  if (!open) {
    return;
  }

  return (
    <div
      ref={modalWrapperRef}
      className={styles.wrapper}
      onClick={closeModalHandler}
    >
      <div className={styles.content} style={customStyle ? customStyle : {}}>
        {children}
      </div>
    </div>
  );
}
