import {
  faChevronCircleDown,
  faChevronCircleUp,
  faExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import IconButton from "common/components/Button/IconButton";
import LoadingCircular from "common/components/LoadingCircular";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./OccuredErrorsPreviewTable.module.css";

export default function OccuredErrorsPreviewTable({ title }) {
  const { datasetId } = useParams();
  const { currentUser } = useAuth();
  const [errorsFileContent, setErrorsFileContent] = useState(null);
  const [collapsed, setCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);

  const fetchData = useCallback(async () => {
    const url = BACKEND_URL_CONFIG.getOccuredErrors.replace(
      "{datasetId}",
      datasetId
    );
    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setErrorsFileContent(response.data.errors);
      }
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }
      toast.error(ERRORS.error_112);
    } finally {
      setLoading(false);
    }
  }, [datasetId, currentUser]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <div className={styles.container}>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>{title}</div>
        <div onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? (
            <IconButton>
              <FontAwesomeIcon icon={faChevronCircleDown} />
            </IconButton>
          ) : (
            <IconButton>
              <FontAwesomeIcon icon={faChevronCircleUp} />
            </IconButton>
          )}
        </div>
      </div>
      {!collapsed && (
        <div className={styles["content-wrapper"]}>
          {loading ? (
            <LoadingCircular />
          ) : errorsFileContent !== null ? (
            <div className={styles["content"]}>
              {errorsFileContent.map((error, index) => (
                <div key={index}>
                  <div>{error}</div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles["empty-table-wrapper"]}>
              <FontAwesomeIcon
                icon={faExclamation}
                style={{ color: "#C6B79A", fontSize: "40px" }}
              />
              <span>Il n'y a pas d'erreurs à visualiser pour ce dataset.</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
