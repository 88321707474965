import axios from "axios";
import {
  GoogleAuthProvider,
  getAdditionalUserInfo,
  signInWithPopup,
} from "firebase/auth";
import { createContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { BACKEND_URL_CONFIG } from "config";
import { auth, db } from "core/firebase";
import { ERRORS } from "errors";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [isUserManager, setIsUserManager] = useState(false);

  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const checkIfUserIsAdmin = async (userId) => {
    if (!userId) {
      return false;
    }
    const userRef = doc(db, "users", userId);
    const docRef = await getDoc(userRef);
    if (docRef.exists()) {
      return docRef.data().is_admin;
    }
  };

  const checkIfUserIsManager = async (userId) => {
    if (!userId) {
      return false;
    }
    const userRef = doc(db, "users", userId);
    const docRef = await getDoc(userRef);
    if (docRef.exists()) {
      const userData = docRef.data();
      return userData.role === "manager";
    }
  };

  const saveUserToDB = async (payload, firstAccessToken) => {
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.createUser,
        payload,
        {
          headers: {
            "X-KEY": firstAccessToken,
          },
        }
      );
      if (response.status === 200) {
        return;
      }
    } catch (err) {
      throw new Error(ERRORS.error_100);
    }
  };

  const generateGCPAccessToken = async () => {
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.generateToken,
        {},
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        return response.data.access_token;
      }
    } catch (err) {
      toast.error(ERRORS.error_101);
      return null;
    }
  };

  const login = async () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(auth, provider)
      .then((result) => {
        const { isNewUser } = getAdditionalUserInfo(result);
        if (isNewUser) {
          const { firstName, lastName } = result["_tokenResponse"];
          const { uid, displayName, email, photoURL } = result.user;
          const newUserObj = {
            user_id: uid,
            display_name: displayName,
            firstname: firstName,
            lastname: lastName,
            initiales: (firstName[0] + lastName[0]).toUpperCase(),
            email: email,
            role: "user",
            photo_url: photoURL,
          };
          saveUserToDB(newUserObj, result.user.accessToken);
        }
        toast(`👋 Welcome ${result.user.displayName}`);
        navigate("/");
      })
      .catch((err) => {
        toast.error(ERRORS.error_103);
      });
  };

  const logout = async () => {
    return await auth.signOut();
  };

  useEffect(() => {
    const unsubcribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      if (user) {
        console.log("it's for beta: ", user);
        const isAdmin = await checkIfUserIsAdmin(user.uid);
        const isManager = await checkIfUserIsManager(user.uid);
        setIsUserAdmin(isAdmin);
        setIsUserManager(isManager);
        setLoading(false);
      } else {
        setLoading(false);
      }
    });
    return unsubcribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    generateGCPAccessToken,
    isUserAdmin,
    isUserManager,
  };
  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
