import axios from "axios";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { db } from "core/firebase";
import { ERRORS } from "errors";
import { doc, onSnapshot } from "firebase/firestore";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Outlet, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export const VersionsContext = createContext();

const VersionsProvider = () => {
  const [versionsInfo, setVersionsInfo] = useState({});
  const { currentUser } = useAuth();
  const { versionId } = useParams();
  const { projectId } = useParams();

  const loadBlocks = useMemo(() => {
    return versionsInfo?.blocks?.load;
  }, [versionsInfo?.blocks?.load]);

  const preprocessBlocks = useMemo(() => {
    return versionsInfo?.blocks?.preprocess;
  }, [versionsInfo?.blocks?.preprocess]);

  const processBlocks = useMemo(() => {
    return versionsInfo?.blocks?.process;
  }, [versionsInfo?.blocks?.process]);

  useEffect(() => {
    const docRef = doc(db, "versions", versionId);
    // Connection to db with real time option
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const versionDataObj = doc.data();
      if (versionDataObj !== undefined) {
        setVersionsInfo(versionDataObj);
      }
    });
    return () => unsubscribe();
  }, []);

  const updateBlocksOrder = useCallback(async (sectionType, newOrder) => {
    const payload = {
      version_id: versionId,
      section_type: sectionType,
      new_section_blocks: newOrder,
      project_id: projectId,
      user_id: currentUser.uid,
    };

    try {
      const response = await axios.put(
        BACKEND_URL_CONFIG.updateBlockOrder,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      toast.error(ERRORS.error_123);
      return false;
    }
  }, []);

  const isPreviousBlockExecuted = (blockId) => {
    const orderedBlocks = versionsInfo?.ordered_blocks;
    const blockIndex = orderedBlocks.findIndex((block) => block.id === blockId);
    // If it's about the first block, we return true.
    if (blockIndex <= 0) return true;

    // Get previous block information
    const previousBlock = orderedBlocks[blockIndex - 1];
    const previousBlockExecuted =
      versionsInfo?.blocks?.[previousBlock.section]?.[previousBlock.id]
        ?.is_executed;

    return previousBlockExecuted;
  };

  const blocksNumber = versionsInfo?.order?.length;

  const value = useMemo(() => {
    return {
      versionsInfo,
      loadBlocks,
      preprocessBlocks,
      processBlocks,
      blocksNumber,
      updateBlocksOrder,
      isPreviousBlockExecuted,
    };
  }, [versionsInfo]);

  return (
    <VersionsContext.Provider value={value}>
      <Outlet />
    </VersionsContext.Provider>
  );
};

export default VersionsProvider;
