import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./FailedMessageContainer.module.css";

export default function FailedMessageContainer({ message }) {
  return (
    <div className={styles["failed-message-container"]}>
      <span>
        <FontAwesomeIcon
          icon={faCircleXmark}
          style={{ color: "#a00000", fontSize: "40px" }}
        />
      </span>
      <span>{message}</span>
    </div>
  );
}
