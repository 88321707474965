let initPreprocessConfig = {
    remove_uppercase: false,
    remove_non_unidecode_chars: false,
    remove_special_punctuations: false,
    remove_middle_points: false,
    remove_urls: false,
    remove_mails: false,
    remove_user_mentions: false,
    replace_at_sign_by_chars: false,
    remove_hashtag_words: false,
    remove_hashtag_signs: false,
    replace_hashtag_sign_by_chars: false,
    remove_emojis: false,
    demojize_emojis: false,
    remove_currencies: false,
    remove_numbers: false,
    remove_punctuations: false,
  };
  
  let preprocessInformationsObject = {
    remove_uppercase: {
      displayName: "Supprimer les majuscules",
      description: "Cela va transformer les caractères majuscules en minuscules.",
    },
    remove_non_unidecode_chars: {
      displayName: "Standardisation des caractères",
      description: `Ce processus transformera tous les caractères en caractères ASCII.
        Cela signifie qu’il supprimera tous les accents et les caractères spéciaux.
        Pour plus d’informations, veuillez consulter ce que sont les caractères ASCII.`,
    },
    remove_special_punctuations: {
      displayName: "Sup. les ponctuations spécifiques",
      description: `Remplace les caractères de ponctuation spéciaux par des caractères de ponctuation classiques.
        Par exemple: ¿ -> ?`,
    },
    remove_middle_points: {
      displayName: "Supprimer le point médian",
      description: `Cela va supprimer le point médian.
        Par exemple: délégué·e·s de la classe -> déléguées de la classe`,
    },
    remove_urls: {
      displayName: "Supprimer les URLs",
      description: "Cela va supprimer les URLs.",
    },
    remove_mails: {
      displayName: "Supprimer les mails",
      description: "Cela va supprimer les adresses mails.",
    },
    remove_user_mentions: {
      displayName: "Supprimer les mentions",
      description: `Cela va supprimer les mentions des utilisateurs notamment sur les tweets.
        Par exemple: @alican`,
    },
    replace_at_sign_by_chars: {
      displayName: "Rempl. le signe arobase",
      description: "Cela va remplacer @ avec 'at'.",
    },
    remove_hashtag_words: {
      displayName: "Supprimer les hashtags",
      description: "Cela va nettoyer les hashtags.",
    },
    remove_hashtag_signs: {
      displayName: "Supprimer le signe hashtag",
      description:
        "Cela va supprimer seulement le symbole d'hashtag en conservant le mot.",
    },
    replace_hashtag_sign_by_chars: {
      displayName: "Rempl. le signe hashtag",
      description: "Cela va remplacer le # par le mot 'hashtag'.",
    },
    remove_emojis: {
      displayName: "Supprimer les emojis",
      description: "Cela va supprimer tous les emojis.",
    },
    demojize_emojis: {
      displayName: "Demojize les emojis",
      description: "Cela convertira les emojis en leur représentation textuelle.",
    },
    remove_currencies: {
      displayName: "Supprimer les devises",
      description: "Cela va supprimer les signes de devises.",
    },
    remove_numbers: {
      displayName: "Supprimer les chiffres",
      description: "Cela va supprimer tous les chiffres.",
    },
    remove_punctuations: {
      displayName: "Supprimer la ponctuation",
      description: "Cela va supprimer les ponctuations.",
    },
  };
  
  const languagesOptions = [
    { value: "fr", label: "French" },
    { value: "en", label: "English" },
  ];
  
  const tokenizerOptions = [
    { value: "manual", label: "Manual" },
    { value: "spacy", label: "Spacy" },
  ];
  
  const lemmatizerOptions = [
    { value: "iramuteq", label: "Iramuteq", compatible: ["manual", "spacy"] },
    { value: "spacy", label: "Spacy", compatible: ["spacy"] },
  ];
  
  const punctuations = [
    "!",
    '"',
    "#",
    "$",
    "%",
    "&",
    "'",
    "(",
    ")",
    "*",
    "+",
    ",",
    "-",
    ".",
    "/",
    ":",
    ";",
    "<",
    "=",
    ">",
    "?",
    "@",
    "[",
    "\\",
    "]",
    "^",
    "_",
    "`",
    "{",
    "|",
    "}",
    "~",
  ];
  const convertedPunctuations = punctuations.map((char) => ({
    value: char,
    label: char,
  }));
  
  const currencies = ["$", "€", "¥", "£", "₺", "₹", "₱"];
  const convertedCurrencies = currencies.map((char) => ({
    value: char,
    label: char,
  }));
  
  const prePunctuationsWordSet = new Set(punctuations.concat(currencies));
  const convertedPrePunctuationsWord = Array.from(prePunctuationsWordSet).map(
    (char) => ({
      value: char,
      label: char,
    })
  );
  
  const postPunctuationsWordSet = new Set(punctuations.concat(currencies));
  const convertedPostPunctuationsWord = Array.from(postPunctuationsWordSet).map(
    (char) => ({
      value: char,
      label: char,
    })
  );
  
  const internPunctuationsWord = [
    ".",
    ",",
    ";",
    "!",
    "?",
    ":",
    '"',
    "`",
    "(",
    ")",
    "[",
    "]",
    "{",
    "}",
    "%",
    "*",
    "+",
    "<",
    ">",
    "=",
    "^",
    "~",
    "#",
    "@",
    "/",
    "\\",
    "|",
  ];
  
  const convertedInternPunctuationsWord = internPunctuationsWord.map((char) => ({
    value: char,
    label: char,
  }));
  
  const prePunctuationsNumber = ["*", "+", "-", "<", ">", "=", "^", "~"];
  const convertedPrePunctuationsNumber = prePunctuationsNumber.map((char) => ({
    value: char,
    label: char,
  }));
  
  const internPunctuationsNumber = [".", ","];
  const convertedInternPunctuationsNumber = internPunctuationsNumber.map(
    (char) => ({
      value: char,
      label: char,
    })
  );
  
  const postPunctuationsNumber = ["%"];
  const convertedPostPunctuationsNumber = postPunctuationsNumber.map((char) => ({
    value: char,
    label: char,
  }));
  
  export {
    convertedCurrencies, convertedInternPunctuationsNumber, convertedInternPunctuationsWord, convertedPostPunctuationsNumber, convertedPostPunctuationsWord, convertedPrePunctuationsNumber, convertedPrePunctuationsWord, convertedPunctuations, initPreprocessConfig, languagesOptions, lemmatizerOptions, preprocessInformationsObject, tokenizerOptions
};
  