import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import {
  faCirclePlus,
  faEllipsisVertical,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { Avatar, Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { Breadcrumbs, Pagination, Stack } from "@mui/material";
import axios from "axios";
import TransparentButton from "common/components/Button/TransparentButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./StopwordsManagementPage.module.css";

export default function StopwordsManagementPage() {
  // States
  const [loading, setLoading] = useState(false);
  const [allStopwords, setAllStopwords] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Contexts
  const navigate = useNavigate();
  const { currentUser, isUserAdmin, isUserManager } = useAuth();

  // Constants
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(allStopwords?.length / itemsPerPage);
  const filteredStopwords = useMemo(
    () => allStopwords.slice(indexOfFirstItem, indexOfLastItem),
    [allStopwords, indexOfFirstItem, indexOfLastItem]
  );

  useEffect(() => {
    fetchStopwords();
  }, []);

  const fetchStopwords = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        BACKEND_URL_CONFIG.getStopwordsFilesList,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        setAllStopwords(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_150);
    } finally {
      setLoading(false);
    }
  };

  const deleteStopwordsFile = async (stopwordsFileId) => {
    setLoading(true);
    const deleteStopwordsFileEndpoint =
      BACKEND_URL_CONFIG.deleteStopwordsFile.replace(
        "{stopwordsFileId}",
        stopwordsFileId
      );
    try {
      const response = await axios.delete(deleteStopwordsFileEndpoint, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        fetchStopwords();
        toast.success("Le fichier a été supprimé");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      }
      toast.error(ERRORS.error_151);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb component="a" href="#" label="Stopwords" />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Stopwords existants</span>
              {loading && <LoadingSpinner />}
            </div>
            <div className={styles["actions-buttons-wrapper"]}>
              <TransparentButton onClick={() => navigate("/stopwords/add")}>
                <FontAwesomeIcon icon={faCirclePlus} />
                Nouveau
              </TransparentButton>
            </div>
          </div>
          <div className={styles["main-section"]}>
            {allStopwords.length === 0 ? (
              <ItemNotFoundContainer message="Aucun fichier de stopwords n’a été trouvé. Vous pouvez en ajouter un !" />
            ) : (
              <div className={styles["table-section-wrapper"]}>
                <table id={styles["files-table"]}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Créé le</th>
                      <th>Créé par</th>
                      <th>Certifié</th>
                      <th>Versions</th>
                      <th>Projets</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredStopwords?.map((stopwordsFile) => (
                      <tr className={styles.row} key={stopwordsFile.id}>
                        <td
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {stopwordsFile.name}
                        </td>
                        <td>
                          {new Date(
                            stopwordsFile.created_at * 1000
                          ).toLocaleString("fr-FR", {
                            timeZone: "Europe/Paris",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            {stopwordsFile.created_by_details.length > 0 &&
                              stopwordsFile.created_by_details.map(
                                (user, index) =>
                                  user.photo_url ? (
                                    <Avatar
                                      key={index}
                                      alt={user.initiales}
                                      src={user.photo_url}
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        fontSize: 13,
                                        color: "#000",
                                        backgroundColor: "#cecece",
                                      }}
                                    />
                                  ) : (
                                    <Avatar
                                      key={index}
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        fontSize: 13,
                                        color: "#000",
                                        backgroundColor: "#cecece",
                                      }}
                                    >
                                      {user.initiales}
                                    </Avatar>
                                  )
                              )}
                          </div>
                        </td>
                        <td>
                          {stopwordsFile.is_certified ? (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              style={{ color: "#2b652b", fontSize: "20px" }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              overflow: "overlay",
                              maxWidth: "250px",
                              maxHeight: "80px",
                            }}
                          >
                            {stopwordsFile.used_in_projects_information.length >
                            0 ? (
                              stopwordsFile.used_in_versions_information?.map(
                                (version) => (
                                  <span
                                    title={version.name}
                                    key={version.id}
                                    className={styles["tag-wrapper"]}
                                    style={{
                                      background: "#aa5f8c",
                                    }}
                                    onClick={() =>
                                      navigate(
                                        `/projects/${version.project_id}/version/${version.id}/run/load`
                                      )
                                    }
                                  >
                                    {version.name.substring(0, 10)}...
                                  </span>
                                )
                              )
                            ) : (
                              <span>-</span>
                            )}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              overflow: "overlay",
                              maxWidth: "250px",
                            }}
                          >
                            {stopwordsFile.used_in_projects_information.length >
                            0 ? (
                              stopwordsFile.used_in_projects_information?.map(
                                (project) => (
                                  <span
                                    title={project.name}
                                    key={project.id}
                                    className={styles["tag-wrapper"]}
                                    style={{
                                      background: "#718bc5",
                                    }}
                                    onClick={() =>
                                      navigate(`/projects/${project.id}`)
                                    }
                                  >
                                    {project.name.substring(0, 10)}...
                                  </span>
                                )
                              )
                            ) : (
                              <span>-</span>
                            )}
                          </div>
                        </td>
                        <td>
                          <Dropdown>
                            <MenuButton
                              style={{ padding: "11px 16px", border: "unset" }}
                              disabled={loading}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </MenuButton>
                            <Menu>
                              <MenuItem
                                disabled={!isUserAdmin && !isUserManager}
                                onClick={() =>
                                  navigate(
                                    `/stopwords/edit/${stopwordsFile.id}`
                                  )
                                }
                              >
                                Modifier
                              </MenuItem>
                              <MenuItem
                                disabled={!isUserAdmin && !isUserManager}
                                onClick={() =>
                                  deleteStopwordsFile(stopwordsFile.id)
                                }
                              >
                                Supprimer
                              </MenuItem>
                            </Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {filteredStopwords.length > 0 && (
              <div className={styles["pagination-container"]}>
                <Stack spacing={2}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, value) => setCurrentPage(value)}
                    count={totalPages}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
