import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "common/components/Button/Button";
import Modal from "common/components/Modal";
import styles from "./OrderChangesConfirmationModal.module.css";

export default function OrderChangesConfirmationModal({
  open,
  setOpen,
  confirmButtonHandler,
}) {
  return (
    <Modal open={open} setOpen={setOpen}>
      <div className={styles["order-changes-modal-wrapper"]}>
        <div>
          <FontAwesomeIcon
            icon={faCircleExclamation}
            style={{ fontSize: "50px", color: "rgb(199, 133, 27)" }}
          />
        </div>
        <div className={styles["confirmation-text-wrapper"]}>
          <span>
            <b>Attention : L'ordre de vos blocs sont changés.</b>
          </span>
          <span>
            Si vous confirmez la mise à jour de votre version, le système mettra
            à zero tous les blocs qui sont en dessous.
          </span>
        </div>
        <div className={styles["modal-actions-buttons-wrapper"]}>
          <Button
            style={{ backgroundColor: "#9c2424" }}
            onClick={() => setOpen(false)}
          >
            Annuler
          </Button>
          <Button onClick={confirmButtonHandler}>Confirmer</Button>
        </div>
      </div>
    </Modal>
  );
}
