import { faClock } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./NotProcessedIcon.module.css";

export default function NotProcessedIcon({ className }) {
  const customClassName = className ? className : styles["waiting-icon-style"];
  return (
    <>
      <FontAwesomeIcon icon={faClock} className={customClassName} />
    </>
  );
}
