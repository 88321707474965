import axios from "axios";
import SubmitButton from "common/components/Button/SubmitButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import DatasetForm from "common/containers/Forms/Dataset/DatasetForm";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./CreateDataset.module.css";

export default function CreateDataset({ title }) {
  // States
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allUsersInformation, setAllUsersInformation] = useState([]);

  // Hooks
  const formId = useId();
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const usersInformation = await fetchUsersInformation();
        setAllUsersInformation(usersInformation);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const nextStep = async (payload) => {
      const datasetId = await createDataset(payload);
      if (datasetId) {
        navigate(`/datasets/${datasetId}/step/2`);
        return;
      }
    };
    if (formData) {
      nextStep(formData);
    }
  }, [formData]);

  const fetchUsersInformation = async () => {
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getAllUserNames, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      return response.data;
    } catch (error) {
      throw Error(ERRORS.error_133);
    }
  };

  const createDataset = async (payload) => {
    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.createDataset,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 201) {
        return response.data.dataset_id;
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(ERRORS.error_109);
        return false;
      } else if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return false;
      }
      toast.error(ERRORS.error_106);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["content"]}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <DatasetForm
              formId={formId}
              setFormData={setFormData}
              setIsSubmitting={setIsSubmitting}
              allUsersInformation={allUsersInformation}
            />
          )}
        </div>
      </div>
      <div className={styles["stepper-action-buttons-wrapper"]}>
        <SubmitButton
          formId={formId}
          disabled={loading || isSubmitting}
          value="Suivant"
          style={{ backgroundColor: "#142637cc", color: "white" }}
        />
      </div>
    </div>
  );
}
