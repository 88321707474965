import axios from "axios";
import Button from "common/components/Button/Button";
import LoadingButton from "common/components/Button/LoadingButton";
import Switch from "common/components/Switch";
import { useAuth } from "common/hooks/useAuth";
import { useStepperContext } from "common/hooks/useStepperContext";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useCallback, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import FileUploader from "./containers/FileUploader";
import UploadDatasetFromGCS from "./containers/UploadDatasetFromGCS";
import UploadedFilesPreviewer from "./containers/UploadedFilesPreviewer";
import styles from "./EditUploadFile.module.css";

export default function EditUploadFile({ title }) {
  const { datasetId } = useParams();
  const { currentUser } = useAuth();
  const [gcsUploader, setGcsUploader] = useState(false);
  const [loading, setLoading] = useState(false);
  const { updateCurrentStep } = useStepperContext();
  const gcsUploaderRef = useRef(0);
  const navigate = useNavigate();
  const [successfullyUploadedFiles, setSuccessfullyUploadedFiles] = useState(
    []
  );

  const updateUploadedFileCallback = useCallback(
    (filenameToAppend) => {
      if (!successfullyUploadedFiles.includes(filenameToAppend)) {
        setSuccessfullyUploadedFiles((prevState) => [
          ...prevState,
          filenameToAppend,
        ]);
      }
    },
    [successfullyUploadedFiles]
  );

  const switchToggleChangeHandler = (e) => {
    setGcsUploader(e.target.checked);
  };

  const initDatasetFilesSettings = async () => {
    const initSettingsEndpoint = BACKEND_URL_CONFIG.initFileSettings.replace(
      "{datasetId}",
      datasetId
    );

    const payload = {
      dataset_settings_init_list: successfullyUploadedFiles,
    };

    try {
      const response = await axios.put(initSettingsEndpoint, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return false;
      }
      if (error.response.status === 304) {
        return true;
      }
      toast.error(ERRORS.error_116);
      return false;
    }
  };

  const triggerIngestDataset = async () => {
    const payload = {
      dataset_id: datasetId,
      user_id: currentUser.uid,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        BACKEND_URL_CONFIG.ingestDataset,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        return true;
      }
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }
      toast.error(ERRORS.error_147);
    } finally {
      setLoading(false);
    }
  };

  const previousButtonHandler = async () => {
    if (datasetId) {
      navigate(`/datasets/${datasetId}/step/1`, { replace: true });
      return;
    }
  };

  const nextButtonHandler = async () => {
    // If there are some uploaded files, so we update settings field in firestore
    if (successfullyUploadedFiles.length > 0) {
      const result = await initDatasetFilesSettings();
      if (result) {
        updateCurrentStep(3, datasetId);
        // Trigger ingest dataset function
        const isSucceeded = await triggerIngestDataset(datasetId);
        if (isSucceeded) {
          navigate(`/datasets/${datasetId}/step/3`, { replace: true });
          return;
        }
      }
      return;
    }
    navigate(`/datasets/${datasetId}/step/3`, { replace: true });
    return;
  };

  return (
    <div className={styles.container}>
      <div className={styles["file-preview-table-wrapper"]}>
        <UploadedFilesPreviewer title="Fichiers uploadés" />
      </div>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["upload-type-input-wrapper"]}>
          <span>Je possède un URI GCS</span>
          <Switch
            inputRef={gcsUploaderRef}
            onChange={switchToggleChangeHandler}
          />
        </div>
        <div className={styles["file-upload-section-wrapper"]}>
          <div className={styles["file-upload-section"]}>
            {gcsUploader ? (
              <UploadDatasetFromGCS />
            ) : (
              <FileUploader
                datasetId={datasetId}
                updateUploadedFileCallback={updateUploadedFileCallback}
              />
            )}
          </div>
        </div>
      </div>
      <div className={styles["stepper-action-buttons-wrapper"]}>
        <Button onClick={previousButtonHandler}>Précedent</Button>
        {loading ? (
          <LoadingButton />
        ) : (
          <Button onClick={nextButtonHandler}>Suivant</Button>
        )}
      </div>
    </div>
  );
}
