import {
  faCircleExclamation,
  faCirclePlus,
  faEllipsisVertical,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { Pagination, Stack } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "axios";
import TransparentButton from "common/components/Button/TransparentButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import { useAuth } from "common/hooks/useAuth";
import { useProject } from "common/hooks/useProject";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./ProjectVersionsPage.module.css";

export default function ProjectVersionsPage() {
  // Contexts
  const navigate = useNavigate();
  const { currentUser, isUserAdmin } = useAuth();
  const { projectId } = useParams();
  const { projectInfo } = useProject();

  // States
  const [allVersions, setAllVersions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Constants
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(allVersions?.length / itemsPerPage);
  const filteredVersions = useMemo(
    () => allVersions.slice(indexOfFirstItem, indexOfLastItem),
    [allVersions, indexOfFirstItem, indexOfLastItem]
  );

  useEffect(() => {
    fetchVersions();
  }, []);

  const fetchVersions = async () => {
    setLoading(true);
    const url = BACKEND_URL_CONFIG.getProjectVersions.replace(
      "{projectId}",
      projectId
    );
    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllVersions(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_134);
    } finally {
      setLoading(false);
    }
  };

  const deleteVersion = async (versionId) => {
    setLoading(true);
    const deleteVersionEndpoint = `${BACKEND_URL_CONFIG.deleteVersion.replace(
      "{versionId}",
      versionId
    )}?project_id=${projectId}`;
    try {
      const response = await axios.delete(deleteVersionEndpoint, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        fetchVersions();
        toast.success("La version a été supprimée");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      }
      toast.error(ERRORS.error_135);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/projects"
                label="Projets"
              />
              <CustomBreadcrumb
                component="a"
                href="#"
                label={projectInfo?.name}
              />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Versions</span>
              {loading && <LoadingSpinner />}
            </div>

            <div className={styles["actions-buttons-wrapper"]}>
              <TransparentButton
                disabled={
                  !projectInfo.owner_ids.includes(currentUser.uid) &&
                  !projectInfo.authorized_user_ids.includes(currentUser.uid) &&
                  !isUserAdmin
                }
                onClick={() => navigate(`/projects/${projectId}/new-version`)}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                Nouvelle version
              </TransparentButton>
            </div>
          </div>
          <div className={styles["main-section"]}>
            {filteredVersions?.length > 0 ? (
              <div className={styles["table-container"]}>
                <table id={styles["project-table"]}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Dernière modification</th>
                      <th>Owners</th>
                      <th>Utilisateurs autorisés</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredVersions?.map((version, index) => (
                      <tr className={styles.row} key={version.id}>
                        <td
                          style={{
                            cursor: "pointer",
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                          onClick={() =>
                            navigate(
                              `/projects/${projectId}/version/${version.id}`
                            )
                          }
                        >
                          {version.name}
                        </td>
                        <td>
                          {new Date(version.last_update * 1000).toLocaleString(
                            "fr-FR",
                            {
                              timeZone: "Europe/Paris",
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                            }
                          )}
                        </td>
                        <td>
                          <div
                            key={`owners-${index}`}
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            {version.owners_details.length > 0 &&
                              version.owners_details.map((user, index) =>
                                user.photo_url ? (
                                  <Avatar
                                    key={`owners-${index}`}
                                    alt={user.initiales}
                                    title={user.display_name}
                                    src={user.photo_url}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      fontSize: 13,
                                      color: "#000",
                                      backgroundColor: "#cecece",
                                    }}
                                  />
                                ) : (
                                  <Avatar
                                    key={`owners-${index}`}
                                    title={user.display_name}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      fontSize: 13,
                                      color: "#000",
                                      backgroundColor: "#cecece",
                                    }}
                                  >
                                    {user.initiales}
                                  </Avatar>
                                )
                              )}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            {version.authorized_users_details.length > 0 ? (
                              version.authorized_users_details.map(
                                (user, index) =>
                                  user.photo_url ? (
                                    <Avatar
                                      key={`users-${index}`}
                                      alt={user.initiales}
                                      title={user.display_name}
                                      src={user.photo_url}
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        fontSize: 13,
                                        color: "#000",
                                        backgroundColor: "#cecece",
                                      }}
                                    />
                                  ) : (
                                    <Avatar
                                      key={`users-${index}`}
                                      title={user.display_name}
                                      sx={{
                                        width: 30,
                                        height: 30,
                                        fontSize: 13,
                                        color: "#000",
                                        backgroundColor: "#cecece",
                                      }}
                                    >
                                      {user.initiales}
                                    </Avatar>
                                  )
                              )
                            ) : (
                              <div>--</div>
                            )}
                          </div>
                        </td>
                        <td>
                          <Dropdown>
                            <MenuButton
                              style={{ padding: "11px 16px", border: "unset" }}
                              disabled={loading}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </MenuButton>
                            <Menu>
                              <MenuItem
                                disabled={
                                  !version.owner_ids.includes(
                                    currentUser.uid
                                  ) && !isUserAdmin
                                }
                                onClick={() =>
                                  navigate(
                                    `/projects/${projectId}/fork-version/${version.id}`
                                  )
                                }
                              >
                                Dupliquer
                              </MenuItem>
                              <MenuItem
                                disabled={
                                  !version.owner_ids.includes(
                                    currentUser.uid
                                  ) && !isUserAdmin
                                }
                                onClick={() =>
                                  navigate(
                                    `/projects/edit/${projectId}/version/${version.id}`
                                  )
                                }
                              >
                                Modifier
                              </MenuItem>
                              <MenuItem
                                disabled={
                                  !version.owner_ids.includes(
                                    currentUser.uid
                                  ) && !isUserAdmin
                                }
                                onClick={() => deleteVersion(version.id)}
                              >
                                Supprimer
                              </MenuItem>
                            </Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div className={styles["empty-table-wrapper"]}>
                <div>
                  <FontAwesomeIcon
                    icon={faCircleExclamation}
                    style={{ fontSize: "50px", color: "rgb(199, 133, 27)" }}
                  />
                </div>
                <div className={styles["text-wrapper"]}>
                  <span>
                    <b>Aucune version n'est trouvée.</b>
                  </span>
                  <span>
                    Veuillez ajouter une nouvelle version pour commencer.
                  </span>
                </div>
              </div>
            )}
            {allVersions?.length > 0 && (
              <div className={styles["pagination-container"]}>
                <Stack spacing={2}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, value) => setCurrentPage(value)}
                    count={totalPages}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
