import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import Stepper from "common/containers/Stepper";
import MainLayout from "layouts/MainLayout";
import { Fragment, useMemo } from "react";
import { useParams } from "react-router-dom";
import styles from "./EditDatasetPage.module.css";
import ConfigDataset from "./containers/ConfigDataset";
import EditDataset from "./containers/EditDataset";
import EditUploadFile from "./containers/EditUploadFile";
import SensitiveColumnsChoice from "./containers/SensitiveColumnsChoice";

export default function EditDatasetPage() {
  const { stepNumber } = useParams();

  const currentStep = useMemo(() => {
    return stepNumber ? parseInt(stepNumber) : 1;
  }, [stepNumber]);

  const steps = [
    "Création d'un dataset",
    "Uplaod vos fichiers",
    "Parametres des fichiers",
    "Configuration des colonnes",
  ];

  const getSectionComponent = () => {
    switch (currentStep) {
      case 1:
        return <EditDataset title="Edition d'un dataset" />;
      case 2:
        return <EditUploadFile title="Upload vos fichiers" />;
      case 3:
        return <ConfigDataset />;
      case 4:
        return <SensitiveColumnsChoice title="Configuration des colonnes" />;
      default:
        return null;
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/datasets"
                label="Datasets"
              />
              <CustomBreadcrumb label="Edition d'un dataset" href="#" />
            </Breadcrumbs>
          </div>
          <div className={styles["stepper-wrapper"]}>
            <Stepper currentStep={currentStep} steps={steps} />
          </div>
          <div>{getSectionComponent()}</div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
