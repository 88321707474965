import axios from "axios";
import SubmitButton from "common/components/Button/SubmitButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import DatasetForm from "common/containers/Forms/Dataset/DatasetForm";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./EditDataset.module.css";

export default function EditDataset({ title }) {
  // States
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [datasetInformation, setDatasetInformation] = useState(null);
  const [allUsersInformation, setAllUsersInformation] = useState([]);

  // Hooks
  const formId = useId();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { datasetId } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [usersInformation, datasetInformation] = await Promise.all([
          fetchUsersInformation(),
          fetchDatasetInformation(),
        ]);
        setAllUsersInformation(usersInformation);
        setDatasetInformation(datasetInformation);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const saveFormData = async (payload) => {
      const datasetId = await updateDataset(payload);
      if (datasetId) {
        navigate(`/datasets/${datasetId}/step/2`);
        return;
      }
    };

    if (formData) {
      saveFormData(formData);
    }
  }, [formData]);

  const fetchUsersInformation = async () => {
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getAllUserNames, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error(ERRORS.error_133);
    }
  };

  const fetchDatasetInformation = async () => {
    const url = BACKEND_URL_CONFIG.getDatasetInfo.replace(
      "{datasetId}",
      datasetId
    );
    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      throw Error(ERRORS.error_142);
    }
  };

  const updateDataset = async (payload) => {
    const url = BACKEND_URL_CONFIG.updateDataset.replace(
      "{datasetId}",
      datasetId
    );
    setLoading(true);
    try {
      const response = await axios.put(url, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        return response.data.dataset_id;
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.error(ERRORS.error_109);
        return false;
      }
      toast.error(ERRORS.error_106);
      return false;
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles["content-wrapper"]}>
        <div className={styles["content"]}>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <DatasetForm
              formId={formId}
              datasetObject={datasetInformation}
              setFormData={setFormData}
              setIsSubmitting={setIsSubmitting}
              allUsersInformation={allUsersInformation}
            />
          )}
        </div>
      </div>
      <div className={styles["stepper-action-buttons-wrapper"]}>
        <SubmitButton
          formId={formId}
          disabled={loading || isSubmitting}
          value="Enregistrer"
          style={{ backgroundColor: "#142637cc", color: "white" }}
        />
      </div>
    </div>
  );
}
