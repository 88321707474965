import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faEllipsisVertical, faHome } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import {
  Avatar,
  Breadcrumbs,
  LinearProgress,
  Pagination,
  Stack,
} from "@mui/material";
import axios from "axios";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import FailedIcon from "common/components/Icon/FailedIcon";
import WaitingIcon from "common/components/Icon/NotProcessedIcon";
import SuccessIcon from "common/components/Icon/SuccessIcon";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./JobsPage.module.css";

// Status icon mapping to make it dynamic
const StatusIcon = ({ status }) => {
  if (!status) {
    return null;
  }
  const icons = {
    not_processed: <WaitingIcon />,
    in_progress: <LoadingSpinner />,
    failed: <FailedIcon />,
    processed: <SuccessIcon />,
  };

  return icons[status.toLowerCase()] || null;
};

export default function JobsPage() {
  // States
  const [loading, setLoading] = useState(false);
  const [allJobs, setAllJobs] = useState([]);
  const [totalJobsCount, setTotalJobsCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);

  // Contexts
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // Constants
  const limit = 10;
  const totalPages = Math.ceil(totalJobsCount / limit);

  const fetchJobs = useCallback(
    async (limit, currentPage) => {
      try {
        setLoading(true);
        const offset = (currentPage - 1) * limit;
        const response = await axios.get(BACKEND_URL_CONFIG.getJobsList, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          params: {
            limit: limit,
            offset: offset,
          },
        });
        if (response.status === 200) {
          setAllJobs(response.data.jobs);
          setTotalJobsCount(response.data.total);
        }
      } catch (error) {
        if (error.response.status === 403) {
          toast.error(ERRORS.error_129);
          return;
        } else {
          toast.error(ERRORS.error_194);
        }
      } finally {
        setLoading(false);
      }
    },
    [currentUser]
  );

  useEffect(() => {
    fetchJobs(limit, currentPage);
  }, [fetchJobs, limit, currentPage]);

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb component="a" href="#" label="Jobs" />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Jobs</span>
              {loading && <LoadingSpinner />}
            </div>
          </div>
          <div className={styles["main-section"]}>
            {allJobs.length === 0 ? (
              <ItemNotFoundContainer message="Aucun job n’a été trouvé. Vous pouvez en ajouter un !" />
            ) : loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  minHeight: "350px",
                  padding: "20px",
                  flexDirection: "column",
                }}
              >
                <LinearProgress />
              </div>
            ) : (
              <div className={styles["table-section-wrapper"]}>
                <table id={styles["jobs-table"]}>
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Créé le</th>
                      <th>Sous-type</th>
                      <th>Projet</th>
                      <th>Version</th>
                      <th>Déclenché par</th>
                      <th>Commencé le</th>
                      <th>Terminé le</th>
                      <th>Durée du processus</th>
                      <th>État</th>
                      <th>Coûts payés</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {allJobs?.map((job) => (
                      <tr className={styles.row} key={job.id}>
                        <td>{job.id}</td>
                        <td>
                          {new Date(job.created_at * 1000).toLocaleString(
                            "fr-FR",
                            {
                              timeZone: "Europe/Paris",
                              day: "2-digit",
                              month: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                            }
                          )}
                        </td>
                        <td>{job.block_subtype}</td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              overflow: "overlay",
                              maxWidth: "250px",
                              maxHeight: "80px",
                            }}
                          >
                            {job.project_information?.length > 0
                              ? job.project_information?.map((project) => (
                                  <span
                                    title={project.name}
                                    key={project.id}
                                    className={styles["tag-wrapper"]}
                                    style={{
                                      background: "#292929d9",
                                    }}
                                    onClick={() =>
                                      navigate(`/projects/${project.id}`)
                                    }
                                  >
                                    {project.name.substring(0, 10)}...
                                  </span>
                                ))
                              : "---"}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              flexWrap: "wrap",
                              justifyContent: "center",
                              overflow: "overlay",
                              maxWidth: "250px",
                              maxHeight: "80px",
                            }}
                          >
                            {job.version_information?.length > 0
                              ? job.version_information?.map((version) => (
                                  <span
                                    title={version.name}
                                    key={version.id}
                                    className={styles["tag-wrapper"]}
                                    style={{
                                      background: "#292929d9",
                                    }}
                                    onClick={() =>
                                      navigate(
                                        `/projects/${version.project_id}/version/${version.id}/run/load`
                                      )
                                    }
                                  >
                                    {version.name.substring(0, 10)}...
                                  </span>
                                ))
                              : "---"}
                          </div>
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            {job.triggered_by_details?.map((user, index) => (
                              <Avatar
                                key={`triggered-by-${index}`}
                                alt={user.initiales}
                                title={user.display_name}
                                src={user.photo_url}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  fontSize: 13,
                                  color: "#000",
                                  backgroundColor: "#cecece",
                                }}
                              />
                            ))}
                          </div>
                        </td>
                        <td>
                          {job.started_at
                            ? new Date(job.started_at * 1000).toLocaleString(
                                "fr-FR",
                                {
                                  timeZone: "Europe/Paris",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )
                            : "---"}
                        </td>
                        <td>
                          {job.ended_at
                            ? new Date(job.ended_at * 1000).toLocaleString(
                                "fr-FR",
                                {
                                  timeZone: "Europe/Paris",
                                  day: "2-digit",
                                  month: "2-digit",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                }
                              )
                            : "---"}
                        </td>
                        <td>
                          {job.process_duration
                            ? `${job.process_duration} secondes`
                            : "---"}
                        </td>
                        <td>
                          <StatusIcon status={job.status} />
                        </td>
                        <td>
                          {job.paid_costs && job.paid_costs > 0
                            ? job.paid_costs >= 0.01
                              ? `${job.paid_costs}`
                              : `${job.paid_costs.toFixed(7)}`
                            : "0"}
                        </td>
                        <td>
                          <Dropdown>
                            <MenuButton
                              style={{ padding: "11px 16px", border: "unset" }}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </MenuButton>
                            <Menu>
                              <MenuItem disabled>Arrêter</MenuItem>
                              <MenuItem disabled>Supprimer</MenuItem>
                            </Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {allJobs.length > 0 && !loading && (
              <div className={styles["pagination-container"]}>
                <Stack spacing={2}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, value) => setCurrentPage(value)}
                    count={totalPages}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
