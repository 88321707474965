import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function LoadingSpinner(props) {
  const iconCustomStyle = props.iconCustomStyle
    ? props.iconCustomStyle
    : { fontSize: "15px" };
  return (
    <>
      <FontAwesomeIcon
        icon={faSpinner}
        className="fa-spin"
        style={iconCustomStyle}
      />
    </>
  );
}

export default LoadingSpinner;
