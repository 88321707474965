const ALLOWED_BLOCKS_BY_SECTION = {
  load: ["load_dataset", "set_languages", "sentencize_verbatims"],
  preprocess: [
    "lemmatizer",
    "translate_verbatims",
    "spellcheck_verbatims",
    "clean_verbatims",
    "remove_stopwords",
  ],
  process: [
    "generate_wordclouds",
    "generate_global_stats",
    "compare_dictionary_sizes",
    "count_hapax",
    "generate_ngram_frequencies",
    "analyze_sentiments",
    "model_topics",
    "detect_topics",
  ],
};

const BLOCKS_SUBBLOCKS_REF = {
  load: {
    load_dataset: ["load_dataset", "load_dataset_dlp"],
    set_languages: ["set_languages"],
    sentencize_verbatims: ["sentencize_verbatims_spacy"],
  },
  preprocess: {
    lemmatizer: ["lemmatize_verbatims_iramuteq", "lemmatize_verbatims_spacy"],
    translate_verbatims: [
      "translate_verbatims_gct",
      "translate_verbatims_openai_batch",
    ],
    spellcheck_verbatims: ["spellcheck_verbatims_openai_batch"],
    clean_verbatims: ["clean_verbatims"],
    remove_stopwords: ["remove_stopwords"],
  },
  process: {
    generate_wordclouds: ["generate_wordclouds"],
    generate_global_stats: ["generate_global_stats"],
    compare_dictionary_sizes: ["compare_dictionary_sizes"],
    count_hapax: ["count_hapax"],
    generate_ngram_frequencies: ["generate_ngram_frequencies"],
    analyze_sentiments: [
      "analyze_sentiments_openai_batch",
      "analyze_sentiments_textblob",
      "analyze_sentiments_aws_comprehend",
    ],
    model_topics: ["model_topics_openai_batch"],
    detect_topics: ["detect_topics_openai_batch"],
  },
};

const BLOCK_TYPE_DISPLAY_NAME = {
  load_dataset: "Choix du dataset",
  load_dataset_dlp: "Choix du dataset avec DLP",
  set_languages: "Définition de la langue",
  sentencize_verbatims: "Sentencizer",
  sentencize_verbatims_spacy: "Sentencizer Spacy",
  lemmatizer: "Lemmatizer",
  translate_verbatims: "Traduction",
  translate_verbatims_gct: "Traduction GCP",
  translate_verbatims_openai_batch: "Traduction OpenAI",
  lemmatize_verbatims_iramuteq: "Lemmatizer Iramuteq",
  lemmatize_verbatims_spacy: "Lemmatizer Spacy",
  spellcheck_verbatims: "Spellchecker",
  spellcheck_verbatims_openai_batch: "Spellchecker OpenAI",
  clean_verbatims: "Nettoyage des verbatims",
  remove_stopwords: "Supression des stopwords",
  generate_wordclouds: "Nuages de mots",
  generate_global_stats: "Statistiques globales",
  compare_dictionary_sizes: "Comparaison de taille de dictionnaires",
  count_hapax: "Hapax",
  generate_ngram_frequencies: "Fréquences de n-grams",
  analyze_sentiments: "Analyse de sentiment",
  analyze_sentiments_openai_batch: "Analyse de sentiment OpenAI",
  analyze_sentiments_textblob: "Analyse de sentiment TextBlob",
  analyze_sentiments_aws_comprehend: "Analyse de sentiment AWS Comprehend",
  model_topics: "Topic modeling",
  model_topics_openai_batch: "Topic modeling OpenAI",
  detect_topics: "Topic detection",
  detect_topics_openai_batch: "Topic detection OpenAI",
};

const BLOCK_SUBTYPE_DISPLAY_NAME = {
  set_global_language: "Langue globale",
  set_specific_languages: "Langue spécifique",
  detect_languages_langid: "Langid",
  detect_languages_aws_comprehend: "AWS Comprehend",
  load_dataset_dlp: "DLP",
  sentencize_verbatims_spacy: "Spacy",
  lemmatize_verbatims_spacy: "Spacy",
  lemmatize_verbatims_iramuteq: "Iramuteq",
  translate_verbatims_openai_batch: "OpenAI",
  translate_verbatims_gct: "GCP",
  spellcheck_verbatims_openai_batch: "OpenAI",
  analyze_sentiments_openai_batch: "OpenAI",
  analyze_sentiments_textblob: "TextBlob",
  analyze_sentiments_aws_comprehend: "AWS",
  model_topics_openai_batch: "OpenAI",
  detect_topics_openai_batch: "OpenAI",
};

const REDIRECT_BLOCK_PAGES_MAP = {
  load: {
    load_dataset: "Choix du dataset",
    set_languages: "Définir de la langue",
    sentencize_verbatims: "Sentencize verbatims",
  },
  preprocess: {
    lemmatizer: "Lemmatizer",
    translate_verbatims: "Traduction",
    spellcheck_verbatims: "Spellchecker",
    clean_verbatims: "Nettoyage",
    remove_stopwords: "Stopwords",
  },
  process: {
    generate_wordclouds: "Nuages de mots",
    generate_global_stats: "Statistiques globales",
    compare_dictionary_sizes: "Comparaison de dictionnaires",
    count_hapax: "Hapax",
    generate_ngram_frequencies: "Fréquences de n-grams",
    analyze_sentiments: "Analyse de sentiment",
    model_topics: "Topic modeling",
    detect_topics: "Topic detection",
  },
};

const BLOCK_HAVING_OUTPUT_PREVIEW = [
  "generate_wordclouds",
  "generate_ngram_frequencies",
  "generate_global_stats",
  "compare_dictionary_sizes",
  "detect_topics_openai_batch",
  "count_hapax",
];

export {
  ALLOWED_BLOCKS_BY_SECTION,
  BLOCKS_SUBBLOCKS_REF,
  BLOCK_HAVING_OUTPUT_PREVIEW,
  BLOCK_SUBTYPE_DISPLAY_NAME,
  BLOCK_TYPE_DISPLAY_NAME,
  REDIRECT_BLOCK_PAGES_MAP,
};
