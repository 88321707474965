import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./DeleteButton.module.css";

function DeleteButton(props) {
  // The className can be custom, so we check first if there is any props about that
  const className = props.className
    ? props.className
    : styles["del-icon-container"];

  return (
    <button
      disabled={props.disabled}
      className={`${props.disabled ? styles["disabled-button"] : className}`}
      onClick={props.onClick}
      style={props.customStyle}
      title={props.title}
    >
      <FontAwesomeIcon icon={faTrashAlt} />
    </button>
  );
}

export default DeleteButton;
