import { useAuth } from "common/hooks/useAuth";

import { useCallback, useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import StopwordsFileForm from "common/containers/Forms/UploadFile/StopwordsFileForm";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinearProgress } from "@mui/joy";
import { LinearProgress as LinearProgressMaterial } from "@mui/material";

import { Breadcrumbs } from "@mui/material";
import axios from "axios";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import { BACKEND_URL_CONFIG, STOPWORDS_BUCKET_NAME } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment } from "react";
import { toast } from "react-toastify";
import styles from "./AddStopwordsFilePage.module.css";

export default function AddStopwordsFilePage() {
  // Contexts
  const { currentUser, generateGCPAccessToken } = useAuth();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [progress, setProgress] = useState(0);
  const [uploading, setUploading] = useState(false);

  // Refs
  const formIdRef = useId();

  const initUploadSession = useCallback(
    async (filename, signal) => {
      const accessToken = await generateGCPAccessToken();
      const bucketName = STOPWORDS_BUCKET_NAME;
      try {
        const response = await axios.post(
          `https://storage.googleapis.com/upload/storage/v1/b/${bucketName}/o?uploadType=resumable&name=${filename}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
            signal: signal,
          }
        );
        return response.headers.location;
      } catch (error) {
        toast.error(ERRORS.error_105);
        return;
      }
    },
    [generateGCPAccessToken]
  );

  const saveStopwordsFileToDB = useCallback(
    async (formData, signal) => {
      const payload = {
        user_id: currentUser.uid,
        name: formData.file_label,
        is_certified: formData.is_certified,
      };
      try {
        const response = await axios.post(
          BACKEND_URL_CONFIG.createStopwordsFile,
          payload,
          {
            headers: {
              "X-KEY": currentUser.accessToken,
            },
            signal: signal,
          }
        );
        if (response.status === 201) {
          return response.data.id;
        }
      } catch (error) {
        if (error.response.status === 409) {
          toast.error(ERRORS.error_148);
          setUploading(false);
          setLoading(false);
          return;
        } else {
          toast.error(ERRORS.error_149);
          setUploading(false);
          setLoading(false);
          return;
        }
      }
    },
    [currentUser]
  );

  const chunkUpload = useCallback(async (uploadedFile, sessionURI, signal) => {
    if (sessionURI === undefined) {
      return;
    }
    const fileSize = uploadedFile.size;
    const chunkSize = 6 * 1024 * 1024; // Set chunk size to 6MB
    const totalChunks = Math.ceil(fileSize / chunkSize);
    const progressValue = 100 / totalChunks;

    setUploading(true);
    for (let start = 0; start < fileSize; start += chunkSize) {
      const end = Math.min(start + chunkSize, fileSize);
      const chunk = uploadedFile.slice(start, end);
      try {
        const response = await axios.put(sessionURI, chunk, {
          headers: {
            "Content-Range": `bytes ${start}-${end - 1}/${fileSize}`,
          },
          signal: signal,
        });
        if (response.status === 200) {
          setUploading(false);
          return true;
        }
      } catch (err) {
        if (err?.code === "ERR_CANCELED") {
          setUploading(false);
          return;
        }
        if (err.response && err.response?.status !== 308) {
          toast.error(ERRORS.error_104);
          setUploading(false);
          return;
        }
        if (err.response.status === 308) {
          setProgress((progress) => progress + progressValue);
        }
      }
    }
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    const uploadFile = async (formData) => {
      setLoading(true);
      const fileId = await saveStopwordsFileToDB(formData, controller.signal);
      if (!fileId) {
        return;
      }
      const filename = `${fileId}.${formData.file_extension}`;
      setUploading(true);
      const sessionURI = await initUploadSession(filename, controller.signal);
      if (sessionURI) {
        const result = await chunkUpload(
          formData.file_content,
          sessionURI,
          controller.signal
        );
        if (result) {
          toast.success("Le fichier a été enregistré");
          navigate("/stopwords");
        }
      }
      setUploading(false);
      setLoading(false);
    };

    if (formData) {
      uploadFile(formData);
    }

    return () => {
      controller.abort();
    };
  }, [
    formData,
    initUploadSession,
    saveStopwordsFileToDB,
    chunkUpload,
    navigate,
  ]);

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/stopwords"
                label="Stopwords"
              />
              <CustomBreadcrumb
                component="a"
                href="#"
                label="Nouveau stopword"
              />
            </Breadcrumbs>
          </div>
          <div className={styles["main-section"]}>
            <BlockFormContainer>
              <BlockFormTitleContainer
                title="Ajouter un fichier de stopwords"
                formId={formIdRef}
                loading={loading}
                isSubmitting={isSubmitting}
              />
              <BlockFormFieldsContainer>
                {loading ? (
                  uploading ? (
                    <LinearProgressMaterial
                      variant="determinate"
                      value={progress}
                      style={{ borderRadius: "1rem", height: "6px" }}
                    />
                  ) : (
                    <LinearProgress />
                  )
                ) : (
                  <StopwordsFileForm
                    setFormData={setFormData}
                    formId={formIdRef}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </BlockFormFieldsContainer>
            </BlockFormContainer>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
