import {
  faCirclePlus,
  faEllipsisVertical,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar, Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { Pagination, Stack } from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "axios";
import TransparentButton from "common/components/Button/TransparentButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./ProjectsPage.module.css";

export default function ProjectsPage() {
  // Contexts
  const navigate = useNavigate();
  const { currentUser, isUserAdmin, isUserManager } = useAuth();

  // States
  const [allProjects, setAllProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Constants
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(allProjects?.length / itemsPerPage);
  const filteredProjects = useMemo(
    () => allProjects.slice(indexOfFirstItem, indexOfLastItem),
    [allProjects, indexOfFirstItem, indexOfLastItem]
  );

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getAllProjects, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllProjects(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_134);
    } finally {
      setLoading(false);
    }
  };

  const deleteProject = async (projectId) => {
    setLoading(true);
    const deleteDatasetEndpoint = BACKEND_URL_CONFIG.deleteProject.replace(
      "{projectId}",
      projectId
    );
    try {
      const response = await axios.delete(deleteDatasetEndpoint, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        fetchProjects();
        toast.success("Le project a été supprimé");
        return;
      }
    } catch (error) {
      toast.error(ERRORS.error_135);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/projects"
                label="Projets"
              />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Projets</span>
              {loading && <LoadingSpinner />}
            </div>
            <div className={styles["actions-buttons-wrapper"]}>
              <TransparentButton
                disabled={!isUserManager && !isUserAdmin}
                onClick={() => navigate("/projects/new")}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                Nouveau projet
              </TransparentButton>
            </div>
          </div>
          <div className={styles["main-section"]}>
            <div className={styles["table-container"]}>
              <table id={styles["project-table"]}>
                <thead>
                  <tr>
                    <th>Nom</th>
                    <th>Dernière modification</th>
                    <th>Owners</th>
                    <th>Utilisateurs autorisés</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredProjects?.map((project, index) => (
                    <tr className={styles.row} key={project.id}>
                      <td
                        style={{
                          cursor: "pointer",
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                        onClick={() => navigate(`/projects/${project.id}`)}
                      >
                        {project.name}
                      </td>
                      <td>
                        {new Date(project.last_update * 1000).toLocaleString(
                          "fr-FR",
                          {
                            timeZone: "Europe/Paris",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </td>
                      <td>
                        <div
                          key={`owners-${index}`}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {project.owners_details.length > 0 &&
                            project.owners_details.map((user, index) =>
                              user.photo_url ? (
                                <Avatar
                                  key={`owners-${index}`}
                                  alt={user.initiales}
                                  title={user.display_name}
                                  src={user.photo_url}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: 13,
                                    color: "#000",
                                    backgroundColor: "#cecece",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  key={`owners-${index}`}
                                  title={user.display_name}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: 13,
                                    color: "#000",
                                    backgroundColor: "#cecece",
                                  }}
                                >
                                  {user.initiales}
                                </Avatar>
                              )
                            )}
                        </div>
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {project.authorized_users_details.length > 0 ? (
                            project.authorized_users_details.map(
                              (user, index) =>
                                user.photo_url ? (
                                  <Avatar
                                    key={`users-${index}`}
                                    alt={user.initiales}
                                    title={user.display_name}
                                    src={user.photo_url}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      fontSize: 13,
                                      color: "#000",
                                      backgroundColor: "#cecece",
                                    }}
                                  />
                                ) : (
                                  <Avatar
                                    key={`users-${index}`}
                                    title={user.display_name}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      fontSize: 13,
                                      color: "#000",
                                      backgroundColor: "#cecece",
                                    }}
                                  >
                                    {user.initiales}
                                  </Avatar>
                                )
                            )
                          ) : (
                            <div>--</div>
                          )}
                        </div>
                      </td>
                      <td>
                        <Dropdown>
                          <MenuButton
                            style={{ padding: "11px 16px", border: "unset" }}
                            disabled={loading}
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </MenuButton>
                          <Menu>
                            <MenuItem
                              disabled={
                                !project.owner_ids.includes(currentUser.uid) &&
                                !isUserAdmin
                              }
                              onClick={() =>
                                navigate(`/projects/edit/${project.id}`)
                              }
                            >
                              Modifier
                            </MenuItem>
                            <MenuItem
                              disabled={
                                !project.owner_ids.includes(currentUser.uid) &&
                                !isUserAdmin
                              }
                              onClick={() => deleteProject(project.id)}
                            >
                              Supprimer
                            </MenuItem>
                          </Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles["pagination-container"]}>
              <Stack spacing={2}>
                <Pagination
                  page={currentPage}
                  onChange={(e, value) => setCurrentPage(value)}
                  count={totalPages}
                />
              </Stack>
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
