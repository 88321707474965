import { Option, Select } from "@mui/joy";
import { useForm } from "react-hook-form";

import { useEffect } from "react";
import { Controller } from "react-hook-form";
import styles from "./SetLanguageForm.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

const setLanguageChoicesRef = [
  ["Choisir une langue globale", "set_global_language"],
  ["Avoir une détection de la langue (LangID)", "detect_languages_langid"],
  [
    "Avoir une détection de la langue (AWS Comprehend)",
    "detect_languages_aws_comprehend",
  ],
  ["Choisir ma propre colonne de langue", "set_specific_languages"],
];

const availableLanguages = [
  ["Français", "fr"],
  ["Anglais", "en"],
];

export default function SetLanguageForm({
  block,
  formId,
  setFormData,
  setIsSubmitting,
  availableColumnsInfo,
}) {
  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      language_option: block ? block.subtype : null,
      global_language: block ? block.parameters.global_language : null,
      language_column_id: block ? block.parameters.language_column_id : null,
    },
  });

  // We need to watch language option to show/hide another field
  const languageOption = watch("language_option");

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);

  const onSubmit = (data) => {
    const structuredParameters = {};
    if (languageOption === "set_global_language") {
      structuredParameters.global_language = data.global_language;
    }

    if (languageOption === "set_specific_languages") {
      structuredParameters.language_column_id = data.language_column_id;
    }

    const submittedData = {
      parameters: structuredParameters,
      subBlockType: languageOption,
      blockDisplayName: languageOption,
    };
    setFormData(submittedData);
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Je souhaiterais :</label>
        <Controller
          name="language_option"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisir votre dataset..."
              required={true}
              disabled={isSubmitting}
              style={customSelectStyle}
              onChange={(_, newValue) => {
                field.onChange(newValue);
              }}
            >
              {setLanguageChoicesRef?.map(([displayName, eachRequestType]) => (
                <Option key={eachRequestType} value={eachRequestType}>
                  {displayName}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      {languageOption === "set_global_language" && (
        <div className={styles["field-container"]}>
          <label className={styles.label}>Langue à choisir</label>
          <Controller
            name="global_language"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Choisir la langue..."
                required={true}
                disabled={isSubmitting}
                style={customSelectStyle}
                onChange={(_, newValue) => {
                  field.onChange(newValue);
                }}
              >
                {availableLanguages?.map(
                  ([languageDisplayName, languageCode]) => (
                    <Option key={languageCode} value={languageCode}>
                      {languageDisplayName}
                    </Option>
                  )
                )}
              </Select>
            )}
          />
        </div>
      )}
      {languageOption === "set_specific_languages" && (
        <div className={styles["field-container"]}>
          <label className={styles.label}>Colonne de langue à choisir :</label>
          <Controller
            name="language_column_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                placeholder="Choisir la colonne contenant la langue..."
                required={true}
                disabled={isSubmitting}
                style={customSelectStyle}
                onChange={(_, newValue) => {
                  field.onChange(newValue);
                }}
              >
                {Object.entries(availableColumnsInfo || {}).map(
                  ([columnId, columnName]) => (
                    <Option key={columnId} value={columnId}>
                      {columnName}
                    </Option>
                  )
                )}
              </Select>
            )}
          />
        </div>
      )}
    </form>
  );
}
