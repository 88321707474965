import styles from "./ClearButton.module.css";

function ClearButton(props) {
  return (
    <>
      <button
        onClick={props.onClick}
        className={styles["clear-button"]}
        style={props.style}
        disabled={props.disabled}
      >
        {props.children}
      </button>
    </>
  );
}

export default ClearButton;
