import { useAuth } from "common/hooks/useAuth";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const ProtectedForAdmins = () => {
  // States
  const [loading, setLoading] = useState(true);

  // Contexts
  const { isUserAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (isUserAdmin) {
      setLoading(false);
      return;
    }
    toast.error(ERRORS.error_129);
    navigate("/");
  }, [isUserAdmin, navigate]);

  return !loading && <Outlet />;
};

export default ProtectedForAdmins;
