import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisVertical, faHome } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import { Avatar, Breadcrumbs, Pagination, Stack } from "@mui/material";
import axios from "axios";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./TopicsManagementPage.module.css";

export default function TopicsManagementPage() {
  // States
  const [loading, setLoading] = useState(false);
  const [allTopics, setAllTopics] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Contexts
  const navigate = useNavigate();
  const { currentUser, isUserAdmin, isUserManager } = useAuth();

  // Constants
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(allTopics?.length / itemsPerPage);
  const filteredTopics = useMemo(
    () => allTopics.slice(indexOfFirstItem, indexOfLastItem),
    [allTopics, indexOfFirstItem, indexOfLastItem]
  );

  const fetchTopics = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getTopicsFilesList, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllTopics(response.data);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      }
      toast.error(ERRORS.error_178);
    } finally {
      setLoading(false);
    }
  }, [currentUser.accessToken]);

  const deleteTopicsFile = async (topicFileId) => {
    setLoading(true);
    const deleteTopicsFileEndpoint =
      BACKEND_URL_CONFIG.deleteTopicsFile.replace("{topicFileId}", topicFileId);
    try {
      const response = await axios.delete(deleteTopicsFileEndpoint, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        fetchTopics();
        toast.success("Le fichier a été supprimé");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      }
      toast.error(ERRORS.error_179);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Fetch topics while first render
    fetchTopics();
  }, [fetchTopics]);

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb component="a" href="#" label="Topics" />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Topics existants</span>
              {loading && <LoadingSpinner />}
            </div>
          </div>
          <div className={styles["main-section"]}>
            {allTopics?.length === 0 ? (
              <ItemNotFoundContainer message="Aucun fichier de topics n’a été trouvé. Vous pouvez en ajouter un !" />
            ) : (
              <div className={styles["table-section-wrapper"]}>
                <table id={styles["files-table"]}>
                  <thead>
                    <tr>
                      <th>Nom</th>
                      <th>Créé le</th>
                      <th>Créé par</th>
                      <th>Certifié</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTopics?.map((topicsFile) => (
                      <tr className={styles.row} key={topicsFile.id}>
                        <td
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {topicsFile.name}
                        </td>
                        <td>
                          {new Date(
                            topicsFile.created_at * 1000
                          ).toLocaleString("fr-FR", {
                            timeZone: "Europe/Paris",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          })}
                        </td>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            {topicsFile.created_by_details?.length > 0 &&
                              topicsFile.created_by_details.map((user, index) =>
                                user.photo_url ? (
                                  <Avatar
                                    key={index}
                                    alt={user.initiales}
                                    src={user.photo_url}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      fontSize: 13,
                                      color: "#000",
                                      backgroundColor: "#cecece",
                                    }}
                                  />
                                ) : (
                                  <Avatar
                                    key={index}
                                    sx={{
                                      width: 30,
                                      height: 30,
                                      fontSize: 13,
                                      color: "#000",
                                      backgroundColor: "#cecece",
                                    }}
                                  >
                                    {user.initiales}
                                  </Avatar>
                                )
                              )}
                          </div>
                        </td>
                        <td>
                          {topicsFile.is_certified ? (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              style={{ color: "#2b652b", fontSize: "20px" }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>
                          <Dropdown>
                            <MenuButton
                              style={{ padding: "11px 16px", border: "unset" }}
                              disabled={loading}
                            >
                              <FontAwesomeIcon icon={faEllipsisVertical} />
                            </MenuButton>
                            <Menu>
                              <MenuItem
                                disabled={!isUserAdmin && !isUserManager}
                                onClick={() =>
                                  navigate(`/topics/edit/${topicsFile.id}`)
                                }
                              >
                                Modifier
                              </MenuItem>
                              <MenuItem
                                disabled={!isUserAdmin && !isUserManager}
                                onClick={() => deleteTopicsFile(topicsFile.id)}
                              >
                                Supprimer
                              </MenuItem>
                            </Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {filteredTopics?.length > 0 && (
              <div className={styles["pagination-container"]}>
                <Stack spacing={2}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, value) => setCurrentPage(value)}
                    count={totalPages}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
