import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "axios";
import SubmitButton from "common/components/Button/SubmitButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import ProjectForm from "common/containers/Forms/Project/ProjectForm";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useId, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./NewProjectPage.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

export default function NewProjectPage() {
  // Contexts
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [allUsersInformation, setAllUsersInformation] = useState({});
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Form states
  const formId = useId();

  useEffect(() => {
    fetchUsersInformation();
  }, []);

  useEffect(() => {
    if (formData) {
      const payload = {
        name: formData.name,
        user_id: currentUser.uid,
        available_credits: parseFloat(formData.available_credits),
        owner_ids: formData.owner_ids,
        authorized_user_ids: formData.authorized_user_ids,
      };
      saveProject(payload);
    }
  }, [formData]);

  const fetchUsersInformation = async () => {
    try {
      setLoading(true);
      const response = await axios.get(BACKEND_URL_CONFIG.getAllUserNames, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllUsersInformation(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_133);
    } finally {
      setLoading(false);
    }
  };

  const saveProject = async (payload) => {
    try {
      setLoading(true);
      const response = await axios.post(
        BACKEND_URL_CONFIG.createProject,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response?.status === 201) {
        toast.success("Votre nouveau projet a été créé.");
        navigate("/projects");
      }
    } catch (error) {
      if (error?.response?.status === 409) {
        toast.error(
          "Ce nom est utilisé pour un autre projet. Veuillez choisir un autre."
        );
        return;
      }
      toast.error(ERRORS.error_132);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/projects"
                label="Projets"
              />
              <CustomBreadcrumb label="Nouveau projet" href="#" />
            </Breadcrumbs>
          </div>
          <div className={styles["form-container"]}>
            <div className={styles["title-wrapper"]}>
              <div className={styles.title}>Créer un nouveau projet</div>
            </div>
            <div className={styles["form-wrapper"]}>
              <div className={styles["content"]}>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <ProjectForm
                    formId={formId}
                    setFormData={setFormData}
                    allUsersInformation={allUsersInformation}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </div>
            </div>
            <div className={styles["stepper-action-buttons-wrapper"]}>
              <SubmitButton
                disabled={isSubmitting}
                style={{ backgroundColor: "#142637cc", color: "white" }}
                formId={formId}
                value="Enregistrer"
              />
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
