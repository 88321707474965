import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import axios from "axios";
import Button from "common/components/Button/Button";
import LoadingButton from "common/components/Button/LoadingButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import { useAuth } from "common/hooks/useAuth";
import { useStepperContext } from "common/hooks/useStepperContext";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { toast } from "react-toastify";
import styles from "./SensitiveColumnsChoice.module.css";

export default function SensitiveColumnsChoice({ title }) {
  const { datasetId } = useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { datasetInfo, updateSensitiveColumns } = useStepperContext();

  const selectChangeHandler = async (event, newValue) => {
    const results = await updateSensitiveColumns(newValue, datasetId);
    if (!results) {
      toast.error(ERRORS.error_120);
      return;
    }
  };

  const triggerSaveDatasetEndpoint = async () => {
    const saveDatasetEndpoint = BACKEND_URL_CONFIG.saveDataset.replace(
      "{datasetId}",
      datasetId
    );
    try {
      setLoading(true);
      const response = await axios.post(
        saveDatasetEndpoint,
        {},
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Le dataset a été bien enregistré");
        navigate("/datasets");
      }
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }
      toast.error(ERRORS.error_121);
    } finally {
      setLoading(false);
    }
  };

  const previousButtonHandler = async () => {
    if (datasetId) {
      navigate(`/datasets/${datasetId}/step/3`, { replace: true });
      return;
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>
          <span>{title}</span>
          {loading && <LoadingSpinner />}
        </div>
      </div>

      <div className={styles["content-wrapper"]}>
        <FormControl sx={{ width: 400 }}>
          <FormLabel
            id="select-field-demo-label"
            htmlFor="select-field-demo-button"
          >
            Colonnes sensibles
          </FormLabel>
          <div>
            <Select
              multiple
              placeholder="Aucune colonne selectionnée"
              defaultValue={datasetInfo?.sensitive_columns}
              onChange={selectChangeHandler}
            >
              {Object.entries(datasetInfo?.stats?.columns || {})
                ?.sort((a, b) => a[1].index - b[1].index)
                ?.map(([columnId, columnInfo]) => (
                  <Option key={columnId} value={columnId}>
                    {columnInfo.name}
                  </Option>
                ))}
            </Select>
          </div>
        </FormControl>
      </div>
      <div className={styles["stepper-action-buttons-wrapper"]}>
        <Button onClick={previousButtonHandler}>Précedent</Button>
        {loading ? (
          <LoadingButton />
        ) : (
          <Button onClick={triggerSaveDatasetEndpoint}>Enregistrer</Button>
        )}
      </div>
    </div>
  );
}
