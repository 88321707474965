import { ALLOWED_BLOCKS_BY_SECTION } from "core/blocks-config";
import MainBlockSection from "pages/AddBlocks/components/MainBlockSection";
import React from "react";
import styles from "./Sidebar.module.css";

const Sidebar = React.memo(
  ({
    loadBlocks,
    preprocessBlocks,
    processBlocks,
    updateBlocksOrder,
    versionsInfo,
  }) => {
    const hasAnyJobInProgress = versionsInfo?.running_job_ids?.length > 0;

    return (
      <div className={styles.sidebar}>
        <div className={styles["sidebar-container"]}>
          <MainBlockSection
            title="Load"
            mainSectionId="load"
            allowedBlocks={ALLOWED_BLOCKS_BY_SECTION.load}
            sectionInfos={loadBlocks}
            updateBlocksOrder={updateBlocksOrder}
            hasAnyJobInProgress={hasAnyJobInProgress}
          ></MainBlockSection>
          <MainBlockSection
            title="Préprocess"
            mainSectionId="preprocess"
            allowedBlocks={ALLOWED_BLOCKS_BY_SECTION.preprocess}
            sectionInfos={preprocessBlocks}
            updateBlocksOrder={updateBlocksOrder}
            hasAnyJobInProgress={hasAnyJobInProgress}
          ></MainBlockSection>
          <MainBlockSection
            title="Process"
            mainSectionId="process"
            allowedBlocks={ALLOWED_BLOCKS_BY_SECTION.process}
            sectionInfos={processBlocks}
            updateBlocksOrder={updateBlocksOrder}
            hasAnyJobInProgress={hasAnyJobInProgress}
          ></MainBlockSection>
        </div>
      </div>
    );
  }
);

export default Sidebar;
