import { CircularProgress } from "@mui/material";
import React from "react";
import styles from "./LoadingCircular.module.css";

export default function LoadingCircular() {
  return (
    <div className={styles["loading-content-wrapper"]}>
      <CircularProgress sx={{ color: "#C6B79A" }} />
    </div>
  );
}
