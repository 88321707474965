import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth } from "common/hooks/useAuth";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./LoginPage.module.css";

export default function LoginPage() {
  const { login, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser !== null) {
      toast.info("Vous êtes déjà connecté");
      navigate("/");
      return;
    }
  }, [currentUser, navigate]);

  return (
    <Fragment>
      <main className={styles.container}>
        <div className={styles["login-wrapper"]}>
          <div className={styles["logo-wrapper"]}>{/* maybe logo ? */}</div>
          <div className={styles["welcome-text"]}>
            <h1 className={styles["greetings-text"]}>Bienvenue sur C-NLP !</h1>
            <p className={styles["description"]}>
              Connectez-vous pour accéder à l'application.
            </p>
          </div>
          <div className={styles["button-wrapper"]}>
            <button onClick={login} className={styles["login-button"]}>
              Se connecter avec <FontAwesomeIcon icon={faGoogle} />
            </button>
          </div>
        </div>
      </main>
    </Fragment>
  );
}
