import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import LoadDatasetWithDLPForm from "common/containers/Forms/Blocks/LoadDatasetWithDLPForm";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { BLOCK_TYPE_DISPLAY_NAME } from "core/blocks-config";
import { ERRORS } from "errors";
import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";
import { useCallback, useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function AddLoadDatasetWithDLP() {
  // Contexts
  const { sectionType, projectId, versionId, blockType, subBlockType } =
    useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [needAdvancedOptions, setNeedAdvancedOptions] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allDatasets, setAllDatasets] = useState([]);

  // Ref form
  // We need to use a ref to access the form values and submit them
  const formIdRef = useId();

  const fetchDatasets = useCallback(async () => {
    try {
      const response = await axios.get(
        `${BACKEND_URL_CONFIG.getDatasetNames}`,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        setAllDatasets(response.data.datasets);
      }
    } catch (error) {
      toast.error(ERRORS.error_118);
    }
  }, [currentUser]);

  // Get payload
  const getPayload = useCallback(() => {
    const payload = {
      type: blockType,
      subtype: subBlockType,
      section_type: sectionType,
      name: formData?.blockDisplayName,
      version_id: versionId,
      project_id: projectId,
      user_id: currentUser.uid,
      parameters: formData?.parameters,
    };
    return payload;
  }, [
    formData,
    blockType,
    subBlockType,
    sectionType,
    projectId,
    versionId,
    currentUser.uid,
  ]);

  const saveBlockIntoDB = useCallback(
    async (payload) => {
      setLoading(true);
      try {
        const response = await axios.post(
          BACKEND_URL_CONFIG.addBlock,
          payload,
          {
            headers: {
              "X-KEY": currentUser.accessToken,
            },
          }
        );
        if (response.status === 201) {
          toast.success("Votre block a été enregistré");
          navigate(
            `/projects/${projectId}/version/${versionId}/show/${sectionType}/${blockType}`
          );
        }
      } catch (error) {
        if (error.response.status === 403) {
          toast.error(ERRORS.error_129);
          return;
        } else if (error.response.status === 400) {
          toast.error(ERRORS.error_145);
          return;
        } else {
          toast.error(ERRORS.error_127);
        }
      } finally {
        setLoading(false);
      }
    },
    [currentUser, navigate, projectId, versionId, blockType, sectionType]
  );

  useEffect(() => {
    if (formData) {
      const payload = getPayload();
      saveBlockIntoDB(payload);
    }
  }, [formData, saveBlockIntoDB, getPayload]);

  useEffect(() => {
    const fetchDatasetsAsync = async () => {
      setLoading(true);
      await fetchDatasets();
      setLoading(false);
    };

    fetchDatasetsAsync();
  }, [fetchDatasets]);

  return (
    <BlockFormContainer>
      <BlockFormTitleContainer
        title={`Ajouter un bloc : ${BLOCK_TYPE_DISPLAY_NAME[subBlockType]}`}
        formId={formIdRef}
        loading={loading}
        needAdvancedOptions={needAdvancedOptions}
        isSubmitting={isSubmitting}
        setNeedAdvancedOptions={setNeedAdvancedOptions}
      />
      <BlockFormFieldsContainer>
        {loading ? (
          <LoadingSpinner iconCustomStyle={{ fontSize: "25px" }} />
        ) : (
          <LoadDatasetWithDLPForm
            setFormData={setFormData}
            formId={formIdRef}
            needAdvancedOptions={needAdvancedOptions}
            defaultBlockDisplayName={subBlockType}
            setIsSubmitting={setIsSubmitting}
            allDatasets={allDatasets}
          />
        )}
      </BlockFormFieldsContainer>
    </BlockFormContainer>
  );
}
