import { useForm } from "react-hook-form";

import { useEffect } from "react";
import styles from "./SaveTopicsParamsForm.module.css";

export default function SaveTopicsParamsForm({
  topicFileInformation,
  formId,
  setFormData,
  setIsSubmitting,
}) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      label: topicFileInformation ? topicFileInformation.name : "",
      description: topicFileInformation ? topicFileInformation.description : "",
      is_certified: topicFileInformation
        ? topicFileInformation.is_certified
        : false,
    },
  });

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  const onSubmit = (data) => {
    const submittedData = {
      label: data.label,
      description: data.description,
      is_certified: data.is_certified,
    };
    setFormData(submittedData);
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Label des topics</label>
        <input
          {...register("label", {
            required: true,
            validate: (value) => {
              return !!value.trim();
            },
          })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le label des paramètres"
        />
      </div>
      <div className={styles["checkbox-container"]}>
        <label className={styles.label}>Le fichier est-il certifié ?</label>
        <input
          {...register("is_certified")}
          type="checkbox"
          disabled={isSubmitting}
          className={styles["checkbox-input"]}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>
          Description des topics (optionnel)
        </label>
        <textarea
          {...register("description")}
          name="description"
          rows={40}
          cols={50}
          disabled={isSubmitting}
          className={styles["custom-textarea"]}
          placeholder="Saisir la description des paramètres"
        />
      </div>
    </form>
  );
}
