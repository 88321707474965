import {
  faCircle,
  faCircleCheck,
  faCirclePlus,
  faEdit,
  faGripVertical,
  faPlay,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "@mui/joy/Dropdown";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import CustomMenuButton from "common/components/Button/CustomMenuButton";
import IconButton from "common/components/Button/IconButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import {
  BLOCK_TYPE_DISPLAY_NAME,
  REDIRECT_BLOCK_PAGES_MAP,
} from "core/blocks-config";
import { ERRORS } from "errors";
import OrderChangesConfirmationModal from "pages/AddBlocks/components/OrderChangesConfirmationModal";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./MainBlockSection.module.css";

export default function MainBlockSection({
  title,
  mainSectionId,
  allowedBlocks,
  sectionInfos,
  updateBlocksOrder,
  hasAnyJobInProgress,
}) {
  // States
  const [loading, setLoading] = useState(false);
  const [dragId, setDragId] = useState();
  const [anyChanges, setAnyChanges] = useState(false);
  const [editable, setEditable] = useState(false);
  const [open, setOpen] = useState(false);
  const [filteredBlocks, setFilteredBlocks] = useState([]);

  // Contexts
  const { projectId, versionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    // Filter active blocks to get better display on fronts
    const initFilteredBlocks = Object.entries(sectionInfos || {})
      .map(([blockId, block]) => ({ id: blockId, ...block }))
      .filter((block) => allowedBlocks.includes(block.type) && block.active);

    setFilteredBlocks(initFilteredBlocks);
  }, [sectionInfos, allowedBlocks]);

  const confirmButtonHandler = () => {
    setLoading(true);
    try {
      if (!filteredBlocks.length > 0) {
        return;
      }
      const newFilteredBlocks = filteredBlocks.map((eachBlock) => {
        // We remove ID and get only block information
        // The goal is to send exactly same data to backend
        const { id, ...blocksInformation } = eachBlock;
        blocksInformation.order = parseInt(blocksInformation.order);
        return [eachBlock.id, blocksInformation];
      });
      const newOrderedBlocks = Object.fromEntries(newFilteredBlocks);
      updateBlocksOrder(mainSectionId, newOrderedBlocks);
    } catch (error) {
      toast.error(ERRORS.error_123);
    } finally {
      setOpen(false);
      setEditable(false);
      setLoading(false);
      setAnyChanges(false);
    }
  };

  const saveButtonHandler = () => {
    // If there are any changes in blocks order, so we open confirmation modal
    if (anyChanges) {
      setOpen(true);
      return;
    }
    setEditable(false);
    return;
  };

  const handleDrag = (e) => {
    e.target.className = `${styles["draggable-block-box-wrapper"]} ${styles["dragging"]}`;
    setDragId(e.target.id);
  };

  const handleDragEnd = (e) => {
    e.target.className = styles["draggable-block-box-wrapper"];
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    if (e.target.attributes?.mainsectionid?.value !== mainSectionId) {
      return;
    }

    // If the destination is one of the first two items, we skip the drag
    // Because we don't want to allow to drag first two items (load_dataset and set_languages blocks)
    if (
      (mainSectionId === "load" && e.target.id === filteredBlocks[0].id) ||
      (mainSectionId === "load" && e.target.id === filteredBlocks[1].id)
    ) {
      return;
    }

    // Get source block
    const dragBox = filteredBlocks.find((block) => block.id === dragId);
    const sourceOrder = dragBox?.order;

    // If the mouse leaves the dropzone
    if (sourceOrder === undefined) {
      return;
    }

    // Get destination block
    const destionationId = e.target.id;
    if (!e.target.attributes.order) {
      return;
    }
    const destinationOrder = e.target.attributes?.order.value;

    // If the destination is unknown
    if (destinationOrder === undefined) {
      return;
    }

    // The idea is to update filteredBlocks array with new order.
    // That's why, it's important to understand what's 'sourceOrder' and 'destinationOrder'.
    // It's not complicated, sourceOrder is about the html item that we drag
    // And the other hand, the destinationOrder is about the new html item for which we drag over
    const newFilteredBlocksArray = filteredBlocks.map((eachBlock) => {
      if (eachBlock.id === destionationId) {
        return { ...eachBlock, order: sourceOrder };
      }
      if (eachBlock.id === dragId) {
        return { ...eachBlock, order: destinationOrder };
      }
      return eachBlock;
    });
    if (
      JSON.stringify(filteredBlocks) !== JSON.stringify(newFilteredBlocksArray)
    ) {
      setFilteredBlocks(newFilteredBlocksArray);
      setAnyChanges(true);
    }
  };

  const handleDrop = (e) => {};

  const redirectToDynamicSection = (e) => {
    if (e.target.id) {
      navigate(
        `/projects/${projectId}/version/${versionId}/show/${mainSectionId}/${e.target.id}`
      );
    }
  };

  const redirectToRunPage = (mainSectionId) => {
    navigate(
      `/projects/${projectId}/version/${versionId}/run/${mainSectionId}`
    );
  };

  const redirectToBlockTypePage = (blockType) => {
    navigate(
      `/projects/${projectId}/version/${versionId}/show/${mainSectionId}/${blockType}`
    );
  };

  return (
    <div className={styles["main-block"]}>
      {/* Custom order changes confirmation modal */}
      <OrderChangesConfirmationModal
        open={open}
        setOpen={setOpen}
        confirmButtonHandler={confirmButtonHandler}
      />
      <div className={styles["main-block-header"]}>
        <div className={styles["title-wrapper"]}>
          <span>{title}</span>
          <span className={styles["title-block-number-information"]}>
            {filteredBlocks.length}
          </span>
          <span
            className={styles["play-button-wrapper"]}
            onClick={() => redirectToRunPage(mainSectionId)}
          >
            <FontAwesomeIcon className={styles["play-button"]} icon={faPlay} />
          </span>
          {loading && <LoadingSpinner />}
        </div>
        {/* If there is no item or only one, we skip order changes */}
        {filteredBlocks.length > 1 && (
          <div className={styles["title-actions-wrapper"]}>
            {editable ? (
              <span>
                <IconButton
                  style={{ fontSize: "13px" }}
                  onClick={saveButtonHandler}
                  disabled={hasAnyJobInProgress}
                >
                  <FontAwesomeIcon icon={faCircleCheck} />
                </IconButton>
              </span>
            ) : (
              <span>
                <IconButton
                  style={{ fontSize: "13px" }}
                  onClick={() => setEditable(!editable)}
                  disabled={hasAnyJobInProgress}
                >
                  <FontAwesomeIcon icon={faEdit} />
                </IconButton>
              </span>
            )}
          </div>
        )}
      </div>
      <div
        className={styles["main-block-content"]}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {filteredBlocks.length > 0 &&
          filteredBlocks
            .sort((a, b) => a.order - b.order)
            .map((block, index) => (
              <div
                draggable={
                  editable && (mainSectionId === "load" ? index > 1 : true)
                }
                className={
                  editable && (mainSectionId === "load" ? index > 1 : true)
                    ? styles["draggable-block-box-wrapper"]
                    : styles["block-box-wrapper"]
                }
                key={block.id}
                id={block.id}
                order={block.order}
                mainsectionid={mainSectionId}
                onDragStart={handleDrag}
                onDragEnd={handleDragEnd}
              >
                <div className={styles["block-name-wrapper"]}>
                  {editable && (mainSectionId === "load" ? index > 1 : true) ? (
                    <FontAwesomeIcon
                      style={{ color: "#000000" }}
                      icon={faGripVertical}
                    />
                  ) : (
                    <FontAwesomeIcon
                      className={styles["block-name-circle"]}
                      icon={faCircle}
                    />
                  )}
                  <span
                    style={{ textAlign: "left" }}
                    onClick={() => redirectToBlockTypePage(block.type)}
                  >
                    {/* We display section type label */}
                    {BLOCK_TYPE_DISPLAY_NAME?.[block.type] || block.type}
                  </span>
                </div>
              </div>
            ))}
        <div className={styles["main-block-add-new-block-container"]}>
          <Dropdown>
            <CustomMenuButton
              title="Ajouter un nouveau block"
              className={styles["add-new-block-custom-button"]}
            >
              <FontAwesomeIcon
                disabled={hasAnyJobInProgress}
                icon={faCirclePlus}
              />
            </CustomMenuButton>
            <Menu>
              {Object.entries(
                REDIRECT_BLOCK_PAGES_MAP?.[mainSectionId] || {}
              ).map(([blockId, displayName], index) => (
                <MenuItem
                  id={blockId}
                  onClick={redirectToDynamicSection}
                  disabled={hasAnyJobInProgress}
                  key={index}
                >
                  {displayName}
                </MenuItem>
              ))}
            </Menu>
          </Dropdown>
        </div>
      </div>
    </div>
  );
}
