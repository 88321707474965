import { db } from "core/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { createContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

export const ProjectContext = createContext();

const ProjectProvider = () => {
  const [loading, setLoading] = useState(true);
  const [projectInfo, setProjectInfo] = useState({});
  const { projectId } = useParams();

  useEffect(() => {
    const docRef = doc(db, "projects", projectId);
    // Connection to db with real time option
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const projectDoc = doc.data();
      if (projectDoc !== undefined) {
        setProjectInfo(projectDoc);
        setLoading(false);
      }
    });
    return () => unsubscribe();
  }, [projectId]);

  const value = { projectInfo };

  return (
    <ProjectContext.Provider value={value}>
      {!loading && <Outlet />}
    </ProjectContext.Provider>
  );
};

export default ProjectProvider;
