import EditCleanVerbatims from "./containers/EditCleanVerbatims";
import EditCompareDictionarySize from "./containers/EditCompareDictionarySize";
import EditGlobalStats from "./containers/EditGlobalStats";
import EditHapax from "./containers/EditHapax";
import EditLemmatizerIramuteq from "./containers/EditLemmatizerIramuteq";
import EditLemmatizerSpacy from "./containers/EditLemmatizerSpacy";
import EditLoadDataset from "./containers/EditLoadDataset";
import EditLoadDatasetWithDLP from "./containers/EditLoadDatasetWithDLP";
import EditNgramFrequencies from "./containers/EditNgramFrequencies";
import EditRemoveStopwords from "./containers/EditRemoveStopwords";
import EditSentencizeVerbatims from "./containers/EditSentencizeVerbatims";
import EditSentimentAnalysisAWS from "./containers/EditSentimentAnalysisAWS";
import EditSentimentAnalysisOpenAI from "./containers/EditSentimentAnalysisOpenAI";
import EditSentimentAnalysisTextBlob from "./containers/EditSentimentAnalysisTextBlob";
import EditSetLanguage from "./containers/EditSetLanguage";
import EditSpellcheckerOpenAI from "./containers/EditSpellcheckerOpenAI";
import EditTopicDetectionOpenAI from "./containers/EditTopicDetectionOpenAI";
import EditTopicModelingOpenAI from "./containers/EditTopicModelingOpenAI";
import EditTranslationGCP from "./containers/EditTranslationGCP";
import EditTranslationOpenAI from "./containers/EditTranslationOpenAI";
import EditWordcloud from "./containers/EditWordCloud";

const EDIT_BLOCKS_COMPONENT_REFERENTIAL = {
  load_dataset: <EditLoadDataset />,
  load_dataset_dlp: <EditLoadDatasetWithDLP />,
  clean_verbatims: <EditCleanVerbatims />,
  set_specific_languages: <EditSetLanguage />,
  set_global_language: <EditSetLanguage />,
  detect_languages_langid: <EditSetLanguage />,
  detect_languages_aws_comprehend: <EditSetLanguage />,
  sentencize_verbatims_spacy: <EditSentencizeVerbatims />,
  lemmatize_verbatims_spacy: <EditLemmatizerSpacy />,
  lemmatize_verbatims_iramuteq: <EditLemmatizerIramuteq />,
  remove_stopwords: <EditRemoveStopwords />,
  spellcheck_verbatims_openai_batch: <EditSpellcheckerOpenAI />,
  translate_verbatims_gct: <EditTranslationGCP />,
  translate_verbatims_openai_batch: <EditTranslationOpenAI />,
  generate_wordclouds: <EditWordcloud />,
  generate_global_stats: <EditGlobalStats />,
  compare_dictionary_sizes: <EditCompareDictionarySize />,
  count_hapax: <EditHapax />,
  generate_ngram_frequencies: <EditNgramFrequencies />,
  analyze_sentiments_openai_batch: <EditSentimentAnalysisOpenAI />,
  analyze_sentiments_textblob: <EditSentimentAnalysisTextBlob />,
  analyze_sentiments_aws_comprehend: <EditSentimentAnalysisAWS />,
  model_topics_openai_batch: <EditTopicModelingOpenAI />,
  detect_topics_openai_batch: <EditTopicDetectionOpenAI />,
};

export { EDIT_BLOCKS_COMPONENT_REFERENTIAL };
