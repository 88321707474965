import { Option, Select } from "@mui/joy";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearButton from "common/components/Button/ClearButton";
import DeleteButton from "common/components/Button/DeleteButton";
import TransparentButton from "common/components/Button/TransparentButton";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useCallback, useEffect, useState } from "react";
import { Controller, useFieldArray } from "react-hook-form";
import { useParams } from "react-router-dom";
import styles from "./TopicModelingOpenAIForm.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

const openaiModels = [
  ["gpt-4o-mini", "gpt-4o-mini"],
  ["gpt-4o", "gpt-4o"],
  ["gpt-4-turbo", "gpt-4-turbo"],
  ["gpt-4", "gpt-4"],
  ["gpt-3.5-turbo", "gpt-3.5-turbo"],
];

export default function TopicModelingOpenAIForm({
  block,
  formId,
  needAdvancedOptions,
  setFormData,
  setIsSubmitting,
  defaultBlockDisplayName,
  createNewBlockFromSavedTopics,
}) {
  // States
  const [loading, setLoading] = useState(false);
  const [availableColumnsInfo, setAvailableColumnsInfo] = useState(null);
  const [savedTopics, setSavedTopics] = useState([]);
  const [topicsImported, setTopicsImported] = useState(false);

  // Contexts
  const { currentUser } = useAuth();
  const { versionId, projectId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      multiclass: block ? block.parameters.multiclass : false,
      topics: block ? block.parameters.topics : [],
      blockDisplayName: block ? block.name : defaultBlockDisplayName,
      language_column_id: block ? block.parameters.language_column_id : null,
      verbatim_column_id: block ? block.parameters.verbatim_column_id : null,
      saved_topics_id: block ? block.saved_topics_id : null,
      model: block ? block.parameters.model : "gpt-3.5-turbo",
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "topics",
    rules: {
      required: "Veuillez saisir au moins un topic !",
    },
  });

  const fetchDetectedTopics = useCallback(async () => {
    try {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getDetectedTopics
        .replace("{projectId}", projectId)
        .replace("{versionId}", versionId);
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        return response.data;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      } else if (error.response.status === 404) {
        toast.info(ERRORS.error_190);
        return;
      }
      toast.error(ERRORS.error_189);
    } finally {
      setLoading(false);
    }
  }, [currentUser, projectId, versionId]);

  const fetchAvailableColumns = useCallback(async () => {
    setLoading(true);
    const url = BACKEND_URL_CONFIG.getAvailableColumnsInfo.replace(
      "{versionId}",
      versionId
    );
    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAvailableColumnsInfo(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_130);
    } finally {
      setLoading(false);
    }
  }, [currentUser, versionId]);

  const fetchSavedTopics = useCallback(
    async (controller) => {
      const url = `${BACKEND_URL_CONFIG.getTopicsFilesList}?q=names`;
      try {
        const response = await axios.get(url, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: controller.signal,
        });
        if (response.status === 200) {
          setSavedTopics(response.data);
        }
      } catch (error) {
        toast.error(ERRORS.error_181);
      }
    },
    [currentUser]
  );

  useEffect(() => {
    fetchAvailableColumns();
  }, [fetchAvailableColumns]);

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);

  useEffect(() => {
    const controller = new AbortController();
    if (createNewBlockFromSavedTopics) {
      fetchSavedTopics(controller);
    }
    return () => controller.abort();
  }, [createNewBlockFromSavedTopics, fetchSavedTopics]);

  const onSubmit = (data) => {
    const uniqueTopics = [...new Set(data.topics.map((topic) => topic.name))];
    if (data.topics.length !== uniqueTopics.length) {
      toast.error(ERRORS.error_191);
      return;
    }
    const submittedData = {
      parameters: {
        model: data.model,
        multiclass: data.multiclass,
        topics: data.topics,
        language_column_id: data.language_column_id,
        language_column_id_set_by_user: !!data.language_column_id,
        verbatim_column_id: data.verbatim_column_id,
        verbatim_column_id_set_by_user: !!data.verbatim_column_id,
      },
      blockDisplayName: data.blockDisplayName,
    };

    if (createNewBlockFromSavedTopics) {
      submittedData.parameters.saved_topics_id = data.saved_topics_id;
    }
    setFormData(submittedData);
  };

  const handleImportTopics = async () => {
    const detectedTopics = await fetchDetectedTopics();
    if (!detectedTopics) {
      return;
    }
    if (detectedTopics.length === 0) {
      toast.info(ERRORS.error_190);
      return;
    }
    detectedTopics.forEach((topicName) => {
      const checkTopicFieldAlreadyExists = (topicName) =>
        fields.some((field) => field.name === topicName);
      if (!checkTopicFieldAlreadyExists(topicName)) {
        append({ name: topicName, description: "" });
      }
    });
    setTopicsImported(true);
  };
  const onError = (errors) => {
    if (errors.topics?.root?.message) {
      toast.error(errors.topics?.root?.message);
    }
    if (errors.topics?.custom?.message) {
      toast.error(errors.topics?.custom?.message);
    }
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit, onError)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom du bloc</label>
        <input
          {...register("blockDisplayName", { required: true })}
          type="text"
          required={true}
          className={styles["text-input"]}
          placeholder="Saisir le nom de votre bloc"
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Modèle</label>
        <Controller
          name="model"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              required={true}
              placeholder="Choisir votre modèle..."
              style={customSelectStyle}
              disabled={loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {openaiModels?.map(([modelId, modelName]) => (
                <Option key={modelId} value={modelId}>
                  {modelName}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["checkbox-container"]}>
        <label className={styles.label}>Multiclasse ?</label>
        <input
          {...register("multiclass")}
          type="checkbox"
          disabled={isSubmitting}
          className={styles["checkbox-input"]}
        />
      </div>
      {!createNewBlockFromSavedTopics ? (
        fields.map((item, index) => (
          <div key={item.id} className={styles["form-row"]}>
            <div
              style={{ display: "flex", gap: "10px", alignItems: "flex-end" }}
            >
              <div
                className={styles["field-container"]}
                style={{ width: "100%" }}
              >
                <label
                  className={styles.label}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span style={{ fontSize: "13px" }}>Topic :</span>
                  <span style={{ fontSize: "10px", color: "red" }}>
                    {errors.topics?.[index]?.name?.message}
                  </span>
                </label>
                <input
                  {...register(`topics.${index}.name`, {
                    validate: (value) => {
                      const isUnique = !fields.some(
                        (item, i) => i !== index && item.name === value
                      );
                      return isUnique ? isUnique : "Ce topic existe déjà";
                    },
                  })}
                  placeholder="Nom du topic"
                  className={styles["text-input"]}
                />
              </div>

              <div
                className={styles["field-container"]}
                style={{ width: "100%" }}
              >
                <label className={styles.label}>Description :</label>
                <input
                  {...register(`topics.${index}.description`)}
                  placeholder="Description du topic"
                  className={styles["text-input"]}
                />
              </div>
              <div>
                <DeleteButton onClick={() => remove(index)} />
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className={styles["field-container"]}>
          <label className={styles.label}>Topics enregistrés</label>
          <Controller
            name="saved_topics_id"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <Select
                {...field}
                required={true}
                placeholder="Choisir vos topics enregistrés..."
                style={customSelectStyle}
                disabled={loading}
                onChange={(e, newValue) => {
                  field.onChange(newValue);
                }}
              >
                {savedTopics?.map((savedTopic) => (
                  <Option key={savedTopic.id} value={savedTopic.id}>
                    {savedTopic.name}
                  </Option>
                ))}
              </Select>
            )}
          />
        </div>
      )}
      {needAdvancedOptions && (
        <>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Colonne des verbatims</label>
            <Controller
              name="verbatim_column_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Choisir la colonne des verbatims..."
                  style={customSelectStyle}
                  disabled={loading}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  {...(field.value && {
                    endDecorator: (
                      <ClearButton
                        onClick={() => {
                          field.onChange(null);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </ClearButton>
                    ),
                    indicator: null,
                  })}
                >
                  {Object.entries(availableColumnsInfo || {}).map(
                    ([columnId, columnName]) => (
                      <Option key={columnId} value={columnId}>
                        {columnName}
                      </Option>
                    )
                  )}
                </Select>
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Colonne de langue à choisir</label>
            <Controller
              name="language_column_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Choisir la colonne de langue..."
                  style={customSelectStyle}
                  disabled={loading}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  {...(field.value && {
                    endDecorator: (
                      <ClearButton
                        onClick={() => {
                          field.onChange(null);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </ClearButton>
                    ),
                    indicator: null,
                  })}
                >
                  {Object.entries(availableColumnsInfo || {}).map(
                    ([columnId, columnName]) => (
                      <Option key={columnId} value={columnId}>
                        {columnName}
                      </Option>
                    )
                  )}
                </Select>
              )}
            />
          </div>
        </>
      )}
      {!createNewBlockFromSavedTopics && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "center" }}>
            <TransparentButton
              type="button"
              onClick={() => append({ name: "", description: "" })}
              disabled={loading}
            >
              Ajouter un topic
            </TransparentButton>
          </div>
          {!topicsImported && (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <TransparentButton
                type="button"
                onClick={handleImportTopics}
                disabled={loading}
              >
                Importer les topics détectés
              </TransparentButton>
            </div>
          )}
        </div>
      )}
    </form>
  );
}
