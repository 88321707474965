import { LinearProgress, Table } from "@mui/joy";
import { useAuth } from "common/hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import axios from "axios";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useVersions } from "common/hooks/useVersions";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { toast } from "react-toastify";
import styles from "./PreviewWorkingDatasetPage.module.css";

export default function PreviewWorkingDatasetPage() {
  // States
  const [loading, setLoading] = useState(false);
  const [columnNames, setColumnNames] = useState([]);

  // Contexts
  const { projectId, versionId } = useParams();
  const { currentUser } = useAuth();
  const { versionsInfo } = useVersions();

  const triggerDatasetPreviewEndpoint = useCallback(async () => {
    const payload = {
      project_id: projectId,
      version_id: versionId,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        BACKEND_URL_CONFIG.triggerDatasetPreview,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 201) {
        toast.info("Nous récupérons les données du dataset...");
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      } else {
        toast.error(ERRORS.error_186);
      }
    } finally {
      setLoading(false);
    }
  }, [currentUser, projectId, versionId]);

  useEffect(() => {
    triggerDatasetPreviewEndpoint();
  }, [triggerDatasetPreviewEndpoint]);

  useEffect(() => {
    if (versionsInfo?.preview && Object.keys(versionsInfo.preview).length > 0) {
      const columnNames = Object.values(versionsInfo.preview)[0].map(
        (eachRow) => eachRow.column_name
      );
      setColumnNames(columnNames);
    }
  }, [versionsInfo]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LinearProgress />
        </div>
      ) : columnNames.length > 0 ? (
        <div className={styles["content"]}>
          <Table style={{ width: "100%" }}>
            <thead>
              <tr>
                {columnNames?.map((eachColumnName) => (
                  <th
                    key={eachColumnName}
                    className={
                      eachColumnName === "id" ? styles["id-column"] : ""
                    }
                    style={{ width: "150px" }}
                  >
                    {eachColumnName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(versionsInfo?.preview || {}).map(
                (eachRowObj, index) => (
                  <tr key={index}>
                    {Object.values(eachRowObj).map((row) => (
                      <td
                        style={{ wordWrap: "break-word" }}
                        key={row.column_name}
                      >
                        {row.value}
                      </td>
                    ))}
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <ItemNotFoundContainer message="Nous n'avons pas trouvé de données pour le moment." />
        </div>
      )}
    </>
  );
}
