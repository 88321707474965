import axios from "axios";
import TransparentButton from "common/components/Button/TransparentButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import TabsContainer from "common/containers/Tabs";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./WordcloudAnalysis.module.css";

export default function WordcloudAnalysisContainer() {
  // States
  const [imgInformation, setImgInformation] = useState({});
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  //   Contexts
  const { currentUser } = useAuth();
  const { projectId, versionId } = useParams();

  const segmentingKeys = Object.keys(imgInformation);
  const structuredImgInformation = Object.entries(imgInformation).reduce(
    (acc, [key, value], index) => {
      acc[index] = { name: key, code: value };
      return acc;
    },
    {}
  );

  useEffect(() => {
    fetchWordCloudImg();
  }, []);

  const fetchWordCloudImg = async () => {
    setLoading(true);
    const url = BACKEND_URL_CONFIG.getWordCloudImages
      .replace("{projectId}", projectId)
      .replace("{versionId}", versionId);

    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setImgInformation(response.data || {});
        return;
      }
    } catch (error) {
      toast.error(ERRORS.error_137);
      return;
    } finally {
      setLoading(false);
    }
  };

  const downloadImg = async () => {
    var a = document.createElement("a");
    a.href =
      "data:image/png;base64," + structuredImgInformation?.[currentTab]?.code;
    a.download = `${structuredImgInformation?.[currentTab]?.name}.png`;
    a.click();
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={styles["container"]}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {segmentingKeys && segmentingKeys.length === 0 ? (
            <ItemNotFoundContainer message="Aucune analyse n’a été trouvée." />
          ) : (
            <>
              <TabsContainer
                tabsData={segmentingKeys}
                handleTabChange={handleTabChange}
              />
              <div className={styles["wordcloud-img-container"]}>
                <img
                  src={`data:image/png;base64,${structuredImgInformation?.[currentTab]?.code}`}
                  style={{ width: "450px", height: "450px" }}
                />
              </div>
              <div className={styles["download-button-container"]}>
                <TransparentButton onClick={downloadImg}>
                  Télécharger
                </TransparentButton>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
