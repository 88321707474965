import { useAuth } from "common/hooks/useAuth";

import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import SpellcheckerOpenAIForm from "common/containers/Forms/Blocks/SpellcheckerOpenAIForm";
import { BACKEND_URL_CONFIG } from "config";
import { BLOCK_TYPE_DISPLAY_NAME } from "core/blocks-config";
import { ERRORS } from "errors";
import { toast } from "react-toastify";

export default function AddSpellcheckerOpenAI() {
  // Contexts
  const { sectionType, projectId, versionId, blockType, subBlockType } =
    useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // States
  const [needAdvancedOptions, setNeedAdvancedOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);

  // Refs
  const formIdRef = useId();

  useEffect(() => {
    if (formData) {
      const payload = {
        type: blockType,
        subtype: subBlockType,
        section_type: sectionType,
        name: formData.blockDisplayName,
        version_id: versionId,
        project_id: projectId,
        user_id: currentUser.uid,
        parameters: formData.parameters,
      };
      saveBlockIntoDB(payload);
    }
  }, [formData]);

  const saveBlockIntoDB = async (payload) => {
    setLoading(true);
    try {
      const response = await axios.post(BACKEND_URL_CONFIG.addBlock, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 201) {
        toast.success("Votre block a été enregistré");
        navigate(
          `/projects/${projectId}/version/${versionId}/show/${sectionType}/${blockType}`
        );
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      } else if (error.response.status === 400) {
        toast.error(ERRORS.error_145);
      } else {
        toast.error(ERRORS.error_127);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <BlockFormContainer>
      <BlockFormTitleContainer
        title={`Ajouter un bloc : ${BLOCK_TYPE_DISPLAY_NAME[subBlockType]}`}
        formId={formIdRef}
        loading={loading}
        needAdvancedOptions={needAdvancedOptions}
        isSubmitting={isSubmitting}
        setNeedAdvancedOptions={setNeedAdvancedOptions}
      />
      <BlockFormFieldsContainer>
        {loading ? (
          <LoadingSpinner iconCustomStyle={{ fontSize: "25px" }} />
        ) : (
          <SpellcheckerOpenAIForm
            setFormData={setFormData}
            formId={formIdRef}
            needAdvancedOptions={needAdvancedOptions}
            defaultBlockDisplayName={subBlockType}
            setIsSubmitting={setIsSubmitting}
          />
        )}
      </BlockFormFieldsContainer>
    </BlockFormContainer>
  );
}
