import styles from "./Switch.module.css";

function Switch(props) {
  return (
    <>
      <label className={styles.switch}>
        <input
          ref={props.inputRef}
          onChange={props.onChange}
          type="checkbox"
          disabled={props.disabled}
          checked={props.checked}
        />
        <span></span>
      </label>
      {props.children && <span>{props.children}</span>}
    </>
  );
}

export default Switch;
