import AddCleanVerbatims from "./containers/AddCleanVerbatims";
import AddCompareDictionarySize from "./containers/AddCompareDictionarySize";
import AddGlobalStats from "./containers/AddGlobalStats";
import AddHapax from "./containers/AddHapax";
import AddLemmatizerIramuteq from "./containers/AddLemmatizerIramuteq";
import AddLemmatizerSpacy from "./containers/AddLemmatizerSpacy";
import AddLoadDataset from "./containers/AddLoadDataset";
import AddLoadDatasetWithDLP from "./containers/AddLoadDatasetWithDLP";
import AddNgramFrequencies from "./containers/AddNgramFrequencies";
import AddRemoveStopwords from "./containers/AddRemoveStopwords";
import AddSentencizeVerbatims from "./containers/AddSentencizeVerbatims";
import AddSentimentAnalysisAWS from "./containers/AddSentimentAnalysisAWS";
import AddSentimentAnalysisOpenAI from "./containers/AddSentimentAnalysisOpenAI";
import AddSentimentAnalysisTextBlob from "./containers/AddSentimentAnalysisTextBlob";
import AddSetLanguage from "./containers/AddSetLanguage";
import AddSpellcheckerOpenAI from "./containers/AddSpellcheckerOpenAI";
import AddTopicDetectionOpenAI from "./containers/AddTopicDetectionOpenAI";
import AddTopicModelingOpenAI from "./containers/AddTopicModelingOpenAI";
import AddTranslationGCP from "./containers/AddTranslationGCP";
import AddTranslationOpenAI from "./containers/AddTranslationOpenAI";
import AddWordcloud from "./containers/AddWordCloud";

const ADD_BLOCKS_COMPONENT_REFERENTIAL = {
  load_dataset: <AddLoadDataset />,
  load_dataset_dlp: <AddLoadDatasetWithDLP />,
  set_languages: <AddSetLanguage />,
  sentencize_verbatims_spacy: <AddSentencizeVerbatims />,
  lemmatize_verbatims_spacy: <AddLemmatizerSpacy />,
  lemmatize_verbatims_iramuteq: <AddLemmatizerIramuteq />,
  remove_stopwords: <AddRemoveStopwords />,
  spellcheck_verbatims_openai_batch: <AddSpellcheckerOpenAI />,
  translate_verbatims_gct: <AddTranslationGCP />,
  translate_verbatims_openai_batch: <AddTranslationOpenAI />,
  clean_verbatims: <AddCleanVerbatims />,
  generate_wordclouds: <AddWordcloud />,
  generate_global_stats: <AddGlobalStats />,
  compare_dictionary_sizes: <AddCompareDictionarySize />,
  count_hapax: <AddHapax />,
  generate_ngram_frequencies: <AddNgramFrequencies />,
  analyze_sentiments_openai_batch: <AddSentimentAnalysisOpenAI />,
  analyze_sentiments_textblob: <AddSentimentAnalysisTextBlob />,
  analyze_sentiments_aws_comprehend: <AddSentimentAnalysisAWS />,
  model_topics_openai_batch: <AddTopicModelingOpenAI />,
  detect_topics_openai_batch: <AddTopicDetectionOpenAI />,
};

export { ADD_BLOCKS_COMPONENT_REFERENTIAL };
