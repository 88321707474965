import { useAuth } from "common/hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { db } from "core/firebase";
import { ERRORS } from "errors";
import { doc, getDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const ProtectedProject = () => {
  // States
  const [isChecking, setIsChecking] = useState(true);

  // Contexts
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isUserAdmin } = useAuth();

  const checkAccess = useCallback(async () => {
    try {
      const projectRef = doc(db, "projects", projectId);
      const docRef = await getDoc(projectRef);

      if (docRef.exists()) {
        const projectData = docRef.data();
        const hasAccess =
          projectData.owner_ids.includes(currentUser.uid) ||
          projectData.authorized_user_ids.includes(currentUser.uid) ||
          isUserAdmin;

        if (hasAccess) {
          setIsChecking(false);
        } else {
          toast.error(ERRORS.error_129);
          navigate("/projects");
        }
      } else {
        toast.error(ERRORS.error_184);
        navigate("/projects");
      }
    } catch (error) {
      toast.error(error.message);
      navigate("/projects");
    }
  }, [projectId, currentUser, navigate, isUserAdmin]);

  useEffect(() => {
    if (projectId && currentUser.uid) {
      checkAccess();
    }
  }, [checkAccess, projectId, currentUser]);

  return !isChecking ? <Outlet /> : null;
};

export default ProtectedProject;
