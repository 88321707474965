import {
  faCircleExclamation,
  faCirclePlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TransparentButton from "common/components/Button/TransparentButton";
import { useAuth } from "common/hooks/useAuth";
import MainLayout from "layouts/MainLayout";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./Homepage.module.css";

export default function Homepage() {
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.title}>
              Bienvenue {currentUser?.displayName} 👋🏻
            </div>
            <div className={styles["actions-buttons-wrapper"]}>
              <TransparentButton onClick={() => navigate("/datasets/new")}>
                <FontAwesomeIcon icon={faCirclePlus} />
                Nouveau dataset
              </TransparentButton>
              <TransparentButton onClick={() => navigate("/projects/new")}>
                <FontAwesomeIcon icon={faCirclePlus} />
                Nouveau projet
              </TransparentButton>
            </div>
          </div>
          <div className={styles["welcome-page-wrapper"]}>
            <div>
              <FontAwesomeIcon
                icon={faCircleExclamation}
                style={{ fontSize: "50px", color: "rgb(199, 133, 27)" }}
              />
            </div>
            <div className={styles["text-wrapper"]}>
              <span>
                <b>Vous n'avez participé à aucun projet.</b>
              </span>
              <span>
                Pour commnecer, vous pouvez aller ajouter votre dataset et/ou
                créer un nouveau projet 😊
              </span>
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
