import { useForm } from "react-hook-form";

import { useEffect } from "react";
import styles from "./SaveParametersForm.module.css";

export default function SaveParametersForm({
  parametersFileInformation,
  formId,
  setFormData,
  setIsSubmitting,
}) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      parameters_label: parametersFileInformation
        ? parametersFileInformation.name
        : "",
      parameters_description: parametersFileInformation
        ? parametersFileInformation.description
        : "",
      is_certified: parametersFileInformation
        ? parametersFileInformation.is_certified
        : false,
    },
  });

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  const onSubmit = (data) => {
    const submittedData = {
      parameters_label: data.parameters_label,
      parameters_description: data.parameters_description,
      is_certified: data.is_certified,
    };
    setFormData(submittedData);
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Label des paramètres</label>
        <input
          {...register("parameters_label", {
            required: true,
            validate: (value) => {
              return !!value.trim();
            },
          })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le label des paramètres"
        />
      </div>
      <div className={styles["checkbox-container"]}>
        <label className={styles.label}>Le fichier est-il certifié ?</label>
        <input
          {...register("is_certified")}
          type="checkbox"
          disabled={isSubmitting}
          className={styles["checkbox-input"]}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>
          Description des paramètres (optionnel)
        </label>
        <textarea
          {...register("parameters_description")}
          name="parameters_description"
          rows={40}
          cols={50}
          disabled={isSubmitting}
          className={styles["custom-textarea"]}
          placeholder="Saisir la description des paramètres"
        />
      </div>
    </form>
  );
}
