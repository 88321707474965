import { faClock, faSignOut, faUser } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Menu, MenuItem } from "@mui/joy";
import CustomMenuButton from "common/components/Button/CustomMenuButton";
import { useAuth } from "common/hooks/useAuth";
import { useNavigate } from "react-router-dom";
import styles from "./Navbar.module.css";
const Navbar = () => {
  const { currentUser, isUserAdmin, logout } = useAuth();
  const navigate = useNavigate();

  return (
    <header className={styles["container"]}>
      <div className={styles.header}>
        <div className={styles["logo-container"]}>
          <span className={styles["logo-name"]} onClick={() => navigate("/")}>
            C-NLP
          </span>
          {process.env.NODE_ENV === "development" && (
            <div className={styles["environment-info-container"]}>
              development
            </div>
          )}
        </div>
        <div className={styles.content}>
          <div className={styles["page-menu"]}>
            <span
              className={styles["navbar-menu-item"]}
              onClick={() => navigate("/datasets")}
            >
              Gestion des datasets
            </span>
            <span
              className={styles["navbar-menu-item"]}
              onClick={() => navigate("/projects")}
            >
              Gestion des projets
            </span>
            <Dropdown>
              <CustomMenuButton
                className={styles["navbar-menu-item"]}
                style={{ border: "unset" }}
              >
                <span style={{ all: "unset" }}></span>
                Bibliothèques
              </CustomMenuButton>
              <Menu>
                <MenuItem onClick={() => navigate("/stopwords")}>
                  Stopwords
                </MenuItem>
                <MenuItem onClick={() => navigate("/lemmas")}>Lemmes</MenuItem>
                <MenuItem onClick={() => navigate("/masks")}>Masks</MenuItem>
                <MenuItem onClick={() => navigate("/parameters")}>
                  Paramètres
                </MenuItem>
                <MenuItem onClick={() => navigate("/topics")}>Topics</MenuItem>
              </Menu>
            </Dropdown>
          </div>
          <Dropdown>
            <CustomMenuButton style={{ border: "unset" }}>
              <div className={styles["user-info"]}>
                <img
                  src={currentUser?.photoURL}
                  className={styles["user-avatar"]}
                  alt=""
                ></img>
              </div>
            </CustomMenuButton>

            <Menu>
              {isUserAdmin && (
                <>
                  <MenuItem onClick={() => navigate("/users")}>
                    <div className={styles["dropdown-item"]}>
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ fontSize: "15px" }}
                      />
                      Utilisateur
                    </div>
                  </MenuItem>
                  <MenuItem>
                    <div
                      className={styles["dropdown-item"]}
                      onClick={() => navigate("/jobs")}
                    >
                      <FontAwesomeIcon
                        icon={faClock}
                        style={{ fontSize: "15px" }}
                      />
                      Jobs
                    </div>
                  </MenuItem>
                </>
              )}
              <MenuItem onClick={logout}>
                <div className={styles["dropdown-item"]}>
                  <FontAwesomeIcon
                    icon={faSignOut}
                    style={{ fontSize: "15px" }}
                  />
                  Se déconnecter
                </div>
              </MenuItem>
            </Menu>
          </Dropdown>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
