import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import { faPlay, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import Button from "common/components/Button/Button";
import FailedIcon from "common/components/Icon/FailedIcon";
import WaitingIcon from "common/components/Icon/NotProcessedIcon";
import SuccessIcon from "common/components/Icon/SuccessIcon";
import LoadingSpinner from "common/components/LoadingSpinner";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import {
  BLOCK_HAVING_OUTPUT_PREVIEW,
  BLOCK_TYPE_DISPLAY_NAME,
} from "core/blocks-config";
import { db } from "core/firebase";
import { ERRORS } from "errors";
import { doc, onSnapshot } from "firebase/firestore";
import { useVersions } from "hooks/useVersions";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./RunnableBlock.module.css";

// Status icon mapping to make it dynamic
const StatusIcon = ({ status }) => {
  const icons = {
    not_processed: <WaitingIcon />,
    in_progress: <LoadingSpinner />,
    failed: <FailedIcon />,
    processed: <SuccessIcon />,
  };

  return icons[status.toLowerCase()] || null;
};

function RunnableBlock({
  blockId,
  blockType,
  currentJobId,
  blockInformation,
  editEnabled,
  shouldDeactivateButtons,
}) {
  // States
  const [isDeleting, setIsDeleting] = useState(false);
  const [loadingEstimation, setLoadingEstimation] = useState(false);
  const [loadingRun, setLoadingRun] = useState(false);
  const [currentJobDocument, setCurrentJobDocument] = useState(null);
  const [previousBlockExecuted, setPreviousBlockExecuted] = useState(false);

  // Context
  const { currentUser } = useAuth();
  const { versionsInfo, isPreviousBlockExecuted } = useVersions();
  const { sectionType, projectId, versionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (blockId) {
      const isExecuted = isPreviousBlockExecuted(blockId);
      setPreviousBlockExecuted(isExecuted);
    }
  }, [versionsInfo, blockId, isPreviousBlockExecuted]);

  useEffect(() => {
    if (!currentJobId) {
      setCurrentJobDocument(null);
      return;
    }

    const docRef = doc(db, "jobs", currentJobId);
    // Connection to db with real time option
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const currentJobData = doc.data();
      if (currentJobData !== undefined) {
        setCurrentJobDocument(currentJobData);
      }
    });

    return () => unsubscribe();
  }, [currentJobId]);

  const deleteButtonHandler = async (blockId) => {
    const payload = {
      version_id: versionId,
      block_id: blockId,
      section_type: sectionType,
      user_id: currentUser.uid,
      project_id: projectId,
    };
    setIsDeleting(true);
    try {
      const response = await axios.delete(BACKEND_URL_CONFIG.deleteBlock, {
        data: payload,
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("Le block a été supprimé");
        return;
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.info(
          "Le bloc est protégé. Supprimez d'abord les blocs qui le suivent."
        );
        return;
      }
      if (error.response.status === 403) {
        toast.error(
          "Vous n'êtes pas autorisé à supprimer des blocks. Contactez un administrateur."
        );
        return;
      }
      toast.error(ERRORS.error_124);
      return;
    } finally {
      setIsDeleting(false);
    }
  };

  const costEstimateTrigger = async (subBlockType) => {
    if (!subBlockType) {
      toast.error(ERRORS.error_128);
      return;
    }

    setLoadingEstimation(true);
    const payload = {
      project_id: projectId,
      version_id: versionId,
      block_id: blockId,
      user_id: currentUser.uid,
    };
    const url = BACKEND_URL_CONFIG.costEstimationByBlockType.replace(
      "{blockType}",
      subBlockType
    );
    try {
      const response = await axios.post(url, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("L'estimation est lancée.");
        return;
      }
    } catch (error) {
      if (error.response?.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      }
      return;
    } finally {
      setLoadingEstimation(false);
    }
  };

  const runBlock = async (processId) => {
    setLoadingRun(true);
    const payload = {
      project_id: projectId,
      version_id: versionId,
      block_id: blockId,
      user_id: currentUser.uid,
    };
    try {
      const url = BACKEND_URL_CONFIG.triggerProcessById.replace(
        "{processId}",
        processId
      );
      const response = await axios.post(url, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("Le bloc a été executé.");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
      }
    } finally {
      setLoadingRun(false);
    }
  };

  return (
    <div key={blockId} className={styles["row-container"]}>
      <div className={styles["block-type-title"]}>
        {BLOCK_TYPE_DISPLAY_NAME?.[blockType] || blockType}
      </div>
      <div className={styles["block-container"]}>
        <div className={styles["block-informations"]}>
          <div className={styles["block-name"]}>{blockInformation.name}</div>
          {currentJobDocument && (
            <StatusIcon status={currentJobDocument.status} />
          )}
        </div>
        <div className={styles["block-actions-buttons"]}>
          {/* Button to display the output if the block has one */}
          {BLOCK_HAVING_OUTPUT_PREVIEW.includes(blockInformation.subtype) &&
            currentJobDocument?.status === "PROCESSED" &&
            blockInformation.is_executed && (
              <Button
                className={styles["run-button"]}
                onClick={() =>
                  navigate(
                    `/show/output/${sectionType}/${blockInformation.subtype}/${projectId}/${versionId}`
                  )
                }
              >
                <FontAwesomeIcon icon={faFolderOpen} /> Analyse
              </Button>
            )}
          {/* The block should have has_cost parameter True to be estimated */}
          {/* Estimate button */}
          {blockInformation.has_cost && (
            <>
              {blockInformation.estimated_cost !== -1 && (
                <span className={styles["estimated-cost-information"]}>
                  Estimation :{" "}
                  {blockInformation.estimated_cost.toFixed(2) === "0.00"
                    ? "0.01"
                    : blockInformation.estimated_cost.toFixed(2)}
                  €
                </span>
              )}
              <Button
                className={styles["cost-estimate-button"]}
                title="Estimer le coût"
                onClick={() => costEstimateTrigger(blockInformation?.subtype)}
                disabled={
                  blockInformation.is_executed ||
                  versionsInfo?.running_job_ids?.length > 0 ||
                  isDeleting ||
                  shouldDeactivateButtons ||
                  !previousBlockExecuted
                }
              >
                {loadingEstimation ? <LoadingSpinner /> : <>€</>}
              </Button>
            </>
          )}
          <Button
            className={styles["run-button"]}
            onClick={() => runBlock(blockInformation.subtype)}
            disabled={
              blockInformation.is_executed ||
              isDeleting ||
              shouldDeactivateButtons ||
              !previousBlockExecuted ||
              (blockInformation.has_cost &&
                blockInformation.estimated_cost === -1) ||
              versionsInfo?.running_job_ids?.length > 0
            }
          >
            {loadingRun ? (
              <LoadingSpinner />
            ) : (
              <>
                <FontAwesomeIcon icon={faPlay} />{" "}
                {blockInformation.is_executed ? "Executé" : "Run"}
              </>
            )}
          </Button>
          {editEnabled && (
            <>
              <Button
                className={`${styles["delete-button"]}`}
                onClick={() => deleteButtonHandler(blockId)}
                disabled={
                  isDeleting ||
                  shouldDeactivateButtons ||
                  versionsInfo?.running_job_ids?.length > 0
                }
              >
                {isDeleting ? (
                  <LoadingSpinner />
                ) : (
                  <FontAwesomeIcon icon={faTrashAlt} />
                )}
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default RunnableBlock;
