import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import TabsContainer from "common/containers/Tabs";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./HapaxCountsAnalysis.module.css";

export default function HapaxCountsAnalysisContainer() {
  // States
  const [hapaxCounts, setHapaxCounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);

  //   Contexts
  const { currentUser } = useAuth();
  const { projectId, versionId } = useParams();

  // Memo
  const filteredHapaxCounts = useMemo(() => {
    const keys = Object.keys(hapaxCounts);
    return hapaxCounts?.[keys[currentTab]];
  }, [currentTab, hapaxCounts]);

  useEffect(() => {
    fetchHapaxCounts();
  }, []);

  const fetchHapaxCounts = async () => {
    try {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getHapaxCounts
        .replace("{projectId}", projectId)
        .replace("{versionId}", versionId);
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setHapaxCounts(response.data || []);
      }
    } catch (error) {
      toast.error(ERRORS.error_183);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={styles["hapax-counts-container"]}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {hapaxCounts && hapaxCounts.length === 0 ? (
            <ItemNotFoundContainer message="Aucune analyse n’a été trouvée." />
          ) : (
            <>
              <TabsContainer
                tabsData={Object.keys(hapaxCounts)}
                handleTabChange={handleTabChange}
              />
              <div className={styles["hapax-counts-wrapper"]}>
                <div>
                  <span>Hapax count : </span>
                  <span style={{ fontWeight: "bold" }}>
                    {filteredHapaxCounts}
                  </span>
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
