import Button from "common/components/Button/Button";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./ConfigDataset.module.css";
import DatasetPreviewTable from "./containers/DatasetPreviewTable";
import OccuredErrorsPreviewTable from "./containers/OccuredErrorsPreviewTable";
import UploadedFileConfigTable from "./containers/UploadedFileConfigTable";

export default function ConfigDataset() {
  const { datasetId } = useParams();

  const navigate = useNavigate();

  const previousButtonHandler = async () => {
    if (datasetId) {
      navigate(`/datasets/${datasetId}/step/2`, { replace: true });
      return;
    }
    navigate("/");
    return;
  };

  const nextButtonHandler = async () => {
    if (datasetId) {
      navigate(`/datasets/${datasetId}/step/4`, { replace: true });
      return;
    }
    navigate("/");
    return;
  };

  return (
    <>
      <DatasetPreviewTable
        title="Aperçu du dataset"
        previousButtonHandler={previousButtonHandler}
      />
      <OccuredErrorsPreviewTable title="Erreurs survenues" />
      <UploadedFileConfigTable title="Paramètres des fichiers uploadés" />
      <div className={styles["stepper-action-buttons-wrapper"]}>
        <Button onClick={previousButtonHandler}>Précedent</Button>
        <Button onClick={nextButtonHandler}>Suivant</Button>
      </div>
    </>
  );
}
