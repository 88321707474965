import { Controller, useForm } from "react-hook-form";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Option, Select } from "@mui/joy";
import axios from "axios";
import ClearButton from "common/components/Button/ClearButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styles from "./ForkVersionForm.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

export default function ForkVersionForm({
  formId,
  setFormData,
  setIsSubmitting,
  allUsersInformation,
  sourceProjectId,
  sourceVersionId,
  allProjectsInformation,
}) {
  // States
  const [allVersionsInformation, setAllVersionsInformation] = useState([]);
  const [loading, setLoading] = useState(false);

  // Contexts
  const { currentUser } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      source_project_id: sourceProjectId ? sourceProjectId : [],
      source_version_id: sourceVersionId ? sourceVersionId : [],
      destination_project_id: sourceProjectId ? sourceProjectId : [],
      destination_version_name: "",
      owner_ids: [],
      authorized_user_ids: [],
    },
  });

  const selectedProjectId = watch("source_project_id");

  useEffect(() => {
    fetchVersionsByProjectId(selectedProjectId);
  }, [selectedProjectId]);

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  const onSubmit = (data) => {
    const submittedData = {
      source_project_id: data.source_project_id,
      source_version_id: data.source_version_id,
      destination_project_id: data.destination_project_id,
      destination_version_name: data.destination_version_name,
      owner_ids: data.owner_ids,
      authorized_user_ids: data.authorized_user_ids,
    };
    setFormData(submittedData);
  };

  const fetchVersionsByProjectId = async (projectId) => {
    const url = BACKEND_URL_CONFIG.getProjectVersions.replace(
      "{projectId}",
      projectId
    );
    try {
      setLoading(true);
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllVersionsInformation(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_160);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Projet source</label>
        <Controller
          name="source_project_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              required
              placeholder="Choisir le projet source..."
              style={customSelectStyle}
              disabled={isSubmitting}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              {...(field.value && {
                endDecorator: (
                  <ClearButton
                    onClick={() => {
                      field.onChange(null);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ClearButton>
                ),
                indicator: null,
              })}
            >
              {allProjectsInformation?.map((project) => (
                <Option key={project.id} value={project.id}>
                  {project.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label
          className={styles.label}
          style={{ display: "flex", gap: "10px" }}
        >
          <span>Version source</span>
          <span>{loading && <LoadingSpinner />}</span>
        </label>
        <Controller
          name="source_version_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              required
              placeholder="Choisir le projet source..."
              style={customSelectStyle}
              disabled={!selectedProjectId || isSubmitting}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              {...(field.value && {
                endDecorator: (
                  <ClearButton
                    onClick={() => {
                      field.onChange(null);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ClearButton>
                ),
                indicator: null,
              })}
            >
              {allVersionsInformation?.map((version) => (
                <Option key={version.id} value={version.id}>
                  {version.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Projet cible</label>
        <Controller
          name="destination_project_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              required
              placeholder="Choisir le projet cible..."
              style={customSelectStyle}
              disabled={isSubmitting}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              {...(field.value && {
                endDecorator: (
                  <ClearButton
                    onClick={() => {
                      field.onChange(null);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ClearButton>
                ),
                indicator: null,
              })}
            >
              {allProjectsInformation?.map((project) => (
                <Option key={project.id} value={project.id}>
                  {project.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom de la version à dupliquer</label>
        <input
          {...register("destination_version_name", {
            required: true,
            validate: (value) => {
              return !!value.trim(); // The input can not be empty or only whitespace
            },
          })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le nom de la version à dupliquer"
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Admin(s)</label>
        <Controller
          name="owner_ids"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              required
              placeholder="Choisir les admins..."
              style={customSelectStyle}
              disabled={isSubmitting}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {Object.entries(allUsersInformation).map(
                ([userId, userDisplayName]) => (
                  <Option key={userId} value={userId}>
                    {userDisplayName}
                  </Option>
                )
              )}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Personnes autorisées</label>
        <Controller
          name="authorized_user_ids"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              required
              placeholder="Choisir les personnes autorisées..."
              style={customSelectStyle}
              disabled={isSubmitting}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {Object.entries(allUsersInformation).map(
                ([userId, userDisplayName]) => (
                  <Option key={userId} value={userId}>
                    {userDisplayName}
                  </Option>
                )
              )}
            </Select>
          )}
        />
      </div>
    </form>
  );
}
