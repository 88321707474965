import styles from "./IconButton.module.css";

function IconButton(props) {
  // The className can be custom
  const iconButtonClassName = props.className
    ? props.className
    : styles["button-with-icon"];

  return (
    <>
      <button
        onClick={props.onClick}
        className={`${
          props.disabled ? styles["disabled-button"] : iconButtonClassName
        }`}
        style={props.style}
        disabled={props.disabled}
        title={props.title}
      >
        {props.children}
      </button>
    </>
  );
}

export default IconButton;
