import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faHome } from "@fortawesome/free-solid-svg-icons";

import { Breadcrumbs } from "@mui/material";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import MainLayout from "layouts/MainLayout";
import { Fragment } from "react";

import { useProject } from "common/hooks/useProject";
import { BLOCK_TYPE_DISPLAY_NAME } from "core/blocks-config";
import { useVersions } from "hooks/useVersions";
import { useParams } from "react-router-dom";
import styles from "./ShowBlockOutputPage.module.css";
import CompareDictionarySizeAnalysisContainer from "./container/CompareDictionarySizeAnalysis";
import GlobalStatsAnalysisContainer from "./container/GlobalStatsAnalysis";
import HapaxCountsAnalysisContainer from "./container/HapaxCountsAnalysis";
import NgramFrequenciesAnalysisContainer from "./container/NgramFrequenciesAnalysis";
import TopicDetectionAnalysisContainer from "./container/TopicDetectionAnalysis";
import WordcloudAnalysisContainer from "./container/WordcloudAnalysis";

const DYNAMIC_PAGE_COMPONENTS = {
  generate_wordclouds: <WordcloudAnalysisContainer />,
  generate_ngram_frequencies: <NgramFrequenciesAnalysisContainer />,
  generate_global_stats: <GlobalStatsAnalysisContainer />,
  compare_dictionary_sizes: <CompareDictionarySizeAnalysisContainer />,
  detect_topics_openai_batch: <TopicDetectionAnalysisContainer />,
  count_hapax: <HapaxCountsAnalysisContainer />,
};

export default function ShowBlockOutputPage() {
  // Contexts
  const { sectionType, blockType, projectId, versionId } = useParams();
  const { projectInfo } = useProject();
  const { versionsInfo } = useVersions();

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/projects"
                label="Projets"
              />
              <CustomBreadcrumb
                component="a"
                href={`/projects/${projectId}`}
                label={projectInfo?.name}
              />
              <CustomBreadcrumb
                component="a"
                href={`/projects/${projectId}/version/${versionId}`}
                label={versionsInfo?.name}
              />
              <CustomBreadcrumb
                component="a"
                href={`/projects/${projectId}/version/${versionId}/run/${sectionType}`}
                label={`Section ${sectionType}`}
              />
              <CustomBreadcrumb
                component="a"
                href="#"
                label={`${BLOCK_TYPE_DISPLAY_NAME[blockType]}`}
              />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Analyse: {BLOCK_TYPE_DISPLAY_NAME[blockType]}</span>
            </div>
          </div>
          <div className={styles["main-section"]}>
            {DYNAMIC_PAGE_COMPONENTS[blockType]}
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
