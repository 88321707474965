import { Option, Select } from "@mui/joy";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import {
  faExclamationCircle,
  faTriangleExclamation,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox } from "@mui/material";
import LoadingSpinner from "common/components/LoadingSpinner";
import { db } from "core/firebase";
import { ERRORS } from "errors";
import { doc, getDoc } from "firebase/firestore";
import { useCallback, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import styles from "./LoadDatasetWithDLPForm.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

export default function LoadDatasetWithDLPForm({
  block,
  formId,
  needAdvancedOptions,
  setFormData,
  defaultBlockDisplayName,
  setIsSubmitting,
  allDatasets,
}) {
  // States
  const [loading, setLoading] = useState(false);
  const [columnsObject, setColumnsObject] = useState({});
  const [hasAnySensitiveColumns, setHasAnySensitiveColumns] = useState(false);
  const [checkedColumnObj, setCheckedColumnObj] = useState({});

  // Constants
  const defaultColValue = "string";
  const defaultOptions = [
    { label: "string", value: "string" },
    { label: "categorical", value: "categorical" },
    { label: "int", value: "int" },
    { label: "float", value: "float" },
    { label: "date", value: "date" },
  ];

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting },
    control,
    setValue,
  } = useForm({
    defaultValues: {
      dataset_id: block ? block.parameters?.dataset_id : null,
      verbatim_column_id: block ? block.parameters?.verbatim_column_id : null,
      blockDisplayName: block ? block.name : defaultBlockDisplayName,
      anonymized_column_ids: block
        ? block.parameters?.anonymized_column_ids
        : [],
    },
  });

  // We need to fetch the dataset information when the dataset is chosen
  // That's why we need to watch `dataset_id`
  const datasetChosen = watch("dataset_id");

  const fetchDatasetInformation = useCallback(
    async (datasetName) => {
      if (!datasetName) {
        return;
      }
      const datasetRef = doc(db, "datasets", datasetName);
      const docRef = await getDoc(datasetRef);
      try {
        if (docRef.exists()) {
          const documentData = docRef.data();
          const columnsInformation = documentData.stats.columns;
          // Check if there is some sensitive column
          const anySensitive = Object.values(columnsInformation).some(
            (columnInfo) => columnInfo.sensitive === true
          );
          setHasAnySensitiveColumns(anySensitive);

          // Order column
          const orderedColumns = Object.fromEntries(
            Object.entries(columnsInformation).sort(
              ([, a], [, b]) => a.index - b.index
            )
          );
          setColumnsObject(orderedColumns);

          // init checked column default map
          // if there is a block, it's about updating a block
          // so we need to set the checked columns from the block parameters
          if (block !== undefined) {
            const blockKeptColumns = block.parameters?.kept_columns_dict;
            const initCheckedColumnObj = Object.fromEntries(
              Object.keys(columnsInformation).map((columnId) => [
                columnId,
                blockKeptColumns?.[columnId] ? true : false,
              ])
            );
            setCheckedColumnObj(initCheckedColumnObj);
          } else {
            // if there is no block, it's about creating a new block
            // so we need to set all columns as checked
            const initCheckedColumnObj = Object.fromEntries(
              Object.keys(columnsInformation).map((columnId) => [
                columnId,
                true,
              ])
            );
            setCheckedColumnObj(initCheckedColumnObj);
          }
        }
      } catch (error) {
        toast.error(ERRORS.error_122);
      }
    },
    [block]
  );

  useEffect(() => {
    const fetchDatasetInformationAsync = async () => {
      setLoading(true);
      await fetchDatasetInformation(datasetChosen);
      setLoading(false);
    };
    if (datasetChosen) {
      fetchDatasetInformationAsync();
    }
  }, [datasetChosen, fetchDatasetInformation]);

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);

  const onSubmit = (data) => {
    // If there is no keptColumnObj, we need to create it
    // with default values by iterating over checkedColumnObj
    const keptColumnsData = data?.keptColumnObj
      ? data.keptColumnObj
      : Object.fromEntries(
          Object.keys(checkedColumnObj).map((key) => [
            key,
            { type: defaultColValue },
          ])
        );

    // If there is no checkedColumnObj, we use the default one
    const checkedColumnsData = data?.checkedColumnObj
      ? data.checkedColumnObj
      : checkedColumnObj;

    // Data coming from the form submitting
    const verbatimColumn = data.verbatim_column_id;
    const datasetId = data.dataset_id;
    const blockDisplayName = data.blockDisplayName;
    const anonymizedColumnIds = data.anonymized_column_ids;

    // Check saved keptColumnObj if there are checked with checkbox
    const filteredKeptColumnObj = Object.keys(checkedColumnsData)
      .filter((key) => checkedColumnsData[key])
      .reduce((acc, key) => {
        acc[key] = keptColumnsData[key];
        return acc;
      }, {});

    const submittedData = {
      parameters: {
        dataset_id: datasetId,
        verbatim_column_id: verbatimColumn,
        verbatim_column_id_set_by_user: true,
        kept_columns_dict: filteredKeptColumnObj,
        anonymized_column_ids: anonymizedColumnIds,
      },
      blockDisplayName: blockDisplayName,
    };

    setFormData(submittedData);
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom du dataset</label>
        <Controller
          name="dataset_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisir votre dataset..."
              required={true}
              style={customSelectStyle}
              onChange={(_, newValue) => {
                field.onChange(newValue);
                setValue("dataset_id", newValue);
              }}
            >
              {allDatasets?.map((dataset) => (
                <Option key={dataset.dataset_id} value={dataset.dataset_id}>
                  {dataset.name}
                </Option>
              ))}
            </Select>
          )}
        />
        {hasAnySensitiveColumns && (
          <div className={styles["alert-message-wrapper"]}>
            <span className={styles["alert-icon-wrapper"]}>
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </span>
            <div>
              <span style={{ fontWeight: "bold" }}>Attention</span>, il y a des
              colonnes sensibles dans ce dataset. Pensez à les rensigner pour
              les anonymser.
            </div>
          </div>
        )}
      </div>
      <div className={styles["field-container"]}>
        <label
          className={styles.label}
          style={{ display: "flex", gap: "10px" }}
        >
          <span>Colonne des verbatims</span>
          <span>{loading && <LoadingSpinner />}</span>
        </label>
        <Controller
          name="verbatim_column_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisir votre colonne des verbatims..."
              required={true}
              style={customSelectStyle}
              disabled={!datasetChosen || loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {Object.entries(columnsObject).map(([columnId, columnInfo]) => (
                <Option key={columnId} value={columnId}>
                  {columnInfo.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label
          className={styles.label}
          style={{ display: "flex", gap: "10px" }}
        >
          <span>Colonnes à anonymiser</span>
          <span>{loading && <LoadingSpinner />}</span>
        </label>
        <Controller
          name="anonymized_column_ids"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              multiple={true}
              placeholder="Choisir votre colonne à anonymiser..."
              required={true}
              style={customSelectStyle}
              disabled={!datasetChosen || loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {Object.entries(columnsObject).map(([columnId, columnInfo]) => (
                <Option key={columnId} value={columnId}>
                  {columnInfo.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom du bloc</label>
        <input
          {...register("blockDisplayName", { required: true })}
          type="text"
          required={true}
          className={styles["text-input"]}
          placeholder="Saisir le nom de votre bloc"
        />
      </div>

      {needAdvancedOptions &&
        (datasetChosen ? (
          <div className={styles["advanced-options-container"]}>
            <div className={styles["advanced-options-header"]}>
              <label className={styles.label}>Options avancées</label>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <input
                  type="checkbox"
                  style={{ cursor: "pointer" }}
                  defaultChecked={Object.values(checkedColumnObj).every(
                    (value) => value === true
                  )}
                  onChange={(e) => {
                    const updatedCheckedColumns = Object.fromEntries(
                      Object.keys(checkedColumnObj).map((columnId) => [
                        columnId,
                        e.currentTarget.checked,
                      ])
                    );
                    setValue("checkedColumnObj", updatedCheckedColumns);
                  }}
                ></input>
                <span>Tout</span>
              </div>
            </div>
            <div className={styles["checkbox-inputs-container"]}>
              {Object.entries(columnsObject).map(([columnId, columnInfo]) => (
                <div
                  key={`kept-column-${columnId}`}
                  className={styles["checkbox-input"]}
                >
                  <Controller
                    name={`checkedColumnObj.${columnId}`}
                    control={control}
                    defaultValue={checkedColumnObj[columnId]}
                    render={({ field }) => (
                      <Checkbox
                        {...field}
                        id={columnId}
                        checked={field.value}
                        onChange={(e) => field.onChange(e.target.checked)}
                      />
                    )}
                  />
                  <div className={styles["column-name-wrapper"]}>
                    {columnInfo.name}
                  </div>
                  <div
                    className={styles["column-cast-type-dropdown-wrapper"]}
                    style={{ flexGrow: 1 }}
                  >
                    <Controller
                      name={`keptColumnObj.${columnId}.type`}
                      control={control}
                      defaultValue={
                        block?.parameters?.kept_columns_dict?.[columnId]
                          ? block?.parameters?.kept_columns_dict?.[columnId]
                              .type
                          : defaultColValue
                      }
                      render={({ field }) => (
                        <Select
                          {...field}
                          placeholder="Choisir..."
                          style={customSelectStyle}
                          onChange={(event, newValue) => {
                            field.onChange(newValue);
                          }}
                        >
                          {defaultOptions.map((eachOption) => (
                            <Option
                              key={eachOption.value}
                              value={eachOption.value}
                              id={columnId}
                            >
                              {eachOption.label}
                            </Option>
                          ))}
                        </Select>
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div
            className={styles["alert-message-wrapper"]}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <span
              className={styles["alert-icon-wrapper"]}
              style={{ color: "#009dff" }}
            >
              <FontAwesomeIcon icon={faExclamationCircle} />
            </span>
            <div>
              <span style={{ fontWeight: "bold" }}>Attention</span>, veuillez
              d'abord choisir un dataset pour voir les options avancées.
            </div>
          </div>
        ))}
    </form>
  );
}
