import { MenuButton } from "@mui/base";
import styles from "./CustomMenuButton.module.css";

export default function CustomMenuButton({
  title,
  className,
  style,
  disabled,
  children,
}) {
  // Check if the user want to set a custom class name. Otherwise, set it by default
  const buttonClassName = className ? className : styles["custom-menu-button"];
  return (
    <MenuButton
      title={title}
      style={style}
      className={buttonClassName}
      disabled={disabled}
    >
      {children}
    </MenuButton>
  );
}
