import { useAuth } from "common/hooks/useAuth";

import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Breadcrumbs } from "@mui/material";
import axios from "axios";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import StopwordsFileForm from "common/containers/Forms/UploadFile/StopwordsFileForm";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment } from "react";
import { toast } from "react-toastify";
import styles from "./EditStopwordsFilePage.module.css";

export default function EditStopwordsFilePage() {
  // Contexts
  const { currentUser } = useAuth();
  const { stopwordsFileId } = useParams();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [stopwordsFileInformation, setStopwordsFileInformation] =
    useState(null);

  // Refs
  const formIdRef = useId();

  useEffect(() => {
    const fetchStopwordsFileInformation = async (controller) => {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getStopwordsFileInformation.replace(
        "{stopwordsFileId}",
        stopwordsFileId
      );
      try {
        const response = await axios.get(url, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: controller.signal,
        });
        if (response.status === 200) {
          setStopwordsFileInformation(response.data);
        }
      } catch (error) {
        if (error.response.status === 404) {
          navigate("/stopwords");
          toast.error(ERRORS.error_182);
          return;
        }
        toast.error(ERRORS.error_166);
      } finally {
        setLoading(false);
      }
    };

    const controller = new AbortController();
    fetchStopwordsFileInformation(controller);
    return () => controller.abort();
  }, [currentUser.accessToken, stopwordsFileId]);

  useEffect(() => {
    const saveStopwordsFile = async (controller) => {
      const payload = {
        name: formData.file_label,
        is_certified: formData.is_certified,
      };
      updateStopwordsFile(payload, controller);
    };

    const updateStopwordsFile = async (payload, controller) => {
      setLoading(true);
      try {
        const url = BACKEND_URL_CONFIG.updateStopwordsFile.replace(
          "{stopwordsFileId}",
          stopwordsFileId
        );
        const response = await axios.put(url, payload, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: controller.signal,
        });
        if (response.status === 200) {
          toast.success("Votre fichier de stopwords a été modifié");
          navigate("/stopwords");
        }
      } catch (error) {
        if (error.response.status === 409) {
          toast.error(ERRORS.error_171);
        } else {
          toast.error(ERRORS.error_165);
        }
      } finally {
        setLoading(false);
      }
    };

    // We need to check if formData is not null to avoid calling the function
    const controller = new AbortController();
    if (formData) {
      saveStopwordsFile(controller);
    }
    return () => controller.abort();
  }, [formData, currentUser.accessToken, stopwordsFileId, navigate]);

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/stopwords"
                label="Stopwords"
              />
              <CustomBreadcrumb component="a" href="#" label="Modifier" />
            </Breadcrumbs>
          </div>
          <div className={styles["main-section"]}>
            <BlockFormContainer>
              <BlockFormTitleContainer
                title="Modifier un fichier de stopwords"
                formId={formIdRef}
                loading={loading}
                isSubmitting={isSubmitting}
              />
              <BlockFormFieldsContainer>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <StopwordsFileForm
                    stopwordsFileInformation={stopwordsFileInformation}
                    setFormData={setFormData}
                    formId={formIdRef}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </BlockFormFieldsContainer>
            </BlockFormContainer>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
