import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import React from "react";

export default function SelectDropdown({
  defaultValue,
  optionsArray,
  onChange,
}) {
  return (
    <Select defaultValue={defaultValue} onChange={onChange}>
      {optionsArray.map((option, index) => (
        <Option key={index} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
}
