import { faCirclePlus, faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@mui/joy";
import { Pagination, Stack } from "@mui/material";

import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "axios";
import TransparentButton from "common/components/Button/TransparentButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./DatasetsPage.module.css";

export default function DatasetsPage() {
  // States
  const [allDatasets, setAllDatasets] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);

  // Constants
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(allDatasets?.length / itemsPerPage);
  const filteredDatasets = useMemo(
    () => allDatasets.slice(indexOfFirstItem, indexOfLastItem),
    [allDatasets, indexOfFirstItem, indexOfLastItem]
  );

  // Hooks
  const navigate = useNavigate();
  const { currentUser, isUserManager, isUserAdmin } = useAuth();

  useEffect(() => {
    fetchDatasets();
  }, []);

  const fetchDatasets = async () => {
    setLoading(true);
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getAllDatasets, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllDatasets(response.data.datasets);
      }
    } catch (error) {
      toast.error(ERRORS.error_118);
    } finally {
      setLoading(false);
    }
  };

  const deleteDataset = async (datasetId) => {
    setLoading(true);
    const deleteDatasetEndpoint = BACKEND_URL_CONFIG.deleteDataset.replace(
      "{datasetId}",
      datasetId
    );
    try {
      const response = await axios.delete(deleteDatasetEndpoint, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        fetchDatasets();
        toast.success("Le dataset a été supprimé");
        return;
      }
    } catch (error) {
      if (error.response.status === 409) {
        toast.info(ERRORS.error_177);
        return;
      }

      toast.error(ERRORS.error_119);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb component="a" href="#" label="Datasets" />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Datasets</span>
              {loading && <LoadingSpinner />}
            </div>
            <div className={styles["actions-buttons-wrapper"]}>
              <TransparentButton
                onClick={() => navigate("/datasets/new")}
                disabled={!isUserManager && !isUserAdmin}
              >
                <FontAwesomeIcon icon={faCirclePlus} />
                Nouveau dataset
              </TransparentButton>
            </div>
          </div>
          <div className={styles["main-section"]}>
            <div className={styles["table-container"]}>
              <table id={styles["project-table"]}>
                <thead>
                  <tr>
                    <th>Nom du dataset</th>
                    <th>Dernière modification</th>
                    <th>Owners</th>
                    <th>Utilisateurs autorisés</th>
                    <th>Nb de lignes</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredDatasets?.map((item, index) => (
                    <tr className={styles.row} key={item.dataset_id}>
                      <td
                        style={{
                          fontWeight: "bold",
                          textDecoration: "underline",
                        }}
                      >
                        {item.name}
                      </td>
                      <td>
                        {new Date(item.last_update * 1000).toLocaleString(
                          "fr-FR",
                          {
                            timeZone: "Europe/Paris",
                            day: "2-digit",
                            month: "2-digit",
                            year: "numeric",
                          }
                        )}
                      </td>
                      <td>
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {item.owners_details.length > 0 ? (
                            item.owners_details.map((user, index) =>
                              user.photo_url ? (
                                <Avatar
                                  key={`owners-${index}`}
                                  alt={user.initiales}
                                  title={user.display_name}
                                  src={user.photo_url}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: 13,
                                    color: "#000",
                                    backgroundColor: "#cecece",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  key={`owners-${index}`}
                                  title={user.display_name}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: 13,
                                    color: "#000",
                                    backgroundColor: "#cecece",
                                  }}
                                >
                                  {user.initiales}
                                </Avatar>
                              )
                            )
                          ) : (
                            <div>--</div>
                          )}
                        </div>
                      </td>
                      <td>
                        <div
                          key={`owners-${index}`}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                          }}
                        >
                          {item.authorized_users_details.length > 0 ? (
                            item.authorized_users_details.map((user, index) =>
                              user.photo_url ? (
                                <Avatar
                                  key={`users-${index}`}
                                  alt={user.initiales}
                                  title={user.display_name}
                                  src={user.photo_url}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: 13,
                                    color: "#000",
                                    backgroundColor: "#cecece",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  key={`users-${index}`}
                                  title={user.display_name}
                                  sx={{
                                    width: 30,
                                    height: 30,
                                    fontSize: 13,
                                    color: "#000",
                                    backgroundColor: "#cecece",
                                  }}
                                >
                                  {user.initiales}
                                </Avatar>
                              )
                            )
                          ) : (
                            <div>--</div>
                          )}
                        </div>
                      </td>

                      <td>{item.row_number || "-"}</td>
                      <td>
                        {item.is_draft ? (
                          <span style={{ fontWeight: "bold" }}>En cours</span>
                        ) : (
                          <FontAwesomeIcon
                            title="Enregistré"
                            icon={faCircleCheck}
                            style={{ color: "#2b652b", fontSize: "20px" }}
                          />
                        )}
                      </td>
                      <td>
                        <Dropdown>
                          <MenuButton
                            style={{ padding: "11px 16px", border: "unset" }}
                            disabled={loading}
                          >
                            <FontAwesomeIcon icon={faEllipsisVertical} />
                          </MenuButton>
                          <Menu>
                            <MenuItem
                              disabled={
                                !item?.owner_ids?.includes(currentUser?.uid) &&
                                !isUserAdmin
                              }
                              onClick={() =>
                                navigate(`/datasets/${item.dataset_id}/step/1`)
                              }
                            >
                              Modifier
                            </MenuItem>
                            <MenuItem
                              disabled={
                                !item?.owner_ids?.includes(currentUser?.uid) &&
                                !isUserAdmin
                              }
                              onClick={() => deleteDataset(item.dataset_id)}
                            >
                              Supprimer
                            </MenuItem>
                          </Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className={styles["pagination-container"]}>
              <Stack spacing={2}>
                <Pagination
                  page={currentPage}
                  onChange={(e, value) => setCurrentPage(value)}
                  count={totalPages}
                />
              </Stack>
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
