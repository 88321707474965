const BACKEND_MAIN_URL = process.env.REACT_APP_BACKEND_MAIN_URL;
const UPLOAD_BUCKET_NAME = process.env.REACT_APP_UPLOAD_BUCKET_NAME;
const LEMMAS_BUCKET_NAME = "c-nlp-lemmas";
const MASKS_BUCKET_NAME = "c-nlp-masks";
const STOPWORDS_BUCKET_NAME = "c-nlp-stopwords";
const TOPICS_BUCKET_NAME = "c-nlp-topics";

const BACKEND_URL_CONFIG = {
  getAllUserNames: `${BACKEND_MAIN_URL}/users/names`,
  generateToken: `${BACKEND_MAIN_URL}/users/generate_token`,
  createDataset: `${BACKEND_MAIN_URL}/datasets`,
  getDatasetInfo: `${BACKEND_MAIN_URL}/datasets/{datasetId}`,
  deleteDataset: `${BACKEND_MAIN_URL}/datasets/{datasetId}`,
  updateDataset: `${BACKEND_MAIN_URL}/datasets/{datasetId}`,
  getAllDatasets: `${BACKEND_MAIN_URL}/datasets`,
  getDatasetNames: `${BACKEND_MAIN_URL}/datasets/names`,
  getOccuredErrors: `${BACKEND_MAIN_URL}/datasets/{datasetId}/errors`,
  extractUploadedFiles: `${BACKEND_MAIN_URL}/datasets/{datasetId}/files`,
  deleteUploadedFile: `${BACKEND_MAIN_URL}/datasets/{datasetId}/files/{fileId}`,
  initFileSettings: `${BACKEND_MAIN_URL}/datasets/{datasetId}/settings/init`,
  ingestDataset: `${BACKEND_MAIN_URL}/datasets/ingest_dataset`,
  saveDataset: `${BACKEND_MAIN_URL}/datasets/{datasetId}/save`,
  createVersion: `${BACKEND_MAIN_URL}/versions`,
  duplicateVersion: `${BACKEND_MAIN_URL}/versions/duplicate`,
  updateVersion: `${BACKEND_MAIN_URL}/versions/{versionId}`,
  getVersionInformation: `${BACKEND_MAIN_URL}/versions/{versionId}`,
  deleteVersion: `${BACKEND_MAIN_URL}/versions/{versionId}`,
  triggerDatasetPreview: `${BACKEND_MAIN_URL}/versions/preview_version_dataset`,
  addBlock: `${BACKEND_MAIN_URL}/versions/block`,
  addBlockFromSavedTopics: `${BACKEND_MAIN_URL}/versions/block?source=saved-topics`,
  addBlockFromSavedParameters: `${BACKEND_MAIN_URL}/versions/block?source=saved-parameters`,
  updateBlockOrder: `${BACKEND_MAIN_URL}/versions/workflow/order`,
  getSectionBlocks: `${BACKEND_MAIN_URL}/versions/{versionId}/{sectionId}/blocks`,
  deleteBlock: `${BACKEND_MAIN_URL}/versions/block`,
  deleteAllBlock: `${BACKEND_MAIN_URL}/versions/section/{sectionType}/all`,
  activateBlock: `${BACKEND_MAIN_URL}/versions/block/activate`,
  deactivateBlock: `${BACKEND_MAIN_URL}/versions/block/deactivate`,
  getBlockInformation: `${BACKEND_MAIN_URL}/versions/{versionId}/{sectionId}/block/{blockId}`,
  updateBlock: `${BACKEND_MAIN_URL}/versions/block`,
  createJob: `${BACKEND_MAIN_URL}/jobs`,
  costEstimationByBlockType: `${BACKEND_MAIN_URL}/process/{blockType}/cost_estimation`,
  validateRights: `${BACKEND_MAIN_URL}/process/validate_rights`,
  triggerProcessById: `${BACKEND_MAIN_URL}/process/{processId}`,
  getAvailableColumnsInfo: `${BACKEND_MAIN_URL}/versions/{versionId}/parameters/available_columns`,
  downloadVersionDataset: `${BACKEND_MAIN_URL}/versions/download_version_dataset`,
  createProject: `${BACKEND_MAIN_URL}/projects`,
  getAllProjects: `${BACKEND_MAIN_URL}/projects`,
  getAllProjectsNames: `${BACKEND_MAIN_URL}/projects/names`,
  getProjectInformation: `${BACKEND_MAIN_URL}/projects/{projectId}`,
  updateProject: `${BACKEND_MAIN_URL}/projects/{projectId}`,
  deleteProject: `${BACKEND_MAIN_URL}/projects/{projectId}`,
  getProjectVersions: `${BACKEND_MAIN_URL}/projects/{projectId}/versions`,
  // Stopwords
  getStopwordsFilesList: `${BACKEND_MAIN_URL}/stopwords`,
  getStopwordsFileInformation: `${BACKEND_MAIN_URL}/stopwords/{stopwordsFileId}`,
  createStopwordsFile: `${BACKEND_MAIN_URL}/stopwords`,
  updateStopwordsFile: `${BACKEND_MAIN_URL}/stopwords/{stopwordsFileId}`,
  deleteStopwordsFile: `${BACKEND_MAIN_URL}/stopwords/{stopwordsFileId}`,
  // Lemmas
  getLemmasFilesList: `${BACKEND_MAIN_URL}/lemmas`,
  getLemmaFileInformation: `${BACKEND_MAIN_URL}/lemmas/{lemmaFileId}`,
  createLemmasFile: `${BACKEND_MAIN_URL}/lemmas`,
  updateLemmasFile: `${BACKEND_MAIN_URL}/lemmas/{lemmaFileId}`,
  deleteLemmasFile: `${BACKEND_MAIN_URL}/lemmas/{lemmasFileId}`,
  // Masks
  getMasksFilesList: `${BACKEND_MAIN_URL}/masks`,
  getMaskFileInformation: `${BACKEND_MAIN_URL}/masks/{maskFileId}`,
  createMasksFile: `${BACKEND_MAIN_URL}/masks`,
  updateMasksFile: `${BACKEND_MAIN_URL}/masks/{maskFileId}`,
  deleteMasksFile: `${BACKEND_MAIN_URL}/masks/{maskFileId}`,
  // Parameters
  createParametersFile: `${BACKEND_MAIN_URL}/parameters`,
  getParametersFilesList: `${BACKEND_MAIN_URL}/parameters`,
  getParametersFileInformation: `${BACKEND_MAIN_URL}/parameters/{parametersFileId}`,
  updateParametersFile: `${BACKEND_MAIN_URL}/parameters/{parametersFileId}`,
  deleteParametersFile: `${BACKEND_MAIN_URL}/parameters/{parametersFileId}`,
  getParametersFilesListBySubtype: `${BACKEND_MAIN_URL}/parameters/block-subtypes/{subtype}`,
  // Topics
  getTopicsFilesList: `${BACKEND_MAIN_URL}/topics`,
  getTopicsFileInformation: `${BACKEND_MAIN_URL}/topics/{topicFileId}`,
  createTopicsFile: `${BACKEND_MAIN_URL}/topics`,
  updateTopicsFile: `${BACKEND_MAIN_URL}/topics/{topicFileId}`,
  deleteTopicsFile: `${BACKEND_MAIN_URL}/topics/{topicFileId}`,
  // Users
  getUsersList: `${BACKEND_MAIN_URL}/users`,
  getUserInformation: `${BACKEND_MAIN_URL}/users/{userId}`,
  createUser: `${BACKEND_MAIN_URL}/users`,
  updateUser: `${BACKEND_MAIN_URL}/users/{userId}`,
  deleteUser: `${BACKEND_MAIN_URL}/users/{userId}`,
  // Get ngram frequencies
  getNgramFrequencies: `${BACKEND_MAIN_URL}/process/generate_ngram_frequencies/{projectId}/{versionId}`,
  getGlobalStats: `${BACKEND_MAIN_URL}/process/generate_global_stats/{projectId}/{versionId}`,
  getDictionarySize: `${BACKEND_MAIN_URL}/process/compare_dictionary_sizes/{projectId}/{versionId}`,
  getWordCloudImages: `${BACKEND_MAIN_URL}/process/generate_wordclouds/{projectId}/{versionId}`,
  getDetectedTopics: `${BACKEND_MAIN_URL}/process/detect_topics_openai_batch/{projectId}/{versionId}`,
  getHapaxCounts: `${BACKEND_MAIN_URL}/process/count_hapax/{projectId}/{versionId}`,
  // Jobs
  getJobsList: `${BACKEND_MAIN_URL}/jobs`,
};

export {
  BACKEND_URL_CONFIG,
  LEMMAS_BUCKET_NAME,
  MASKS_BUCKET_NAME,
  STOPWORDS_BUCKET_NAME,
  TOPICS_BUCKET_NAME,
  UPLOAD_BUCKET_NAME,
};
