import { Option, Select } from "@mui/joy";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import styles from "./WordcloudForm.module.css";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearButton from "common/components/Button/ClearButton";
import CreatableSelect from "react-select/creatable";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

const availableNgramTypes = [
  [1, "Unigram"],
  [2, "Bigram"],
  [3, "Trigram"],
];

const availableColormaps = [["viridis", "Viridis"]];
const defaultValues = {
  ngram_type: 1,
  segmenting_column_ids: [],
  blacklist: [],
  max_words: 30,
  min_font_size: 6,
  background_color: "#ffffff",
  contour_color: "#000000",
  output_width: 11,
  output_height: 13,
  contour_width: 0,
  horizontal_word_ratio: 1,
  colormap: "viridis",
  mask_id: null,
};

export default function WordcloudForm({
  block,
  formId,
  needAdvancedOptions,
  setFormData,
  setIsSubmitting,
  defaultBlockDisplayName,
}) {
  // States
  const [loading, setLoading] = useState(false);
  const [availableColumnsInfo, setAvailableColumnsInfo] = useState(null);
  const [allMasks, setAllMasks] = useState([]);

  // Contexts
  const { currentUser } = useAuth();
  const { versionId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      blockDisplayName: block ? block.name : defaultBlockDisplayName,
      ngram_type: block
        ? block.parameters.ngram_type
        : defaultValues.ngram_type,
      segmenting_column_ids: block
        ? block.parameters.segmenting_column_ids
        : defaultValues.segmenting_column_ids,
      max_words: block ? block.parameters.max_words : defaultValues.max_words,
      min_font_size: block
        ? block.parameters.min_font_size
        : defaultValues.min_font_size,
      background_color: block
        ? block.parameters.background_color
        : defaultValues.background_color,
      contour_color: block
        ? block.parameters.contour_color
        : defaultValues.contour_color,
      blacklist: block
        ? block.parameters.blacklist.map((item) => ({
            value: item,
            label: item,
          }))
        : defaultValues.blacklist,
      output_width: block
        ? block.parameters.output_width
        : defaultValues.output_width,
      output_height: block
        ? block.parameters.output_height
        : defaultValues.output_height,
      contour_width: block
        ? block.parameters.contour_width
        : defaultValues.contour_width,
      horizontal_word_ratio: block
        ? block.parameters.horizontal_word_ratio
        : defaultValues.horizontal_word_ratio,
      colormap: block ? block.parameters.colormap : defaultValues.colormap,
      mask_id: block ? block.parameters.mask_id : defaultValues.mask_id,
      language_column_id: block ? block.parameters.language_column_id : null,
      verbatim_column_id: block ? block.parameters.verbatim_column_id : null,
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchAvailableColumns(), fetchMasks()]);
    };
    fetchData();
  }, []);

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  const onSubmit = (data) => {
    const submittedData = {
      parameters: {
        blacklist: data.blacklist.map((item) => item.value),
        ngram_type: data.ngram_type,
        segmenting_column_ids: data.segmenting_column_ids,
        max_words: parseInt(data.max_words),
        min_font_size: parseInt(data.min_font_size),
        background_color: data.background_color,
        contour_color: data.contour_color,
        output_width: parseInt(data.output_width),
        output_height: parseInt(data.output_height),
        contour_width: parseInt(data.contour_width),
        horizontal_word_ratio: parseFloat(data.horizontal_word_ratio),
        colormap: data.colormap,
        mask_id: data.mask_id,
        language_column_id: data.language_column_id,
        language_column_id_set_by_user: !!data.language_column_id,
        verbatim_column_id: data.verbatim_column_id,
        verbatim_column_id_set_by_user: !!data.verbatim_column_id,
      },
      blockDisplayName: data.blockDisplayName,
    };
    setFormData(submittedData);
  };

  const fetchAvailableColumns = async () => {
    setLoading(true);
    const url = BACKEND_URL_CONFIG.getAvailableColumnsInfo.replace(
      "{versionId}",
      versionId
    );
    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAvailableColumnsInfo(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_130);
    } finally {
      setLoading(false);
    }
  };

  const fetchMasks = async () => {
    setLoading(true);
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getMasksFilesList, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllMasks(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_152);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom du bloc</label>
        <input
          {...register("blockDisplayName", { required: true })}
          type="text"
          required={true}
          className={styles["text-input"]}
          placeholder="Saisir le nom de votre bloc"
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Type de ngram</label>
        <Controller
          name="ngram_type"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              required={true}
              placeholder="Choisir votre type de ngram..."
              style={customSelectStyle}
              disabled={loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {availableNgramTypes?.map(([ngramValue, ngramName]) => (
                <Option key={ngramValue} value={ngramValue}>
                  {ngramName}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Colonnes segmentantes</label>
        <Controller
          name="segmenting_column_ids"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              multiple={true}
              placeholder="Choisir les colonnes segmentantes..."
              style={customSelectStyle}
              disabled={loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {Object.entries(availableColumnsInfo || {}).map(
                ([columnId, columnName]) => (
                  <Option key={columnId} value={columnId}>
                    {columnName}
                  </Option>
                )
              )}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Maximum mots</label>
        <input
          {...register("max_words", { required: true })}
          className={styles["text-input"]}
          type="number"
          placeholder="Saisir le nombre maximum de mots..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Taille de la police</label>
        <input
          {...register("min_font_size", { required: true })}
          className={styles["text-input"]}
          type="text"
          placeholder="Saisir la taille de la police..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Couleur de fond</label>
        <input
          {...register("background_color", { required: true })}
          className={styles["text-input"]}
          type="text"
          placeholder="Saisir le code couleur de fond..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Couleur du contour</label>
        <input
          {...register("contour_color", { required: true })}
          className={styles["text-input"]}
          type="text"
          placeholder="Saisir le code couleur du contour..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Blacklist</label>
        <Controller
          name="blacklist"
          control={control}
          render={({ field }) => (
            <CreatableSelect
              {...field}
              isMulti
              onChange={(value) => {
                field.onChange(value);
              }}
            />
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Liste de masques enregistrées</label>
        <Controller
          name="mask_id"
          control={control}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisir votre fichier de masques..."
              style={customSelectStyle}
              options={allMasks}
              disabled={loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              {...(field.value && {
                endDecorator: (
                  <ClearButton
                    onClick={() => {
                      field.onChange(null);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ClearButton>
                ),
                indicator: null,
              })}
            >
              {allMasks?.map((maskFile) => (
                <Option key={maskFile.id} value={maskFile.id}>
                  {maskFile.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      {needAdvancedOptions && (
        <>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Largeur de l'output</label>
            <input
              {...register("output_width", { required: true })}
              className={styles["text-input"]}
              type="text"
              placeholder="Saisir le largeur de l'output..."
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Longueur de l'output</label>
            <input
              {...register("output_height", { required: true })}
              className={styles["text-input"]}
              type="text"
              placeholder="Saisir la longueur de l'output..."
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Largeur du contour</label>
            <input
              {...register("contour_width", { required: true })}
              className={styles["text-input"]}
              type="text"
              placeholder="Saisir le largeur du contour..."
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Ratio horizontal du mot</label>
            <input
              {...register("horizontal_word_ratio", { required: true })}
              className={styles["text-input"]}
              type="text"
              placeholder="Saisir le ratio horizontal du mot..."
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Nom de la couleur map</label>
            <Controller
              name="colormap"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...field}
                  required={true}
                  placeholder="Choisir votre couleur map..."
                  style={customSelectStyle}
                  disabled={loading}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                >
                  {availableColormaps?.map(([colormapCode, colormapName]) => (
                    <Option key={colormapCode} value={colormapCode}>
                      {colormapName}
                    </Option>
                  ))}
                </Select>
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Colonne des verbatims</label>
            <Controller
              name="verbatim_column_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Choisir la colonne des verbatims..."
                  style={customSelectStyle}
                  disabled={loading}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  {...(field.value && {
                    endDecorator: (
                      <ClearButton
                        onClick={() => {
                          field.onChange(null);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </ClearButton>
                    ),
                    indicator: null,
                  })}
                >
                  {Object.entries(availableColumnsInfo || {}).map(
                    ([columnId, columnName]) => (
                      <Option key={columnId} value={columnId}>
                        {columnName}
                      </Option>
                    )
                  )}
                </Select>
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Colonne de langue à choisir</label>
            <Controller
              name="language_column_id"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  placeholder="Choisir la colonne de langue..."
                  style={customSelectStyle}
                  disabled={loading}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                  }}
                  {...(field.value && {
                    endDecorator: (
                      <ClearButton
                        onClick={() => {
                          field.onChange(null);
                        }}
                      >
                        <FontAwesomeIcon icon={faXmark} />
                      </ClearButton>
                    ),
                    indicator: null,
                  })}
                >
                  {Object.entries(availableColumnsInfo || {}).map(
                    ([columnId, columnName]) => (
                      <Option key={columnId} value={columnId}>
                        {columnName}
                      </Option>
                    )
                  )}
                </Select>
              )}
            />
          </div>
        </>
      )}
    </form>
  );
}
