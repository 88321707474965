import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import NgramFrequenciesForm from "common/containers/Forms/Blocks/NgramFrequenciesForm";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";
import { useCallback, useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function EditNgramFrequencies() {
  // Contexts
  const { sectionType, projectId, versionId, blockId, subBlockType } =
    useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // States
  const [blockInformation, setBlockInformation] = useState(null);
  const [loading, setLoading] = useState(false);
  const [needAdvancedOptions, setNeedAdvancedOptions] = useState(false);
  const [formData, setFormData] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Ref form
  // We need to use a ref to access the form values and submit them
  const formIdRef = useId();

  const fetchBlockInformation = useCallback(
    async (controller) => {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getBlockInformation
        .replace("{versionId}", versionId)
        .replace("{sectionId}", sectionType)
        .replace("{blockId}", blockId);
      try {
        const response = await axios.get(url, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: controller.signal,
        });
        if (response.status === 200) {
          setBlockInformation(response.data);
        }
      } catch (error) {
        if (error.response.status === 404) {
          toast.error("Le bloc n'existe pas.");
          navigate(
            `/projects/${projectId}/version/${versionId}/run/${sectionType}`
          );
          return;
        }
        toast.error(ERRORS.error_139);
      } finally {
        setLoading(false);
      }
    },
    [currentUser, versionId, sectionType, blockId, projectId, navigate]
  );

  useEffect(() => {
    const controller = new AbortController();
    fetchBlockInformation(controller);
    return () => controller.abort();
  }, [fetchBlockInformation]);

  const saveDatasetChoiceBlock = async () => {
    const payload = {
      section_type: sectionType,
      version_id: versionId,
      block_id: blockId,
      project_id: projectId,
      user_id: currentUser.uid,
      new_values: {
        name: formData.blockDisplayName,
        parameters: formData.parameters,
      },
    };
    updateBlock(payload);
  };

  const updateBlock = async (payload) => {
    setLoading(true);
    try {
      const response = await axios.put(
        BACKEND_URL_CONFIG.updateBlock,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Votre block a été modifié");
        navigate(
          `/projects/${projectId}/version/${versionId}/show/${sectionType}/${blockInformation?.type}`
        );
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
      } else {
        toast.error(ERRORS.error_138);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (formData) {
      saveDatasetChoiceBlock();
    }
  }, [formData]);

  return (
    <BlockFormContainer>
      <BlockFormTitleContainer
        title={`Modifier le bloc : ${blockInformation?.name ?? ""}`}
        formId={formIdRef}
        loading={loading}
        needAdvancedOptions={needAdvancedOptions}
        setNeedAdvancedOptions={setNeedAdvancedOptions}
        isSubmitting={isSubmitting}
      />
      <BlockFormFieldsContainer>
        {loading ? (
          <LoadingSpinner iconCustomStyle={{ fontSize: "25px" }} />
        ) : (
          <NgramFrequenciesForm
            block={blockInformation}
            formId={formIdRef}
            setFormData={setFormData}
            needAdvancedOptions={needAdvancedOptions}
            setIsSubmitting={setIsSubmitting}
            defaultBlockDisplayName={subBlockType}
          />
        )}
      </BlockFormFieldsContainer>
    </BlockFormContainer>
  );
}
