import { useAuth } from "common/hooks/useAuth";

import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import Switch from "common/components/Switch";
import CleanVerbatimsForm from "common/containers/Forms/Blocks/CleanVerbatimsForm";
import NewBlockFromSavedParamsForm from "common/containers/Forms/Blocks/NewBlockFromSavedParamsForm";
import { BACKEND_URL_CONFIG } from "config";
import { BLOCK_TYPE_DISPLAY_NAME } from "core/blocks-config";
import { ERRORS } from "errors";
import { toast } from "react-toastify";

export default function AddCleanVerbatims() {
  // Contexts
  const { sectionType, projectId, versionId, blockType, subBlockType } =
    useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // States
  const [needAdvancedOptions, setNeedAdvancedOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [createNewBlockFromSavedParams, setCreateNewBlockFromSavedParams] =
    useState(false);

  // Refs
  const formIdRef = useId();

  useEffect(() => {
    // If it's about a new virgin block or creation from saved parameters
    // The end of the payload will be different
    if (formData) {
      const payload = {
        type: blockType,
        subtype: subBlockType,
        section_type: sectionType,
        name: formData.blockDisplayName,
        version_id: versionId,
        project_id: projectId,
        user_id: currentUser.uid,
        ...(createNewBlockFromSavedParams
          ? { saved_parameters_id: formData.saved_parameters_id }
          : { parameters: formData.parameters }),
      };
      createNewBlockFromSavedParams
        ? saveBlockFromSavedParamsIntoDB(payload)
        : saveBlockIntoDB(payload);
    }
  }, [formData]);

  const saveBlockIntoDB = async (payload) => {
    setLoading(true);
    try {
      const response = await axios.post(BACKEND_URL_CONFIG.addBlock, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 201) {
        toast.success("Votre block a été enregistré");
        navigate(
          `/projects/${projectId}/version/${versionId}/show/${sectionType}/${blockType}`
        );
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      } else if (error.response.status === 400) {
        toast.error(ERRORS.error_145);
      } else {
        toast.error(ERRORS.error_127);
      }
    } finally {
      setLoading(false);
    }
  };

  const saveBlockFromSavedParamsIntoDB = async (payload) => {
    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.addBlockFromSavedParameters,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 201) {
        toast.success("Votre block a été enregistré");
        navigate(
          `/projects/${projectId}/version/${versionId}/show/${sectionType}/${blockType}`
        );
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      } else if (error.response.status === 400) {
        toast.error(ERRORS.error_145);
      } else {
        toast.error(ERRORS.error_127);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <BlockFormContainer>
      <BlockFormTitleContainer
        title={`Ajouter un bloc : ${BLOCK_TYPE_DISPLAY_NAME[subBlockType]}`}
        formId={formIdRef}
        loading={loading}
        createNewBlockFromSavedParams={createNewBlockFromSavedParams}
        needAdvancedOptions={needAdvancedOptions}
        isSubmitting={isSubmitting}
        setNeedAdvancedOptions={setNeedAdvancedOptions}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        <Switch
          onChange={() =>
            setCreateNewBlockFromSavedParams(!createNewBlockFromSavedParams)
          }
        >
          À partir des param. sauvegardés
        </Switch>
      </div>
      <BlockFormFieldsContainer>
        {loading ? (
          <LoadingSpinner iconCustomStyle={{ fontSize: "25px" }} />
        ) : createNewBlockFromSavedParams ? (
          <NewBlockFromSavedParamsForm
            setFormData={setFormData}
            formId={formIdRef}
            needAdvancedOptions={needAdvancedOptions}
            subBlockType={subBlockType}
            defaultBlockDisplayName={subBlockType}
            setIsSubmitting={setIsSubmitting}
          />
        ) : (
          <CleanVerbatimsForm
            setFormData={setFormData}
            formId={formIdRef}
            needAdvancedOptions={needAdvancedOptions}
            defaultBlockDisplayName={subBlockType}
            setIsSubmitting={setIsSubmitting}
          />
        )}
      </BlockFormFieldsContainer>
    </BlockFormContainer>
  );
}
