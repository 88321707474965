import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Option, Select } from "@mui/joy";
import { useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import CreatableSelect from "react-select/creatable";
import styles from "./CleanVerbatimsForm.module.css";
import {
  convertedCurrencies,
  convertedInternPunctuationsNumber,
  convertedInternPunctuationsWord,
  convertedPostPunctuationsNumber,
  convertedPostPunctuationsWord,
  convertedPrePunctuationsNumber,
  convertedPrePunctuationsWord,
  convertedPunctuations,
  initPreprocessConfig,
  preprocessInformationsObject,
} from "./constants.js";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

export default function CleanVerbatimsForm({
  formId,
  block,
  defaultBlockDisplayName,
  needAdvancedOptions,
  setFormData,
  availableColumnsInfo,
}) {
  const getDefaultValues = (blockParameters, fallback) =>
    // We convert the array to this object because the CreatableSelect component needs this format
    blockParameters
      ? blockParameters.split("").map((item) => ({ value: item, label: item }))
      : fallback;

  // Form config
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      blockDisplayName: block ? block.name : defaultBlockDisplayName,
      advancedOptions: {
        word_length_threshold: block
          ? block.parameters.word_length_threshold
          : 0,
        verbatim_column_id: block ? block.parameters.verbatim_column_id : null,
        currencies: getDefaultValues(
          block?.parameters.currencies,
          convertedCurrencies
        ),
        punctuation_word: getDefaultValues(
          block?.parameters.global_punctuation_word,
          convertedPunctuations
        ),
        pre_punctuation_word: getDefaultValues(
          block?.parameters.pre_punctuation_word,
          convertedPrePunctuationsWord
        ),
        intern_punctuation_word: getDefaultValues(
          block?.parameters.intern_punctuation_word,
          convertedInternPunctuationsWord
        ),
        post_punctuation_word: getDefaultValues(
          block?.parameters.post_punctuation_word,
          convertedPostPunctuationsWord
        ),
        pre_punctuation_number: getDefaultValues(
          block?.parameters.pre_punctuation_number,
          convertedPrePunctuationsNumber
        ),
        intern_punctuation_number: getDefaultValues(
          block?.parameters.intern_punctuation_number,
          convertedInternPunctuationsNumber
        ),
        post_punctuation_number: getDefaultValues(
          block?.parameters.post_punctuation_number,
          convertedPostPunctuationsNumber
        ),
      },
    },
  });

  // Refs
  const popupInformationRef = useRef([]);

  const structureInput = (advancedOptionsData) => {
    const allResults = {};
    for (const [key, items] of Object.entries(advancedOptionsData)) {
      // If the item is not an object, we just add it to the results
      if (typeof items != "object" || items === null) {
        allResults[key] = items;
        continue;
      }
      // If the item is an object, we map it to get only the value
      // because the object is structured like this: { value: "value" }
      allResults[key] = items.map((item) => item.value).join("");
    }
    return allResults;
  };

  const onSubmit = (data) => {
    const structuredAdvancedOptions = structureInput(data.advancedOptions);

    const parameters = {
      blockDisplayName: data.blockDisplayName,
      parameters: {
        ...data.preprocessBoolConfig,
        currencies: structuredAdvancedOptions.currencies,
        global_punctuation_word:
          structuredAdvancedOptions.punctuation_word +
          structuredAdvancedOptions.currencies,
        pre_punctuation_word: structuredAdvancedOptions.pre_punctuation_word,
        intern_punctuation_word:
          structuredAdvancedOptions.intern_punctuation_word +
          structuredAdvancedOptions.currencies,
        post_punctuation_word: structuredAdvancedOptions.post_punctuation_word,
        pre_punctuation_number:
          structuredAdvancedOptions.pre_punctuation_number,
        intern_punctuation_number:
          structuredAdvancedOptions.intern_punctuation_number,
        post_punctuation_number:
          structuredAdvancedOptions.post_punctuation_number +
          structuredAdvancedOptions.currencies,
        word_length_threshold: parseInt(
          data.advancedOptions.word_length_threshold
        ),
        verbatim_column_id: data.advancedOptions.verbatim_column_id,
        verbatim_column_id_set_by_user:
          data.advancedOptions.verbatim_column_id_set_by_user,
      },
    };

    parameters.verbatim_column_id =
      data.advancedOptions.verbatim_column_id || null;
    parameters.verbatim_column_id_set_by_user =
      !!data.advancedOptions.verbatim_column_id;

    setFormData(parameters);
  };

  const popupInformationHandler = (index) => {
    const currentDisplayMode = popupInformationRef.current[index].style.display;
    if (currentDisplayMode === "flex") {
      popupInformationRef.current[index].style.display = null;
      return;
    }
    popupInformationRef.current[index].style.display = "flex";
  };

  const popupInformationClose = (index) => {
    popupInformationRef.current[index].style.display = null;
  };

  return (
    <form
      className={styles.container}
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom du bloc</label>
        <input
          {...register("blockDisplayName", { required: true })}
          type="text"
          required={true}
          className={styles["text-input"]}
          placeholder="Saisir le nom de votre bloc"
        />
      </div>
      <div className={styles["field-container"]}>
        <div className={styles["project-preprocess-config-wrapper"]}>
          {Object.entries(initPreprocessConfig)?.map(
            ([configKey, configDefaultValue], index) => (
              <div key={index} className={styles["toggle-list-wrapper"]}>
                <span
                  style={{
                    position: "relative",
                  }}
                >
                  <div
                    ref={(el) => (popupInformationRef.current[index] = el)}
                    className={styles["popup-description"]}
                  >
                    {preprocessInformationsObject[configKey].description}
                  </div>
                  <FontAwesomeIcon
                    icon={faCircleQuestion}
                    className={styles["description-icon"]}
                    onMouseEnter={() => popupInformationHandler(index)}
                    onMouseLeave={() => popupInformationClose(index)}
                  ></FontAwesomeIcon>
                </span>
                <div className={styles["toggle-wrapper"]}>
                  <span
                    style={{
                      position: "relative",
                    }}
                  >
                    {preprocessInformationsObject[configKey].displayName}
                  </span>
                  <label className={styles.switch}>
                    <input
                      {...register(`preprocessBoolConfig.${configKey}`)}
                      type="checkbox"
                      disabled={isSubmitting}
                      defaultChecked={
                        block ? block.parameters[configKey] : configDefaultValue
                      }
                    />
                    <span></span>
                  </label>
                </div>
              </div>
            )
          )}
        </div>
      </div>
      {needAdvancedOptions && (
        <>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Colonne des verbatims</label>
            <Controller
              control={control}
              name="advancedOptions.verbatim_column_id"
              render={({ field }) => (
                <Select
                  {...field}
                  onChange={(_, newValue) => {
                    field.onChange(newValue);
                  }}
                  style={customSelectStyle}
                  placeholder="Choisir la colonne des verbatims..."
                >
                  {Object.entries(availableColumnsInfo || {}).map(
                    ([columnId, columnName]) => (
                      <Option key={columnId} value={columnId}>
                        {columnName}
                      </Option>
                    )
                  )}
                </Select>
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Devises</label>
            <Controller
              name="advancedOptions.currencies"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedCurrencies}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Ponctuations</label>
            <Controller
              name="advancedOptions.punctuation_word"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedPunctuations}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Ponctuations avant le mot</label>
            <Controller
              name="advancedOptions.pre_punctuation_word"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedPrePunctuationsWord}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>
              Ponctuations à l'intérieur du mot
            </label>
            <Controller
              name="advancedOptions.intern_punctuation_word"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedInternPunctuationsWord}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Ponctuations après le mot</label>
            <Controller
              name="advancedOptions.post_punctuation_word"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedPostPunctuationsWord}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>
              Ponctuations avant le chiffre
            </label>
            <Controller
              name="advancedOptions.pre_punctuation_number"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedPrePunctuationsNumber}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>
              Ponctuations à l'interieur du chiffre
            </label>
            <Controller
              name="advancedOptions.intern_punctuation_number"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedInternPunctuationsNumber}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>
              Ponctuations après le chiffre
            </label>
            <Controller
              name="advancedOptions.post_punctuation_number"
              control={control}
              render={({ field }) => (
                <CreatableSelect
                  {...field}
                  isMulti
                  options={convertedPostPunctuationsNumber}
                />
              )}
            />
          </div>
          <div className={styles["field-container"]}>
            <label className={styles.label}>Longueur minimale d'un mot</label>
            <input
              {...register("advancedOptions.word_length_threshold")}
              className={styles["text-input"]}
              type="number"
            />
          </div>
        </>
      )}
    </form>
  );
}
