import { Controller, useForm } from "react-hook-form";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Option, Select } from "@mui/joy";
import ClearButton from "common/components/Button/ClearButton";

import styles from "./UserForm.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

const availableRoles = [
  { id: "user", name: "Utilisateur" },
  { id: "manager", name: "Manager" },
  { id: "internship", name: "Stagiaire" },
];

export default function UserForm({ user, formId, setFormData }) {
  const {
    register,
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      firstname: user ? user.firstname : "",
      lastname: user ? user.lastname : "",
      display_name: user ? user.display_name : "",
      is_admin: user ? user.is_admin : false,
      role: user ? user.role : "",
      available_credits: user ? user.available_credits : 0,
    },
  });

  const onSubmit = (data) => {
    const submittedData = {
      firstname: data.firstname,
      lastname: data.lastname,
      display_name: data.display_name,
      email: data.email,
      is_admin: data.is_admin !== null ? data.is_admin : false, // If is_admin is null, set it to false (to avoid bugs)
      role: data.role,
      available_credits: data.available_credits
        ? parseFloat(data.available_credits)
        : 0.0,
    };

    setFormData(submittedData);
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Prénom</label>
        <input
          {...register("firstname", { required: true })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le prénom..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom</label>
        <input
          {...register("lastname", { required: true })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le nom..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom d'affichage</label>
        <input
          {...register("display_name", { required: true })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le nom d'affichage..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Solde du compte</label>
        <input
          {...register("available_credits", { required: true })}
          type="number"
          step="any"
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le nouveau solde du compte..."
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Role</label>
        <Controller
          name="role"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisir le role..."
              style={customSelectStyle}
              required={true}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              {...(field.value && {
                endDecorator: (
                  <ClearButton
                    onClick={() => {
                      field.onChange(null);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ClearButton>
                ),
                indicator: null,
              })}
            >
              {availableRoles.map((role) => (
                <Option key={role.id} value={role.id}>
                  {role.name}
                </Option>
              ))}
            </Select>
          )}
        />
      </div>
      <div className={styles["checkbox-container"]}>
        <label className={styles.label}>Est-il admin ?</label>
        <input
          {...register("is_admin")}
          type="checkbox"
          disabled={isSubmitting}
          className={styles["checkbox-input"]}
        />
      </div>
    </form>
  );
}
