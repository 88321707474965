import {
  faChevronCircleDown,
  faChevronCircleUp,
  faExclamation,
  faFile,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import IconButton from "common/components/Button/IconButton";
import TransparentButton from "common/components/Button/TransparentButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./UploadedFilesPreviewer.module.css";

const fileIconRef = {
  pdf: <FontAwesomeIcon icon={faFilePdf} />,
  csv: <FontAwesomeIcon icon={faFileCsv} />,
  xlsx: <FontAwesomeIcon icon={faFileExcel} />,
  png: <FontAwesomeIcon icon={faFileImage} />,
};

export default function UploadedFilesPreviewer({ title }) {
  // States
  const [uploadedFilesInfoList, setUploadedFilesInfoList] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [loading, setLoading] = useState(false);

  // Hooks
  const { datasetId } = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    fetchUploadedFilesInfoList();
  }, []);

  const fetchUploadedFilesInfoList = async () => {
    setLoading(true);
    try {
      const url = BACKEND_URL_CONFIG.extractUploadedFiles.replace(
        "{datasetId}",
        datasetId
      );
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setUploadedFilesInfoList(response.data);
        setCollapsed(Object.entries(response.data).length > 0 ? false : true);
      }
    } catch (error) {
      if (error.response.status === 404) {
        setUploadedFilesInfoList([]);
      } else {
        toast.error(ERRORS.error_147);
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteFileHandler = async (fileId) => {
    setLoading(true);
    try {
      const url = BACKEND_URL_CONFIG.deleteUploadedFile
        .replace("{datasetId}", datasetId)
        .replace("{fileId}", fileId);
      const response = await axios.delete(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("Fichier supprimé avec succès");
        fetchUploadedFilesInfoList();
      }
    } catch (error) {
      toast.error(ERRORS.error_146);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>
          <span>{title}</span>

          {loading ? (
            <LoadingSpinner />
          ) : (
            <span className={styles["uploaded-file-number-information"]}>
              {Object.entries(uploadedFilesInfoList).length}
            </span>
          )}
        </div>
        <div>
          {collapsed ? (
            <IconButton onClick={() => setCollapsed(!collapsed)}>
              <FontAwesomeIcon icon={faChevronCircleDown} />
            </IconButton>
          ) : (
            <IconButton onClick={() => setCollapsed(!collapsed)}>
              <FontAwesomeIcon icon={faChevronCircleUp} />
            </IconButton>
          )}
        </div>
      </div>
      {!collapsed && (
        <div>
          {Object.entries(uploadedFilesInfoList).length === 0 ? (
            <div
              className={styles["content-wrapper"]}
              style={{ alignItems: "center" }}
            >
              <div className={styles["empty-table-wrapper"]}>
                <FontAwesomeIcon
                  icon={faExclamation}
                  style={{ color: "#C6B79A", fontSize: "40px" }}
                />
                <span>Il n'y a pas de fichiers uploadés pour ce dataset.</span>
              </div>
            </div>
          ) : (
            <div className={styles["content-wrapper"]}>
              {Object.entries(uploadedFilesInfoList).map(
                ([fileId, eachFile]) => (
                  <div
                    key={fileId}
                    className={styles["file-information-wrapper"]}
                  >
                    <div className={styles["file-type"]}>
                      {fileIconRef[eachFile.format] ? (
                        fileIconRef[eachFile.format]
                      ) : (
                        <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
                      )}
                    </div>
                    <div className={styles["filename"]}>
                      {eachFile.filename}
                    </div>
                    <div className={styles["actions-buttons-wrapper"]}>
                      <TransparentButton
                        style={{ color: "brown" }}
                        onClick={() => deleteFileHandler(fileId)}
                        disabled={loading}
                      >
                        <FontAwesomeIcon icon={faTrashAlt} />
                      </TransparentButton>
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
