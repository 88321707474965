import { faCloudArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "common/components/Button/Button";
import { useState } from "react";
import FileItem from "./FileItem";
import styles from "./FileUploader.module.css";

function FileUploader({ datasetId, updateUploadedFileCallback }) {
  const [startUploadSession, setStartUploadSession] = useState(false);
  const [dragActive, setDragActive] = useState(false);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const allowedExtensions = ["xlsx", "parquet", "csv", "tsv", "txt"];

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  const handleDrop = function (e) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
      const filesArray = Array.from(e.dataTransfer.files);
      const filteredFilesArray = filesArray.filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        return allowedExtensions.includes(extension);
      });
      setUploadedFiles((prevState) => [...prevState, ...filteredFilesArray]);
    }
  };

  const handleChange = function (e) {
    e.preventDefault();
    setStartUploadSession(false);
    if (e.target.files && e.target.files[0]) {
      const filesArray = Array.from(e.target.files);
      const filteredFilesArray = filesArray.filter((file) => {
        const extension = file.name.split(".").pop().toLowerCase();
        return allowedExtensions.includes(extension);
      });
      setUploadedFiles((prevState) => [...prevState, ...filteredFilesArray]);
    }
  };

  const deleteFile = (index) => {
    const uploadedFilesArray = Array.from(uploadedFiles);
    const filteredUploadedFiles = uploadedFilesArray.filter(
      (eachFile) => eachFile !== uploadedFilesArray[index]
    );
    setUploadedFiles(filteredUploadedFiles);
  };

  return (
    <div className={styles["file-uploader-container"]}>
      <div
        className={styles["upload-input-container"]}
        onDragEnter={handleDrag}
        onDragLeave={handleDrag}
        onDragOver={handleDrag}
        onDrop={handleDrop}
      >
        <form
          className={styles["file-upload-form"]}
          onSubmit={(e) => e.preventDefault()}
        >
          <input
            id={styles["input-file"]}
            type="file"
            name="fileUpload"
            accept={allowedExtensions.map((item) => `.${item}`).join(", ")}
            onChange={handleChange}
            multiple
          />
          <label
            htmlFor={styles["input-file"]}
            id="file-drag"
            className={dragActive ? "drag-active" : ""}
          >
            <div className={styles["upload-information-wrapper"]}>
              <FontAwesomeIcon
                icon={faCloudArrowUp}
                className={styles["upload-file-icon"]}
              />
              <span style={{ textAlign: "center" }}>
                Choose a file or drag it here
              </span>
            </div>
          </label>
        </form>
        <div className={styles["extensions-information-wrapper"]}>
          <span>
            formats compatibles : *.xlsx, *.parquet, *.csv, *.tsv, *.txt
          </span>
        </div>
      </div>
      <div className={styles["selected-files-wrapper"]}>
        {uploadedFiles.length > 0 &&
          uploadedFiles?.map((eachFile, index) => (
            <div key={eachFile.name}>
              <FileItem
                datasetId={datasetId}
                uploadedFile={eachFile}
                startUploadSession={startUploadSession}
                deleteFile={deleteFile}
                fileIndex={index}
                updateUploadedFileCallback={updateUploadedFileCallback}
              />
            </div>
          ))}
      </div>
      <div className={styles.footer}>
        <Button
          disabled={uploadedFiles?.length === 0 || startUploadSession}
          onClick={() => setStartUploadSession(true)}
        >
          Upload
        </Button>
      </div>
    </div>
  );
}

export default FileUploader;
