import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Breadcrumbs, Pagination, Stack } from "@mui/material";
import axios from "axios";
import DeleteButton from "common/components/Button/DeleteButton";
import EditButton from "common/components/Button/EditButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./UsersPage.module.css";

export default function UsersPage() {
  // States
  const [loading, setLoading] = useState(false);
  const [allUsers, setAllUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  // Contexts
  const navigate = useNavigate();
  const { currentUser } = useAuth();

  // Constants
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const totalPages = Math.ceil(allUsers?.length / itemsPerPage);
  const filteredUsers = useMemo(
    () => allUsers.slice(indexOfFirstItem, indexOfLastItem),
    [allUsers, indexOfFirstItem, indexOfLastItem]
  );

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    setLoading(true);
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getUsersList, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAllUsers(response.data);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      } else {
        toast.error(ERRORS.error_188);
      }
    } finally {
      setLoading(false);
    }
  };

  const deleteUser = async (userId) => {
    setLoading(true);
    const deleteUserEndpoint = BACKEND_URL_CONFIG.deleteUser.replace(
      "{userId}",
      userId
    );
    try {
      const response = await axios.delete(deleteUserEndpoint, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        fetchUsers();
        toast.success("L'utilisateur a été supprimé");
        return;
      }
    } catch (error) {
      toast.error(ERRORS.error_187);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb component="a" href="#" label="Utilisateurs" />
            </Breadcrumbs>
          </div>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>Utilisateurs</span>
              {loading && <LoadingSpinner />}
            </div>
          </div>
          <div className={styles["main-section"]}>
            {filteredUsers.length === 0 ? (
              <ItemNotFoundContainer message="Aucun utilisateur n’a été trouvé. Vous pouvez en ajouter un !" />
            ) : (
              <div className={styles["table-section-wrapper"]}>
                <table id={styles["users-table"]}>
                  <thead>
                    <tr>
                      <th>Photo</th>
                      <th>Prénom</th>
                      <th>Nom</th>
                      <th>Email</th>
                      <th>Admin</th>
                      <th>Solde du compte</th>
                      <th>Dépensé</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredUsers?.map((user) => (
                      <tr className={styles.row} key={user.id}>
                        <td>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                            }}
                          >
                            {user.photo_url ? (
                              <Avatar
                                alt={user.initiales}
                                src={user.photo_url}
                                sx={{
                                  width: 30,
                                  height: 30,
                                  fontSize: 13,
                                  color: "#000",
                                  backgroundColor: "#cecece",
                                }}
                              />
                            ) : (
                              <Avatar
                                sx={{
                                  width: 30,
                                  height: 30,
                                  fontSize: 13,
                                  color: "#000",
                                  backgroundColor: "#cecece",
                                }}
                              >
                                {user.initiales}
                              </Avatar>
                            )}
                          </div>
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {user.firstname}
                        </td>
                        <td
                          style={{
                            fontWeight: "bold",
                            textDecoration: "underline",
                          }}
                        >
                          {user.lastname}
                        </td>
                        <td>{user.email}</td>
                        <td>
                          {user.is_admin ? (
                            <FontAwesomeIcon
                              icon={faCircleCheck}
                              style={{ color: "#2b652b", fontSize: "20px" }}
                            />
                          ) : (
                            "-"
                          )}
                        </td>
                        <td>{user.available_credits.toFixed(2)} €</td>
                        <td>{user.paid_costs.toFixed(2)} €</td>
                        <td
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{ display: "flex", flexDirection: "row" }}
                          >
                            <EditButton
                              onClick={() => navigate(`/users/edit/${user.id}`)}
                            />

                            <DeleteButton onClick={() => deleteUser(user.id)} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
            {filteredUsers.length > 0 && (
              <div className={styles["pagination-container"]}>
                <Stack spacing={2}>
                  <Pagination
                    page={currentPage}
                    onChange={(e, value) => setCurrentPage(value)}
                    count={totalPages}
                  />
                </Stack>
              </div>
            )}
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
