import Sidebar from "common/containers/Sidebar";
import { useVersions } from "common/hooks/useVersions";
import React, { Fragment } from "react";
import MainLayout from "./MainLayout";

const WorkflowLayout = React.memo(({ children }) => {
  const {
    loadBlocks,
    preprocessBlocks,
    processBlocks,
    updateBlocksOrder,
    versionsInfo,
  } = useVersions();

  return (
    <Fragment>
      <MainLayout>
        <div
          style={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          <Sidebar
            loadBlocks={loadBlocks}
            preprocessBlocks={preprocessBlocks}
            processBlocks={processBlocks}
            updateBlocksOrder={updateBlocksOrder}
            versionsInfo={versionsInfo}
          />
          <main>{children}</main>
        </div>
      </MainLayout>
    </Fragment>
  );
});

export default WorkflowLayout;
