import { LinearProgress } from "@mui/joy";
import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import TabsContainer from "common/containers/Tabs";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./NgramFrequenciesAnalysis.module.css";

export default function NgramFrequenciesAnalysisContainer() {
  // States
  const [currentTab, setCurrentTab] = useState(0);
  const [ngramFrequencies, setNgramFrequencies] = useState([]);
  const [loading, setLoading] = useState(false);

  //   Contexts
  const { currentUser } = useAuth();
  const { projectId, versionId } = useParams();

  // Memo
  const filteredNgramFrequencies = useMemo(() => {
    if (ngramFrequencies?.length === 0) return null;
    const keys = Object.keys(ngramFrequencies);
    return ngramFrequencies?.[keys[currentTab]];
  }, [currentTab, ngramFrequencies]);

  useEffect(() => {
    fetchNgramFrequencies();
  }, []);

  const fetchNgramFrequencies = async () => {
    try {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getNgramFrequencies
        .replace("{projectId}", projectId)
        .replace("{versionId}", versionId);
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setNgramFrequencies(response.data || []);
      }
    } catch (error) {
      toast.error(ERRORS.error_183);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={styles["ngram-frequencies-container"]}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {ngramFrequencies && ngramFrequencies.length === 0 ? (
            <ItemNotFoundContainer message="Aucune analyse n’a été trouvée." />
          ) : (
            <>
              <TabsContainer
                tabsData={Object.keys(ngramFrequencies)}
                handleTabChange={handleTabChange}
              />
              <div className={styles["ngram-blocks-wrapper"]}>
                {Object.entries(filteredNgramFrequencies || {})?.map(
                  ([ngram, ngramTypeInformation], index) => (
                    <NgramBlock
                      key={index}
                      ngramType={ngram}
                      ngramTypeInformation={ngramTypeInformation}
                    />
                  )
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

function NgramBlock({ ngramType, ngramTypeInformation }) {
  const NGRAM_TYPE_REF = {
    1: "Unigrammes",
    2: "Bigrammes",
    3: "Trigrammes",
  };

  const ngramTopNumber = useMemo(() => {
    const ngramValues = Object.values(ngramTypeInformation);
    return Math.max(...ngramValues);
  }, [ngramTypeInformation]);

  return (
    <div className={styles["ngram-block"]}>
      <div className={styles["ngram-block-title"]}>
        {NGRAM_TYPE_REF[ngramType]}
      </div>
      <div className={styles["ngram-block-data"]}>
        {Object.entries(ngramTypeInformation || {})?.map(
          ([ngram, frequency]) => (
            <NgramItem
              key={ngram}
              ngram={ngram}
              frequency={frequency}
              ngramTopNumber={ngramTopNumber}
            />
          )
        )}
      </div>
    </div>
  );
}

function NgramItem({ ngram, frequency, ngramTopNumber }) {
  let percentage = (frequency / ngramTopNumber) * 100;
  if (percentage > 100) {
    percentage = 100;
  }
  return (
    <div className={styles["ngram-item-wrapper"]}>
      <div className={styles["ngram-item-title"]}>{ngram}</div>
      <div className={styles["ngram-item-frequency"]}>
        <LinearProgress determinate value={percentage} sx={{ width: "100%" }} />
      </div>
      <div className={styles["ngram-item-frequency-value"]}>{frequency}</div>
    </div>
  );
}
