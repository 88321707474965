import { useAuth } from "common/hooks/useAuth";

import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Breadcrumbs } from "@mui/material";
import axios from "axios";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import SaveParametersForm from "common/containers/Forms/UploadFile/SaveParametersForm";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment } from "react";
import { toast } from "react-toastify";
import styles from "./EditParametersFilePage.module.css";

export default function EditParametersFilePage() {
  // Contexts
  const { currentUser } = useAuth();
  const { parametersFileId } = useParams();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [parametersFileInformation, setParametersFileInformation] =
    useState(null);

  // Refs
  const formIdRef = useId();

  useEffect(() => {
    const controller = new AbortController();
    fetchParametersFileInformation(controller);
    return () => controller.abort();
  }, []);

  const fetchParametersFileInformation = async (controller) => {
    setLoading(true);
    const url = BACKEND_URL_CONFIG.getParametersFileInformation.replace(
      "{parametersFileId}",
      parametersFileId
    );
    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
        signal: controller.signal,
      });
      if (response.status === 200) {
        setParametersFileInformation(response.data);
      }
    } catch (error) {
      if (error.response.status === 404) {
        navigate("/parameters");
        toast.error(ERRORS.error_182);
        return;
      }
      toast.error(ERRORS.error_170);
    } finally {
      setLoading(false);
    }
  };

  const saveParametersFile = async (controller) => {
    const payload = {
      name: formData.parameters_label,
      description: formData.parameters_description,
      is_certified: formData.is_certified,
    };
    updateParametersFile(payload, controller);
  };

  const updateParametersFile = async (payload, controller) => {
    setLoading(true);
    try {
      const url = BACKEND_URL_CONFIG.updateParametersFile.replace(
        "{parametersFileId}",
        parametersFileId
      );
      const response = await axios.put(url, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
        signal: controller.signal,
      });
      if (response.status === 200) {
        toast.success("Votre fichier de paramètres a été modifié");
        navigate("/parameters");
      }
    } catch (error) {
      toast.error(ERRORS.error_169);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (formData) {
      saveParametersFile(controller);
    }
    return () => controller.abort();
  }, [formData]);

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/parameters"
                label="Paramètres"
              />
              <CustomBreadcrumb component="a" href="#" label="Modifier" />
            </Breadcrumbs>
          </div>
          <div className={styles["main-section"]}>
            <BlockFormContainer>
              <BlockFormTitleContainer
                title="Modifier les paramètres enregistrés"
                formId={formIdRef}
                loading={loading}
                isSubmitting={isSubmitting}
              />
              <BlockFormFieldsContainer>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <SaveParametersForm
                    parametersFileInformation={parametersFileInformation}
                    setFormData={setFormData}
                    formId={formIdRef}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </BlockFormFieldsContainer>
            </BlockFormContainer>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
