import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import TabsContainer from "common/containers/Tabs";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./CompareDictionarySizeAnalysis.module.css";

export default function CompareDictionarySizeAnalysisContainer() {
  // States
  const [currentTab, setCurrentTab] = useState(0);
  const [dictionarySize, setDictionarySize] = useState([]);
  const [loading, setLoading] = useState(false);

  //   Contexts
  const { currentUser } = useAuth();
  const { projectId, versionId } = useParams();

  // Memo
  const filteredDictionarySize = useMemo(() => {
    const keys = Object.keys(dictionarySize);
    return dictionarySize?.[keys[currentTab]];
  }, [currentTab, dictionarySize]);

  useEffect(() => {
    fetchDictionarySize();
  }, []);

  const fetchDictionarySize = async () => {
    try {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getDictionarySize
        .replace("{projectId}", projectId)
        .replace("{versionId}", versionId);
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setDictionarySize(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_183);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={styles["global-stats-container"]}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {Object.keys(dictionarySize || {}).length === 0 ? (
            <ItemNotFoundContainer message="Aucune analyse n’a été trouvée." />
          ) : (
            <>
              <TabsContainer
                tabsData={Object.keys(dictionarySize)}
                handleTabChange={handleTabChange}
              />
              <div className={styles["global-stats-wrapper"]}>
                {Object.entries(filteredDictionarySize || {}).map(
                  ([columnName, unigramNumber], index) => (
                    <div className="stats-container" key={index}>
                      <div className="stat-card" key={columnName}>
                        <h3>{columnName}</h3>
                        <div className="stat-values">
                          <div className="stat-value" key={columnName}>
                            <span className="stat-key">
                              Nombre de 1-Grams :{" "}
                            </span>
                            <span className="stat-value-number">
                              {unigramNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}
