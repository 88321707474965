import { TabList, Tabs } from "@mui/joy";
import Tab, { tabClasses } from "@mui/joy/Tab";

export default function TabsContainer({ children, handleTabChange, tabsData }) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        fontFamily: "inherit",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Tabs
        aria-label="tabs"
        defaultValue={0}
        sx={{ bgcolor: "transparent" }}
        onChange={handleTabChange}
      >
        <TabList
          disableUnderline
          sx={{
            p: 0.5,
            gap: 0.5,
            borderRadius: "xl",
            bgcolor: "background.level1",
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              boxShadow: "sm",
              bgcolor: "background.surface",
            },
          }}
        >
          {tabsData.map((tab) => (
            <Tab key={tab} disableIndicator>
              {tab}
            </Tab>
          ))}
        </TabList>
      </Tabs>
    </div>
  );
}
