import {
  faCirclePlus,
  faCodeFork,
  faDownload,
  faEllipsisVertical,
  faPenToSquare,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";

import TransparentButton from "common/components/Button/TransparentButton";
import LoadingSpinner from "common/components/LoadingSpinner";

import { faFile } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import CustomMenuButton from "common/components/Button/CustomMenuButton";
import Modal from "common/components/Modal";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useAuth } from "common/hooks/useAuth";
import { useVersions } from "common/hooks/useVersions";
import { BACKEND_URL_CONFIG } from "config";
import {
  ALLOWED_BLOCKS_BY_SECTION,
  BLOCK_TYPE_DISPLAY_NAME,
  REDIRECT_BLOCK_PAGES_MAP,
} from "core/blocks-config";
import { ERRORS } from "errors";
import WorkflowLayout from "layouts/WorkflowLayout";
import PreviewWorkingDatasetPage from "pages/PreviewWorkingDatasetPage";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./RunBlocksPage.module.css";
import RunnableBlock from "./containers/RunnableBlock";

export default function RunBlocksPage() {
  // States
  const [loading, setLoading] = useState(false);
  const [sectionBlocks, setSectionBlocks] = useState([]);
  const [editEnabled, setEditEnabled] = useState(false);
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  // Context
  const { versionsInfo } = useVersions();
  const { currentUser } = useAuth();
  const { sectionType, projectId, versionId } = useParams();
  const navigate = useNavigate();

  const allowedBlocksBySection = ALLOWED_BLOCKS_BY_SECTION[sectionType] || [];

  useEffect(() => {
    if (
      versionsInfo &&
      versionsInfo.blocks &&
      sectionType in versionsInfo.blocks
    ) {
      // Dear developer,
      // The goal is to structure blocks object coming from firestore.
      // For each block type, we should filter on front like "lower", "upper" or another block...
      // So, we need a final JS object like that
      // {'lower': {
      //   "85c454ce-8536-479c-9e58-764c1e75eb40": {
      //     "order": '2', "job_id": null, "name": 'asd', "type": 'lower', "parameters": {}
      //   },
      // ...
      // }
      const filteredSectionsBlocksByType = Object.entries(
        versionsInfo.blocks[sectionType]
      ).reduce((acc, [eachBlockId, eachBlockInfo]) => {
        const type = eachBlockInfo.type;

        if (!eachBlockInfo.active) {
          return acc;
        }

        if (!acc[type]) {
          acc[type] = {};
        }
        acc[type] = { ...acc[type], [eachBlockId]: eachBlockInfo };
        return acc;
      }, {});

      setSectionBlocks(filteredSectionsBlocksByType);
    } else {
      setSectionBlocks([]);
    }
  }, [versionsInfo, sectionType]);

  const redirectToNewBlockPage = (blockType) => {
    navigate(
      `/projects/${projectId}/version/${versionId}/show/${sectionType}/${blockType}`
    );
  };

  const deleteAllBlocks = async () => {
    const payload = {
      version_id: versionId,
      project_id: projectId,
      user_id: currentUser.uid,
    };

    const url = BACKEND_URL_CONFIG.deleteAllBlock.replace(
      "{sectionType}",
      sectionType
    );
    setLoading(true);
    try {
      const response = await axios.delete(url, {
        data: payload,
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("Les blocks ont été supprimés.");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_144);
        return;
      }
      toast.error(ERRORS.error_124);
      return;
    } finally {
      setLoading(false);
    }
  };

  const downloadDataset = async () => {
    const payload = {
      project_id: projectId,
      version_id: versionId,
      file_format: "xlsx",
    };

    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.downloadVersionDataset,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        const a = document.createElement("a");
        document.body.appendChild(a);
        const blobUrl = window.URL.createObjectURL(response.data);
        a.href = blobUrl;
        a.download = `${versionId}.xlsx`;
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);

        toast.success("Le dataset a été téléchargé !");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      }
      toast.error(ERRORS.error_131);
      return;
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <WorkflowLayout>
        <div className={styles.container}>
          <Modal
            open={isPreviewModalOpen}
            setOpen={setIsPreviewModalOpen}
            customStyle={{ width: "90%", height: "90%" }}
          >
            <PreviewWorkingDatasetPage />
          </Modal>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>
                Executer vos blocs pour la section '{sectionType.toLowerCase()}'
              </span>
              <span className={styles.subtitle}>
                Vous pouvez estimer le coût du bloc ou l'éxecuter directement.
                {loading ||
                  (versionsInfo?.running_job_ids?.length > 0 && (
                    <LoadingSpinner />
                  ))}
              </span>
            </div>
            {Object.entries(sectionBlocks).length === 0 && (
              <Dropdown>
                <CustomMenuButton title="Ajouter un nouveau block">
                  <span className={styles["transparent-button"]}>
                    <FontAwesomeIcon
                      disabled={
                        loading || versionsInfo?.running_job_ids?.length > 0
                      }
                      icon={faCirclePlus}
                    />
                    <span> Nouveau</span>
                  </span>
                </CustomMenuButton>
                <Menu>
                  {allowedBlocksBySection?.map((item, index) => (
                    <MenuItem
                      disabled={
                        loading || versionsInfo?.running_job_ids?.length > 0
                      }
                      id={item}
                      onClick={() => redirectToNewBlockPage(item)}
                      key={index}
                    >
                      {BLOCK_TYPE_DISPLAY_NAME?.[item] || item}
                    </MenuItem>
                  ))}
                </Menu>
              </Dropdown>
            )}
            {Object.entries(sectionBlocks).length > 0 && (
              <div className={styles["actions-buttons-wrapper"]}>
                <TransparentButton
                  onClick={() => setIsPreviewModalOpen(!isPreviewModalOpen)}
                  disabled={
                    loading || versionsInfo?.running_job_ids?.length > 0
                  }
                >
                  <>
                    <FontAwesomeIcon icon={faFile} />
                    <span> Aperçu</span>
                  </>
                </TransparentButton>
                <TransparentButton
                  onClick={() =>
                    navigate(`/projects/${projectId}/fork-version/${versionId}`)
                  }
                  disabled={
                    loading || versionsInfo?.running_job_ids?.length > 0
                  }
                >
                  <>
                    <FontAwesomeIcon icon={faCodeFork} />
                    <span> Fork</span>
                  </>
                </TransparentButton>
                <TransparentButton
                  onClick={downloadDataset}
                  disabled={
                    loading || versionsInfo?.running_job_ids?.length > 0
                  }
                >
                  {loading ? (
                    <LoadingSpinner />
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faDownload} />
                      <span> Télécharger</span>
                    </>
                  )}
                </TransparentButton>
                {editEnabled ? (
                  <TransparentButton
                    onClick={() => setEditEnabled(false)}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                    <span> Fermer</span>
                  </TransparentButton>
                ) : (
                  <TransparentButton
                    onClick={() => setEditEnabled(true)}
                    disabled={
                      loading || versionsInfo?.running_job_ids?.length > 0
                    }
                  >
                    <FontAwesomeIcon icon={faPenToSquare} />
                    <span> Modifier</span>
                  </TransparentButton>
                )}
                <Dropdown>
                  <MenuButton
                    style={{ padding: "11px 16px" }}
                    disabled={loading}
                  >
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                  </MenuButton>
                  <Menu>
                    <MenuItem
                      onClick={deleteAllBlocks}
                      disabled={versionsInfo?.running_job_ids?.length > 0}
                    >
                      Supprimer tous les blocs
                    </MenuItem>
                  </Menu>
                </Dropdown>
              </div>
            )}
          </div>
          <div className={styles["content-wrapper"]}>
            {Object.entries(sectionBlocks).length === 0 ? (
              <div className={styles["no-blocks-error-container"]}>
                <ItemNotFoundContainer
                  message="Il n'y a pas de bloc actif pour cette section. Veuillez
                  ajouter des blocs ou activer ceux désactivés!"
                />
              </div>
            ) : (
              Object.entries(sectionBlocks)
                ?.sort(
                  (a, b) =>
                    Object.values(a[1])[0].order - Object.values(b[1])[0].order
                )
                ?.map(([blockType, blockTypeInformation]) =>
                  Object.entries(blockTypeInformation || {}).map(
                    ([blockId, blockInformation]) =>
                      blockInformation.active && (
                        <RunnableBlock
                          key={blockId}
                          blockId={blockId}
                          blockType={blockType}
                          currentJobId={blockInformation.job_id}
                          blockInformation={blockInformation}
                          editEnabled={editEnabled}
                          shouldDeactivateButtons={loading}
                        />
                      )
                  )
                )
            )}
            {Object.entries(sectionBlocks).length > 0 && (
              <div className={styles["new-block-button-container"]}>
                <Dropdown>
                  <CustomMenuButton
                    title="Ajouter un nouveau block"
                    className={styles["add-new-block-custom-button"]}
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                  </CustomMenuButton>
                  <Menu>
                    {Object.entries(
                      REDIRECT_BLOCK_PAGES_MAP?.[sectionType] || {}
                    ).map(([blockId, displayName], index) => (
                      <MenuItem
                        id={blockId}
                        disabled={
                          loading || versionsInfo?.running_job_ids?.length > 0
                        }
                        onClick={() => redirectToNewBlockPage(blockId)}
                        key={index}
                      >
                        {displayName}
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              </div>
            )}
          </div>
        </div>
      </WorkflowLayout>
    </Fragment>
  );
}
