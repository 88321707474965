import { useAuth } from "common/hooks/useAuth";
import { useCallback, useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import { db } from "core/firebase";
import { ERRORS } from "errors";
import { collection, getDocs, limit, query, where } from "firebase/firestore";
import { toast } from "react-toastify";

const ProtectedVersion = () => {
  // States
  const [isChecking, setIsChecking] = useState(true);

  // Contexts
  const { versionId, projectId } = useParams();
  const navigate = useNavigate();
  const { currentUser, isUserAdmin } = useAuth();

  const checkAccess = useCallback(async () => {
    try {
      const versionsCollection = collection(db, "versions");
      const versionMatchQuery = query(
        versionsCollection,
        where("__name__", "==", versionId),
        where("used_in", "==", projectId),
        limit(1)
      );

      const querySnapshot = await getDocs(versionMatchQuery);

      if (querySnapshot.docs.length > 0) {
        const versionData = querySnapshot.docs[0].data();
        const hasAccess =
          versionData.owner_ids.includes(currentUser.uid) ||
          versionData.authorized_user_ids.includes(currentUser.uid) ||
          isUserAdmin;

        if (hasAccess) {
          setIsChecking(false);
        } else {
          toast.error(ERRORS.error_129);
          navigate(`/projects/${projectId}`);
        }
      } else {
        toast.error(ERRORS.error_185);
        navigate(`/projects/${projectId}`);
      }
    } catch (error) {
      toast.error(error.message);
      navigate(`/projects/${projectId}`);
    }
  }, [versionId, currentUser, navigate, projectId, isUserAdmin]);

  useEffect(() => {
    if (versionId && currentUser.uid) {
      checkAccess();
    }
  }, [checkAccess, versionId, currentUser]);

  return !isChecking ? <Outlet /> : null;
};

export default ProtectedVersion;
