import styles from "./SubmitButton.module.css";

export default function SubmitButton({ formId, value, disabled, style }) {
  return (
    <input
      className={styles["transparent-submit-button"]}
      style={style}
      form={formId}
      disabled={disabled}
      type="submit"
      value={value}
    />
  );
}
