import {
  faChevronCircleDown,
  faChevronCircleUp,
  faExclamation,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import axios from "axios";
import IconButton from "common/components/Button/IconButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import SelectDropdown from "common/components/SelectDropdown";
import { useAuth } from "common/hooks/useAuth";
import { useStepperContext } from "common/hooks/useStepperContext";
import { BACKEND_URL_CONFIG } from "config";
import { db } from "core/firebase";
import { ERRORS } from "errors";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./UploadedFileConfigTable.module.css";

const DEFAULT_VALUE_BY_FIELD = {
  decimal: [
    { value: "auto", label: "Auto" },
    { value: ".", label: "." },
    { value: ",", label: "," },
  ],
  encoding: [
    { value: "auto", label: "Auto" },
    { value: "utf-8", label: "UTF-8" },
    { value: "utf-8-sig", label: "UTF-8-SIG" },
    { value: "utf-16", label: "UTF-16" },
    { value: "utf-16", label: "UTF-16" },
    { value: "iso-8859-1", label: "ISO-8859-1" },
  ],
  delimiter: [
    { value: "auto", label: "Auto" },
    { value: ".", label: "." },
    { value: ",", label: "," },
    { value: ";", label: ";" },
    { value: "\\t", label: "\\t" },
  ],
  quotechar: [
    { value: "auto", label: "Auto" },
    { value: '"', label: '"' },
    { value: "'", label: "'" },
    { value: ";", label: ";" },
    { value: "<>", label: "<>" },
    { value: "()", label: "()" },
    { value: "|", label: "|" },
  ],
  encoding_errors: [
    { value: "strict", label: "Strict" },
    { value: "ignore", label: "Ignore" },
    { value: "replace", label: "Replace" },
  ],
  on_bad_lines: [
    { value: "error", label: "Error" },
    { value: "warn", label: "Warn" },
    { value: "skip", label: "Skip" },
  ],
};

export default function UploadedFileConfigTable({ title, isCollapsed }) {
  const { datasetId } = useParams();
  const [currentPage, setCurrentPage] = useState(1);
  const [uploadedFilesInfoList, setUploadedFilesInfoList] = useState([]);
  const [collapsed, setCollapsed] = useState(isCollapsed ? isCollapsed : true);
  const [loading, setLoading] = useState(false);
  const { currentUser } = useAuth();
  const tableHeaderRef = useRef([]);
  const itemsPerPage = 10;
  const { datasetInfo } = useStepperContext();

  // Calculate the starting and ending indices of the current page
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = currentPage * itemsPerPage;

  useEffect(() => {
    if (datasetInfo?.settings) {
      setUploadedFilesInfoList(datasetInfo.settings);
    }
  }, [datasetInfo]);

  const triggerIngestDataset = async () => {
    const payload = {
      dataset_id: datasetId,
      user_id: currentUser.uid,
    };
    try {
      setLoading(true);
      const response = await axios.post(
        BACKEND_URL_CONFIG.ingestDataset,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Le dataset a été traité à nouveau");
      }
    } catch (error) {
      if (error.response.status === 404) {
        return;
      }
      toast.error(ERRORS.error_147);
    } finally {
      setLoading(false);
    }
  };

  const paginationChangeHandler = (event, value) => {
    setCurrentPage(value);
  };

  const fieldChangeHandler = async (event, fileId, optionType) => {
    const newValue = event?.target.innerText;
    if (newValue === undefined) {
      return;
    }

    // Get filename to update
    const datasetRef = doc(db, "datasets", datasetId);
    const document = await getDoc(datasetRef);
    if (document.exists()) {
      const fieldTtoUpdate = `settings.${fileId}.${optionType}`;
      updateDoc(datasetRef, { [fieldTtoUpdate]: newValue.toLowerCase() });
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>
          <span>{title}</span>
          <span className={styles["uploaded-file-number-information"]}>
            {Object.entries(uploadedFilesInfoList).length}
          </span>
          {loading && <LoadingSpinner />}
        </div>
        <div>
          {collapsed ? (
            <IconButton onClick={() => setCollapsed(!collapsed)}>
              <FontAwesomeIcon icon={faChevronCircleDown} />
            </IconButton>
          ) : (
            <>
              <IconButton>
                <FontAwesomeIcon
                  title="Relancer le préprocess de votre dataset"
                  onClick={triggerIngestDataset}
                  icon={faRefresh}
                ></FontAwesomeIcon>
              </IconButton>
              <IconButton onClick={() => setCollapsed(!collapsed)}>
                <FontAwesomeIcon icon={faChevronCircleUp} />
              </IconButton>
            </>
          )}
        </div>
      </div>
      {!collapsed && (
        <div>
          {Object.entries(uploadedFilesInfoList).length === 0 ? (
            <div
              className={styles["content-wrapper"]}
              style={{ alignItems: "center" }}
            >
              <div className={styles["empty-table-wrapper"]}>
                <FontAwesomeIcon
                  icon={faExclamation}
                  style={{ color: "#C6B79A", fontSize: "40px" }}
                />
                <span>Il n'y a pas de fichiers uploadés pour ce dataset.</span>
              </div>
            </div>
          ) : (
            <div className={styles["content-wrapper"]}>
              <table id={styles["uploaded-file-table"]}>
                <thead>
                  <tr ref={tableHeaderRef}>
                    <th>Nom du fichier</th>
                    <th>Décimale</th>
                    <th>Encoding</th>
                    <th>Delimiter</th>
                    <th>Quotechar</th>
                    <th>Encoding errors</th>
                    <th>Bad lines errors</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(uploadedFilesInfoList)
                    .slice(startIndex, endIndex)
                    .map(([fileId, uploadedFile], index) => (
                      <tr key={fileId}>
                        <td className={styles["file-name"]}>
                          {uploadedFile.filename}
                        </td>
                        <td>
                          <SelectDropdown
                            defaultValue={uploadedFile.decimal}
                            onChange={(e) =>
                              fieldChangeHandler(e, fileId, "decimal")
                            }
                            optionsArray={DEFAULT_VALUE_BY_FIELD.decimal}
                          ></SelectDropdown>
                        </td>
                        <td>
                          <SelectDropdown
                            defaultValue={uploadedFile.encoding}
                            onChange={(e) =>
                              fieldChangeHandler(e, fileId, "encoding")
                            }
                            optionsArray={DEFAULT_VALUE_BY_FIELD.encoding}
                          ></SelectDropdown>
                        </td>
                        <td>
                          <SelectDropdown
                            defaultValue={uploadedFile.delimiter}
                            onChange={(e) =>
                              fieldChangeHandler(e, fileId, "delimiter")
                            }
                            optionsArray={DEFAULT_VALUE_BY_FIELD.delimiter}
                          ></SelectDropdown>
                        </td>
                        <td>
                          <SelectDropdown
                            defaultValue={uploadedFile.quotechar}
                            onChange={(e) =>
                              fieldChangeHandler(e, fileId, "quotechar")
                            }
                            optionsArray={DEFAULT_VALUE_BY_FIELD.quotechar}
                          ></SelectDropdown>
                        </td>
                        <td>
                          <SelectDropdown
                            defaultValue={uploadedFile.encoding_errors}
                            onChange={(e) =>
                              fieldChangeHandler(e, fileId, "encoding_errors")
                            }
                            optionsArray={
                              DEFAULT_VALUE_BY_FIELD.encoding_errors
                            }
                          ></SelectDropdown>
                        </td>
                        <td>
                          <SelectDropdown
                            defaultValue={uploadedFile.on_bad_lines}
                            onChange={(e) =>
                              fieldChangeHandler(e, fileId, "on_bad_lines")
                            }
                            optionsArray={DEFAULT_VALUE_BY_FIELD.on_bad_lines}
                          ></SelectDropdown>
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {itemsPerPage < uploadedFilesInfoList.length && (
                <div className={styles["pagination-container"]}>
                  <Stack spacing={2}>
                    <Pagination
                      page={currentPage}
                      onChange={paginationChangeHandler}
                      count={10}
                    />
                  </Stack>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}
