import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./FailedIcon.module.css";

export default function FailedIcon({className}) {
  const customClassName = className
  ? className
  : styles["failed-icon-style"];
  return (
    <>
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className={customClassName}
      />
    </>
  );
}
