import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./ItemNotFoundContainer.module.css";

export default function ItemNotFoundContainer({ message }) {
  return (
    <div className={styles["item-not-found-container"]}>
      <span>
        <FontAwesomeIcon
          icon={faExclamationCircle}
          style={{ color: "#C6B79A", fontSize: "40px" }}
        />
      </span>
      <span>{message}</span>
    </div>
  );
}
