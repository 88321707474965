import WorkflowLayout from "layouts/WorkflowLayout";
import { Fragment, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./EditBlocks.module.css";
import { EDIT_BLOCKS_COMPONENT_REFERENTIAL } from "./config";

export default function EditBlocks() {
  const [currentDynamicContent, setCurrentDynamicContent] = useState(null);

  const navigate = useNavigate();
  const { projectId, versionId, blockType, subBlockType } = useParams();

  const getDynamicContent = () => {
    // According to sub block type we will display the right content
    const content = EDIT_BLOCKS_COMPONENT_REFERENTIAL[subBlockType];

    // If the content is not found, we redirect to the load page
    if (content === undefined) {
      navigate(`/projects/${projectId}/version/${versionId}/run/load`);
    }
    return content;
  };

  useEffect(() => {
    setCurrentDynamicContent(getDynamicContent());
  }, [blockType]);

  return (
    <Fragment>
      <WorkflowLayout>
        <div className={styles["dynamic-content-container"]}>
          <div className={styles["config-workflow-container"]}>
            {/* Attention, the content is dynamic */}
            {currentDynamicContent}
          </div>
        </div>
      </WorkflowLayout>
    </Fragment>
  );
}
