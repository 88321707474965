import { useForm } from "react-hook-form";

import { useEffect } from "react";
import styles from "./StopwordsFileForm.module.css";

export default function StopwordsFileForm({
  stopwordsFileInformation,
  formId,
  setFormData,
  setIsSubmitting,
}) {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
      file_label: stopwordsFileInformation ? stopwordsFileInformation.name : "",
      is_certified: stopwordsFileInformation
        ? stopwordsFileInformation.is_certified
        : false,
    },
  });

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting, setIsSubmitting]);

  const onSubmit = (data) => {
    if (!stopwordsFileInformation) {
      // It's about a creation
      const file = data.file_content[0];
      const submittedData = {
        file_label: data.file_label,
        file_content: file,
        file_extension: file.name.split(".").pop().toLowerCase(),
        is_certified: data.is_certified,
      };
      setFormData(submittedData);
    } else {
      // It's about an update
      setFormData(data);
    }
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Label du fichier</label>
        <input
          {...register("file_label", {
            required: true,
            validate: (value) => {
              return !!value.trim();
            },
          })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le label du fichier"
        />
      </div>
      <div className={styles["checkbox-container"]}>
        <label className={styles.label}>Le fichier est-il certifié ?</label>
        <input
          {...register("is_certified")}
          type="checkbox"
          disabled={isSubmitting}
          className={styles["checkbox-input"]}
        />
      </div>
      {!stopwordsFileInformation && (
        <div className={styles["field-container"]} style={{ gap: "10px" }}>
          <span className={styles.label}>
            Uploader un fichier (seuls les fichiers XLSX sont acceptés)
          </span>
          <label
            htmlFor="file-input"
            className={styles["file-input-container"]}
          >
            <input
              id="file-input"
              {...register("file_content", {
                required: true,
              })}
              className={styles["file-input"]}
              type="file"
              accept=".xlsx"
            />
          </label>
        </div>
      )}
    </form>
  );
}
