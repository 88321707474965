import { Controller, useForm } from "react-hook-form";

import { Option, Select } from "@mui/joy";
import { useAuth } from "common/hooks/useAuth";
import { useEffect } from "react";
import styles from "./DatasetForm.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

export default function DatasetForm({
  datasetObject,
  formId,
  setFormData,
  setIsSubmitting,
  allUsersInformation,
}) {
  // Contexts
  const { currentUser } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      name: datasetObject ? datasetObject.name : "",
      is_draft: datasetObject ? datasetObject.is_draft : false,
      owner_ids: datasetObject ? datasetObject.owner_ids : [],
      authorized_user_ids: datasetObject
        ? datasetObject.authorized_user_ids
        : [],
    },
  });

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  const onSubmit = (data) => {
    const submittedData = {
      name: data.name,
      user_id: currentUser.uid,
      owner_ids: data.owner_ids,
      authorized_user_ids: data.authorized_user_ids,
    };
    setFormData(submittedData);
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom du dataset</label>
        <input
          {...register("name", {
            required: true,
            validate: (value) => {
              return !!value.trim(); // The input can not be empty or only whitespace
            },
          })}
          type="text"
          required={true}
          disabled={isSubmitting}
          className={styles["text-input"]}
          placeholder="Saisir le nom de votre projet"
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Admin(s)</label>
        <Controller
          name="owner_ids"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              required
              placeholder="Choisir les admins..."
              style={customSelectStyle}
              disabled={isSubmitting}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {Object.entries(allUsersInformation).map(
                ([userId, userDisplayName]) => (
                  <Option key={userId} value={userId}>
                    {userDisplayName}
                  </Option>
                )
              )}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Personnes autorisées</label>
        <Controller
          name="authorized_user_ids"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              multiple
              required
              placeholder="Choisir les personnes autorisées..."
              style={customSelectStyle}
              disabled={isSubmitting}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
            >
              {Object.entries(allUsersInformation).map(
                ([userId, userDisplayName]) => (
                  <Option key={userId} value={userId}>
                    {userDisplayName}
                  </Option>
                )
              )}
            </Select>
          )}
        />
      </div>
    </form>
  );
}
