import { faExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinearProgress, Table } from "@mui/joy";
import FailedIcon from "common/components/Icon/FailedIcon";
import WaitingIcon from "common/components/Icon/NotProcessedIcon";
import SuccessIcon from "common/components/Icon/SuccessIcon";
import LoadingSpinner from "common/components/LoadingSpinner";
import FailedMessageContainer from "common/containers/FailedMessageContainer";
import { useStepperContext } from "common/hooks/useStepperContext";
import { db } from "core/firebase";
import { doc, onSnapshot } from "firebase/firestore";
import { useEffect, useMemo, useState } from "react";
import styles from "./DatasetPreviewTable.module.css";

// Status icon mapping to make it dynamic
const StatusIcon = ({ status }) => {
  if (!status) {
    return null;
  }
  const icons = {
    not_processed: <WaitingIcon />,
    in_progress: <LoadingSpinner />,
    failed: <FailedIcon />,
    processed: <SuccessIcon />,
  };

  return icons[status.toLowerCase()] || null;
};

export default function DatasetPreviewTable({ title }) {
  // Contexts
  const { datasetInfo } = useStepperContext();

  // States
  const [currentJobDocument, setCurrentJobDocument] = useState(null);

  const columnNames = Object.entries(datasetInfo?.stats?.columns || {})
    .sort((a, b) => a[1].index - b[1].index)
    .map(([columnId, columnInfo]) => columnInfo.name);

  // Memos
  const currentJobId = useMemo(() => {
    return datasetInfo?.job_id;
  }, [datasetInfo]);

  useEffect(() => {
    if (!currentJobId) {
      return;
    }
    const docRef = doc(db, "jobs", currentJobId);
    // Connection to db with real time option
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const currentJobData = doc.data();
      if (currentJobData !== undefined) {
        setCurrentJobDocument(currentJobData);
      }
    });
    return () => unsubscribe();
  }, [currentJobId]);

  return (
    <div className={styles.container}>
      <div className={styles["title-wrapper"]}>
        <div className={styles.title}>
          <span>{title}</span>
          <span>
            <StatusIcon status={currentJobDocument?.status} />
          </span>
        </div>
      </div>
      <div className={styles["content-wrapper"]}>
        {currentJobDocument && currentJobDocument?.status !== "PROCESSED" ? (
          currentJobDocument.status === "FAILED" ? (
            <FailedMessageContainer message="Une erreur est survenue lors du traitement du dataset." />
          ) : (
            <div style={{ display: "flex", justifyContent: "center" }}>
              <LinearProgress />
            </div>
          )
        ) : Object.keys(datasetInfo?.preview || {}).length > 0 ? (
          <div className={styles["content"]}>
            <Table style={{ width: "100%" }}>
              <thead>
                <tr>
                  {columnNames?.map((eachColumnName) => (
                    <th
                      key={eachColumnName}
                      style={{
                        width: "200px",
                      }}
                      className={
                        eachColumnName === "id" ? styles["id-column"] : ""
                      }
                    >
                      {eachColumnName}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {Object.values(datasetInfo?.preview || {}).map(
                  (eachRowObj, index) => (
                    <tr key={index}>
                      {Object.values(eachRowObj).map((row) => (
                        <td
                          style={{ wordWrap: "break-word" }}
                          key={row.column_name}
                        >
                          {row.value}
                        </td>
                      ))}
                    </tr>
                  )
                )}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className={styles["empty-table-wrapper"]}>
            <FontAwesomeIcon
              icon={faExclamation}
              style={{ color: "#C6B79A", fontSize: "40px" }}
            />
            <span>Il n'y a pas d'aperçu à visualiser pour ce dataset.</span>
          </div>
        )}
      </div>
    </div>
  );
}
