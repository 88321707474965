import React, { useState } from "react";
import styles from "./UploadDatasetFromGCS.module.css";

export default function UploadDatasetFromGCS({ setCurrentStep }) {
  const [gcpProjectName, setGcpProjectName] = useState("");
  const [datasetUri, setDatasetUri] = useState("");
  const gcpProjectNameChangeHandler = (e) => {
    setGcpProjectName(e.target.value);
  };
  const datasetUriChangeHandler = (e) => {
    setDatasetUri(e.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles["gcp-project-name-container"]}>
        <label className={styles.label}>Nom du projet sur GCP</label>
        <input
          type="text"
          name="gcpProjectName"
          required="required"
          placeholder="Saisir le nom de votre projet sur GCP"
          onChange={gcpProjectNameChangeHandler}
          value={gcpProjectName}
        />
      </div>
      <div className={styles["gcp-dataset-uri-container"]}>
        <label className={styles.label}>Dataset URI</label>
        <input
          type="text"
          name="datasetUri"
          required="required"
          placeholder="Saisir l'URI complet du fichier'"
          onChange={datasetUriChangeHandler}
          value={datasetUri}
        />
      </div>
    </div>
  );
}
