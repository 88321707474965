import {
  faCircleCheck,
  faCircleExclamation,
  faFile,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFilePdf,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LinearProgress } from "@mui/material";
import axios from "axios";
import DeleteButton from "common/components/Button/DeleteButton";
import IconButton from "common/components/Button/IconButton";
import { useAuth } from "common/hooks/useAuth";
import { UPLOAD_BUCKET_NAME } from "config";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import styles from "./FileItem.module.css";

const fileIconRef = {
  "application/pdf": <FontAwesomeIcon icon={faFilePdf}></FontAwesomeIcon>,
  "text/csv": <FontAwesomeIcon icon={faFileCsv}></FontAwesomeIcon>,
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": (
    <FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon>
  ),
  "application/vnd.ms-excel": (
    <FontAwesomeIcon icon={faFileExcel}></FontAwesomeIcon>
  ),
  "image/png": <FontAwesomeIcon icon={faFileImage}></FontAwesomeIcon>,
};

export default function FileItem({
  datasetId,
  uploadedFile,
  startUploadSession,
  deleteFile,
  fileIndex,
  updateUploadedFileCallback,
}) {
  const { generateGCPAccessToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState(false);
  const [canceled, setCanceled] = useState(false);
  const [requestController, setRequestController] = useState();

  useEffect(() => {
    if (startUploadSession) {
      goUpload();
    }
  }, [startUploadSession]);

  const chunkUpload = async (sessionURI) => {
    if (sessionURI === undefined) {
      return;
    }
    const fileSize = uploadedFile.size;
    const chunkSize = 6 * 1024 * 1024; // Set chunk size to 6MB
    const totalChunks = Math.ceil(fileSize / chunkSize);
    const progressValue = 100 / totalChunks;
    const controller = new AbortController();
    setRequestController(controller);

    setLoading(true);
    for (let start = 0; start < fileSize; start += chunkSize) {
      const end = Math.min(start + chunkSize, fileSize);
      const chunk = uploadedFile.slice(start, end);
      try {
        const response = await axios.put(sessionURI, chunk, {
          headers: {
            "Content-Range": `bytes ${start}-${end - 1}/${fileSize}`,
          },
          signal: controller.signal,
        });
        if (response.status === 200) {
          setUploaded(true);
          updateUploadedFileCallback({
            filename: uploadedFile.name,
            file_type: uploadedFile.name.split(".").pop().toLowerCase(),
          });
          setLoading(false);
        }
      } catch (err) {
        if (err?.code === "ERR_CANCELED") {
          setCanceled(true);
          setLoading(false);
          return;
        }
        if (err.response && err.response?.status !== 308) {
          toast.error(ERRORS.error_104);
          setLoading(false);
          return;
        }
        setProgress((progress) => progress + progressValue);
      }
    }
  };

  const initUploadSession = async () => {
    const accessToken = await generateGCPAccessToken();
    const nestedFilenamePath = `uploaded_datasets/${datasetId}/${uploadedFile.name}`;
    try {
      const response = await axios.post(
        `https://storage.googleapis.com/upload/storage/v1/b/${UPLOAD_BUCKET_NAME}/o?uploadType=resumable&name=${nestedFilenamePath}`,
        {},
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      return response.headers.location;
    } catch (error) {
      if (error.response.status === 401) {
        toast.error(ERRORS.error_102);
        setLoading(false);
        setCanceled(true);
        return;
      }
      toast.error(ERRORS.error_105);
    }
  };

  const goUpload = async () => {
    const sessionURI = await initUploadSession();
    const result = await chunkUpload(sessionURI);
  };

  return (
    <div className={styles.container}>
      <div className={styles["file-type-wrapper"]}>
        {fileIconRef[uploadedFile.type] ? (
          fileIconRef[uploadedFile.type]
        ) : (
          <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
        )}
      </div>
      <div className={styles["file-information-grid"]}>
        <div className={styles["file-information-wrapper"]}>
          <div className={styles["filename"]}>{uploadedFile.name}</div>
          {loading && (
            <div className={styles["progress-percentage"]}>
              {progress.toFixed(2)}%
            </div>
          )}
        </div>
        <div className={styles["progress-bar-wrapper"]}>
          {loading && (
            <LinearProgress
              variant="determinate"
              color="success"
              value={progress}
            />
          )}
        </div>
      </div>
      <div className={styles["action-buttons-wrapper"]}>
        {!loading && canceled && (
          <span className={styles["file-canceled"]}>
            <FontAwesomeIcon icon={faCircleExclamation}></FontAwesomeIcon>
          </span>
        )}
        {!loading && !uploaded && !canceled && (
          <DeleteButton onClick={() => deleteFile(fileIndex)} />
        )}
        {loading && (
          <IconButton onClick={() => requestController.abort()}>
            <FontAwesomeIcon icon={faXmark}>Annuler</FontAwesomeIcon>
          </IconButton>
        )}
        {!loading && uploaded && (
          <span className={styles["files-uploaded"]}>
            <FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon>
          </span>
        )}
      </div>
    </div>
  );
}
