import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Breadcrumbs from "@mui/material/Breadcrumbs";
import axios from "axios";
import SubmitButton from "common/components/Button/SubmitButton";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import VersionForm from "common/containers/Forms/Version/VersionForm";
import { useAuth } from "common/hooks/useAuth";
import { useProject } from "common/hooks/useProject";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./EditVersionPage.module.css";

export default function EditVersionPage() {
  // Contexts
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { projectInfo } = useProject();

  // States
  const [loading, setLoading] = useState(false);
  const [allUsersInformation, setAllUsersInformation] = useState({});
  const [formData, setFormData] = useState(null);
  const [versionObject, setVersionObject] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { projectId, versionId } = useParams();

  // Form states
  const formId = useId();

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const [versionInfo, usersInfo] = await Promise.all([
          fetchVersionInformation(),
          fetchUsersInformation(),
        ]);
        setVersionObject(versionInfo);
        setAllUsersInformation(usersInfo);
      } catch (error) {
        toast.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (formData) {
      const payload = {
        name: formData.name,
        user_id: currentUser.uid,
        owner_ids: formData.owner_ids,
        authorized_user_ids: formData.authorized_user_ids,
      };
      updateVersion(payload);
    }
  }, [formData]);

  const fetchVersionInformation = async () => {
    try {
      const url = BACKEND_URL_CONFIG.getVersionInformation.replace(
        "{versionId}",
        versionId
      );
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      return response.data;
    } catch (error) {
      throw Error(ERRORS.error_143);
    }
  };

  const fetchUsersInformation = async () => {
    try {
      const response = await axios.get(BACKEND_URL_CONFIG.getAllUserNames, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      return response.data;
    } catch (error) {
      throw Error(ERRORS.error_133);
    }
  };

  const updateVersion = async (payload) => {
    const url = `${BACKEND_URL_CONFIG.updateVersion.replace(
      "{versionId}",
      versionId
    )}?project_id=${projectId}`;
    try {
      setLoading(true);
      const response = await axios.put(url, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response?.status === 200) {
        toast.success("La version a été modifiée.");
        navigate(`/projects/${projectId}`);
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_129);
        return;
      } else if (error.response.status === 409) {
        toast.error(
          "Ce nom est utilisé pour un autre projet. Veuillez choisir un autre."
        );
        return;
      }
      toast.error(ERRORS.error_140);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/projects"
                label="Projets"
              />
              <CustomBreadcrumb
                component="a"
                href={`/projects/${projectId}`}
                label={projectInfo?.name}
              />
              <CustomBreadcrumb label="Editer une version" href="#" />
            </Breadcrumbs>
          </div>
          <div className={styles["form-container"]}>
            <div className={styles["title-wrapper"]}>
              <div className={styles.title}>Modifier la version</div>
            </div>
            <div className={styles["form-wrapper"]}>
              <div className={styles["content"]}>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <VersionForm
                    formId={formId}
                    versionObject={versionObject}
                    setFormData={setFormData}
                    allUsersInformation={allUsersInformation}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </div>
            </div>
            <div className={styles["stepper-action-buttons-wrapper"]}>
              <SubmitButton
                disabled={isSubmitting}
                style={{ backgroundColor: "#142637cc", color: "white" }}
                formId={formId}
                value="Enregistrer"
              />
            </div>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
