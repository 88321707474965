import { useAuth } from "common/hooks/useAuth";

import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import { LinearProgress } from "@mui/joy";

import axios from "axios";
import SaveTopicsParamsForm from "common/containers/Forms/UploadFile/SaveTopicsParamsForm";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { Fragment } from "react";
import { toast } from "react-toastify";
import styles from "./AddTopicFilePage.module.css";

export default function AddTopicFilePage({ blockInformation }) {
  // Contexts
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const { projectId, versionId, sectionType } = useParams();

  // States
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);

  // Refs
  const formIdRef = useId();

  const saveTopicsParametersToDB = async (signal) => {
    const payload = {
      user_id: currentUser.uid,
      name: formData.label,
      description: formData.description,
      block_type: blockInformation.type,
      block_subtype: blockInformation.subtype,
      topics: blockInformation.parameters?.topics,
      is_certified: formData.is_certified,
    };
    setLoading(true);
    try {
      const response = await axios.post(
        BACKEND_URL_CONFIG.createTopicsFile,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: signal,
        }
      );
      if (response.status === 201) {
        toast.success("Le fichier a été enregistré");
        navigate(
          `/projects/${projectId}/version/${versionId}/run/${sectionType}`
        );
      }
    } catch (error) {
      toast.error(ERRORS.error_180);
      return;
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (formData) {
      saveTopicsParametersToDB(controller.signal);
    }
    return () => {
      controller.abort();
    };
  }, [formData]);

  return (
    <Fragment>
      <div className={styles["main-section"]}>
        <BlockFormContainer>
          <BlockFormTitleContainer
            title="Sauvegarder les paramètres"
            formId={formIdRef}
            loading={loading}
            isSubmitting={isSubmitting}
          />
          <BlockFormFieldsContainer>
            {loading ? (
              <LinearProgress />
            ) : (
              <SaveTopicsParamsForm
                setFormData={setFormData}
                formId={formIdRef}
                setIsSubmitting={setIsSubmitting}
              />
            )}
          </BlockFormFieldsContainer>
        </BlockFormContainer>
      </div>
    </Fragment>
  );
}
