import { useAuth } from "common/hooks/useAuth";

import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Breadcrumbs } from "@mui/material";
import axios from "axios";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import SaveTopicsParamsForm from "common/containers/Forms/UploadFile/SaveTopicsParamsForm";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment } from "react";
import { toast } from "react-toastify";
import styles from "./EditTopicFilePage.module.css";

export default function EditTopicFilePage() {
  // Contexts
  const { currentUser } = useAuth();
  const { topicFileId } = useParams();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [topicFileInformation, setTopicFileInformation] = useState(null);

  // Refs
  const formIdRef = useId();

  useEffect(() => {
    const controller = new AbortController();
    const fetchTopicFileInformation = async (controller) => {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getTopicsFileInformation.replace(
        "{topicFileId}",
        topicFileId
      );
      try {
        const response = await axios.get(url, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: controller.signal,
        });
        if (response.status === 200) {
          setTopicFileInformation(response.data);
        }
      } catch (error) {
        toast.error(ERRORS.error_166);
      } finally {
        setLoading(false);
      }
    };
    fetchTopicFileInformation(controller);
    return () => controller.abort();
  }, [currentUser.accessToken, topicFileId]);

  useEffect(() => {
    const updateTopicFile = async (payload, controller) => {
      setLoading(true);
      try {
        const url = BACKEND_URL_CONFIG.updateTopicsFile.replace(
          "{topicFileId}",
          topicFileId
        );
        const response = await axios.put(url, payload, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: controller.signal,
        });
        if (response.status === 200) {
          toast.success("Votre fichier de topic a été modifié");
          navigate("/topics");
        }
      } catch (error) {
        if (error.response.status === 409) {
          toast.error(ERRORS.error_171);
        } else {
          toast.error(ERRORS.error_165);
        }
      } finally {
        setLoading(false);
      }
    };

    const saveTopicFile = async (controller) => {
      const payload = {
        name: formData.label,
        description: formData.description,
        is_certified: formData.is_certified,
      };
      updateTopicFile(payload, controller);
    };

    const controller = new AbortController();
    if (formData) {
      saveTopicFile(controller);
    }
    return () => controller.abort();
  }, [formData, currentUser.accessToken, navigate, topicFileId]);

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb component="a" href="/topics" label="Topics" />
              <CustomBreadcrumb component="a" href="#" label="Modifier" />
            </Breadcrumbs>
          </div>
          <div className={styles["main-section"]}>
            <BlockFormContainer>
              <BlockFormTitleContainer
                title="Modifier un fichier de topics"
                formId={formIdRef}
                loading={loading}
                isSubmitting={isSubmitting}
              />
              <BlockFormFieldsContainer>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <SaveTopicsParamsForm
                    topicFileInformation={topicFileInformation}
                    setFormData={setFormData}
                    formId={formIdRef}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </BlockFormFieldsContainer>
            </BlockFormContainer>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
