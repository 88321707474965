import {
  faCirclePlus,
  faEllipsisVertical,
  faPenToSquare,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";
import Button from "common/components/Button/Button";
import LoadingSpinner from "common/components/LoadingSpinner";

import { faFolderOpen } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import CustomMenuButton from "common/components/Button/CustomMenuButton";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useAuth } from "common/hooks/useAuth";
import { useVersions } from "common/hooks/useVersions";
import { BACKEND_URL_CONFIG } from "config";
import {
  BLOCKS_SUBBLOCKS_REF,
  BLOCK_SUBTYPE_DISPLAY_NAME,
  BLOCK_TYPE_DISPLAY_NAME,
} from "core/blocks-config";
import { ERRORS } from "errors";
import WorkflowLayout from "layouts/WorkflowLayout";
import { Fragment, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./ShowBlocksPage.module.css";

export default function ShowBlocksPage() {
  // Context
  const { versionsInfo } = useVersions();
  const { currentUser } = useAuth();
  const { sectionType, versionId, projectId, blockType } = useParams();

  // States
  const [loading, setLoading] = useState(false);
  const [showInactiveBlocks, setShowInactiveBlocks] = useState(true);
  const potentialSubBlocks = BLOCKS_SUBBLOCKS_REF?.[sectionType]?.[blockType];

  const navigate = useNavigate();

  const sectionBlocks = Object.entries(
    versionsInfo?.blocks?.[sectionType] || {}
  ).reduce((acc, [eachBlockId, eachBlockInfo]) => {
    if (eachBlockInfo.type === blockType) {
      acc[eachBlockId] = eachBlockInfo;
    }
    return acc;
  }, {});

  const redirectToAddBlockPage = (subBlockType) => {
    navigate(
      `/projects/${projectId}/version/${versionId}/${sectionType}/add/${blockType}/${subBlockType}`
    );
  };

  const deleteButtonHandler = async (blockId) => {
    const payload = {
      version_id: versionId,
      block_id: blockId,
      section_type: sectionType,
      user_id: currentUser.uid,
      project_id: projectId,
    };
    setLoading(true);
    try {
      const response = await axios.delete(BACKEND_URL_CONFIG.deleteBlock, {
        data: payload,
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("Le block a été supprimé");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(
          "Vous n'êtes pas autorisé à supprimer des blocks. Contactez un administrateur."
        );
        return;
      }
      toast.error(ERRORS.error_124);
      return;
    } finally {
      setLoading(false);
    }
  };

  const activateBlockHandler = async (blockId) => {
    const payload = {
      version_id: versionId,
      block_id: blockId,
      section_type: sectionType,
      project_id: projectId,
      user_id: currentUser.uid,
    };
    try {
      const response = await axios.put(
        BACKEND_URL_CONFIG.activateBlock,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Le bloc a été activé");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(
          "Vous n'êtes pas autorisé à activer le bloc. Contactez un administrateur."
        );
        return;
      }
      toast.error(ERRORS.error_125);
      return;
    } finally {
      setLoading(false);
    }
  };

  const deactivateBlockHandler = async (blockId) => {
    const payload = {
      version_id: versionId,
      block_id: blockId,
      section_type: sectionType,
      project_id: projectId,
      user_id: currentUser.uid,
    };
    try {
      const response = await axios.put(
        BACKEND_URL_CONFIG.deactivateBlock,
        payload,
        {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        }
      );
      if (response.status === 200) {
        toast.success("Le bloc a été désactivé.");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(
          "Vous n'êtes pas autorisé à désactiver le bloc. Contactez un administrateur."
        );
        return;
      }
      toast.error(ERRORS.error_126);
      return;
    } finally {
      setLoading(false);
    }
  };

  const deleteAllBlocks = async () => {
    const payload = {
      version_id: versionId,
      project_id: projectId,
      user_id: currentUser.uid,
    };

    const url = BACKEND_URL_CONFIG.deleteAllBlock.replace(
      "{sectionType}",
      sectionType
    );
    setLoading(true);
    try {
      const response = await axios.delete(url, {
        data: payload,
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        toast.success("Les blocks ont été supprimés.");
        return;
      }
    } catch (error) {
      if (error.response.status === 403) {
        toast.error(ERRORS.error_144);
        return;
      }
      toast.error(ERRORS.error_124);
      return;
    } finally {
      setLoading(false);
    }
  };

  const redirectEditBlockPage = async (blockId, subBlockType) => {
    navigate(
      `/projects/${projectId}/version/${versionId}/${sectionType}/${subBlockType}/edit/${blockId}`
    );
  };

  return (
    <Fragment>
      <WorkflowLayout>
        <div className={styles.container}>
          <div className={styles["title-wrapper"]}>
            <div className={styles.title}>
              <span>
                Aperçu de la section :{" "}
                {BLOCK_TYPE_DISPLAY_NAME?.[blockType.toLowerCase()] ||
                  blockType.toLowerCase()}
              </span>
              <span className={styles.subtitle}>
                Vous pouvez les modifier, activer/desactiver ou supprimer.
                {loading && <LoadingSpinner />}
              </span>
            </div>

            <div className={styles["actions-buttons-wrapper"]}>
              {blockType === "lemmatizer" && (
                <Button
                  className={styles["new-block-button"]}
                  onClick={() => navigate("/lemmas")}
                >
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <span> Gestion des lemmes</span>
                </Button>
              )}
              {blockType === "remove_stopwords" && (
                <Button
                  className={styles["new-block-button"]}
                  onClick={() => navigate("/stopwords")}
                >
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <span> Gestion des stopwords</span>
                </Button>
              )}
              {blockType === "generate_wordclouds" && (
                <Button
                  className={styles["new-block-button"]}
                  onClick={() => navigate("/masks")}
                >
                  <FontAwesomeIcon icon={faFolderOpen} />
                  <span> Gestion des masques</span>
                </Button>
              )}
              {/* If there is more than one subtype, we will add a dropdown to our button. */}
              {potentialSubBlocks?.length > 1 ? (
                <Dropdown>
                  <CustomMenuButton title="Ajouter un nouveau block">
                    <span className={styles["new-block-button"]}>
                      <FontAwesomeIcon icon={faCirclePlus} />
                      <span> Nouveau</span>
                    </span>
                  </CustomMenuButton>
                  <Menu>
                    {potentialSubBlocks.map((item, index) => (
                      <MenuItem
                        disabled={
                          loading || versionsInfo?.running_job_ids?.length > 0
                        }
                        id={item}
                        onClick={() => redirectToAddBlockPage(item)}
                        key={index}
                      >
                        {BLOCK_TYPE_DISPLAY_NAME?.[item] || item}
                      </MenuItem>
                    ))}
                  </Menu>
                </Dropdown>
              ) : (
                <Button
                  className={styles["new-block-button"]}
                  disabled={
                    loading || versionsInfo?.running_job_ids?.length > 0
                  }
                  onClick={() => redirectToAddBlockPage(potentialSubBlocks[0])}
                >
                  <FontAwesomeIcon icon={faCirclePlus} />
                  <span> Nouveau</span>
                </Button>
              )}
              <Dropdown>
                <MenuButton style={{ padding: "11px 16px" }}>
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </MenuButton>
                <Menu>
                  {showInactiveBlocks ? (
                    <MenuItem
                      disabled={
                        loading || versionsInfo?.running_job_ids?.length > 0
                      }
                      onClick={() => setShowInactiveBlocks(!showInactiveBlocks)}
                    >
                      Cacher les blocks désactivés
                    </MenuItem>
                  ) : (
                    <MenuItem
                      disabled={
                        loading || versionsInfo?.running_job_ids?.length > 0
                      }
                      onClick={() => setShowInactiveBlocks(!showInactiveBlocks)}
                    >
                      Montrer les blocks désactivés
                    </MenuItem>
                  )}
                  <MenuItem
                    onClick={deleteAllBlocks}
                    disabled={
                      loading || versionsInfo?.running_job_ids?.length > 0
                    }
                  >
                    Supprimer tous les blocs
                  </MenuItem>
                </Menu>
              </Dropdown>
            </div>
          </div>
          <div className={styles["content-wrapper"]}>
            {Object.entries(sectionBlocks).length === 0 ? (
              <div className={styles["no-blocks-error-container"]}>
                <ItemNotFoundContainer message="Aucun bloc n’a été ajouté pour cette section. Veuillez en ajouter !" />
              </div>
            ) : (
              <>
                {Object.entries(sectionBlocks)
                  ?.sort((a, b) => b[1].order - a[1].order)
                  ?.map(
                    ([blockId, blockInformation]) =>
                      (showInactiveBlocks || blockInformation.active) && (
                        <div key={blockId} className={styles["row-container"]}>
                          <div className={styles["block-container"]}>
                            <div className={styles["block-informations"]}>
                              <div className={styles["block-name"]}>
                                {blockInformation.name}
                              </div>
                              <div
                                className={`${styles["label-wrapper"]} 
                                ${styles["block-type-tag"]}
                                ${styles["orange-tag"]}`}
                              >
                                {BLOCK_TYPE_DISPLAY_NAME?.[
                                  blockType.toLowerCase()
                                ] || blockType.toLowerCase()}
                              </div>
                              <div
                                className={`${styles["label-wrapper"]} ${
                                  blockInformation?.active
                                    ? styles["green-tag"]
                                    : styles["gray-tag"]
                                }`}
                              >
                                {blockInformation.active
                                  ? "ACTIVE"
                                  : "DESACTIVE"}
                              </div>
                              {BLOCK_SUBTYPE_DISPLAY_NAME[
                                blockInformation.subtype
                              ] && (
                                <div
                                  className={`${styles["label-wrapper"]} ${styles["pink-tag"]}`}
                                >
                                  {
                                    BLOCK_SUBTYPE_DISPLAY_NAME[
                                      blockInformation.subtype
                                    ]
                                  }
                                </div>
                              )}
                            </div>
                            <div className={styles["block-actions-buttons"]}>
                              <div>
                                <Button
                                  className={styles["edit-button"]}
                                  disabled={
                                    loading ||
                                    versionsInfo?.running_job_ids?.length > 0
                                  }
                                  onClick={() =>
                                    redirectEditBlockPage(
                                      blockId,
                                      blockInformation.subtype
                                    )
                                  }
                                >
                                  <FontAwesomeIcon icon={faPenToSquare} />
                                  <span> Modifier</span>
                                </Button>
                              </div>
                              <div>
                                <Button
                                  className={`${styles["delete-button"]}`}
                                  disabled={
                                    loading ||
                                    versionsInfo?.running_job_ids?.length > 0
                                  }
                                  onClick={() => deleteButtonHandler(blockId)}
                                >
                                  <FontAwesomeIcon icon={faTrashAlt} />
                                </Button>
                              </div>
                              <div>
                                <Dropdown>
                                  <MenuButton variant="outlined">
                                    <FontAwesomeIcon
                                      icon={faEllipsisVertical}
                                    />
                                  </MenuButton>
                                  <Menu>
                                    {blockInformation?.active ? (
                                      <MenuItem
                                        disabled={
                                          loading ||
                                          versionsInfo?.running_job_ids
                                            ?.length > 0
                                        }
                                        onClick={() =>
                                          deactivateBlockHandler(blockId)
                                        }
                                      >
                                        Désactiver le block
                                      </MenuItem>
                                    ) : (
                                      <MenuItem
                                        disabled={
                                          loading ||
                                          versionsInfo?.running_job_ids
                                            ?.length > 0
                                        }
                                        onClick={() =>
                                          activateBlockHandler(blockId)
                                        }
                                      >
                                        Activer le block
                                      </MenuItem>
                                    )}
                                  </Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                  )}
              </>
            )}
          </div>
        </div>
      </WorkflowLayout>
    </Fragment>
  );
}
