import ProjectProvider from "common/contexts/ProjectContext";
import AddLemmasFilePage from "pages/AddLemmasFilePage";
import AddMasksFilePage from "pages/AddMasksFilePage";
import AddStopwordsFilePage from "pages/AddStopwordsFilePage";
import EditBlocks from "pages/EditBlocks";
import EditDatasetPage from "pages/EditDatasetPage";
import EditLemmasFilePage from "pages/EditLemmasFilePage";
import EditMasksFilePage from "pages/EditMasksFilePage";
import EditParametersFilePage from "pages/EditParametersFilePage";
import EditStopwordsFilePage from "pages/EditStopwordsFilePage";
import EditTopicFilePage from "pages/EditTopicFilePage";
import EditUserPage from "pages/EditUserPage";
import EditVersionPage from "pages/EditVersionPage";
import ForkVersionPage from "pages/ForkVersionPage";
import JobsPage from "pages/JobsPage";
import LemmasManagementPage from "pages/LibrariesPages/LemmasManagementPage";
import MasksManagementPage from "pages/LibrariesPages/MasksManagementPage";
import ParametersManagementPage from "pages/LibrariesPages/ParametersManagementPage";
import StopwordsManagementPage from "pages/LibrariesPages/StopwordsManagementPage";
import TopicsManagementPage from "pages/LibrariesPages/TopicsManagementPage";
import NewProjectPage from "pages/NewProjectPage";
import RunBlocksPage from "pages/RunBlocksPage";
import ShowBlockOutputPage from "pages/ShowBlockOutputPage";
import ShowBlocksPage from "pages/ShowBlocksPage";
import UsersPage from "pages/UsersPage";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Bounce, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Fragment } from "react/jsx-runtime";
import ProtectedForAdmins from "routers/ProtectedForAdmins";
import ProtectedForAdminsOrManager from "routers/ProtectedForAdminsOrManager";
import ProtectedProject from "routers/ProtectedProject";
import ProtectedVersion from "routers/ProtectedVersion";
import "./App.css";
import AuthProvider from "./common/contexts/AuthContext";
import StepperProvider from "./common/contexts/StepperContext";
import VersionsProvider from "./common/contexts/VersionsContext";
import AddBlocks from "./pages/AddBlocks";
import DatasetsPage from "./pages/DatasetsPage";
import EditProjectPage from "./pages/EditProjectPage";
import Homepage from "./pages/Homepage";
import LoginPage from "./pages/LoginPage";
import NewDatasetPage from "./pages/NewDatasetPage";
import NewVersionPage from "./pages/NewVersionPage";
import NotFoundPage from "./pages/NotFoundPage";
import ProjectsPage from "./pages/ProjectsPage";
import ProjectVersionsPage from "./pages/ProjectVersionsPage";
import PrivateRoute from "./routers/PrivateRoute";
import ProtectedStepper from "./routers/ProtectedStepper";
function App() {
  return (
    <Fragment>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        theme="light"
        pauseOnHover
        transition={Bounce}
      />
      <BrowserRouter basename="/">
        <AuthProvider>
          <Routes>
            <Route path="*" element={<NotFoundPage />}></Route>
            <Route path="/login" element={<LoginPage />}></Route>
            <Route element={<PrivateRoute />}>
              <Route path="/" element={<Homepage />}></Route>
              <Route element={<ProtectedForAdmins />}>
                {/* Jobs Page */}
                <Route path="/jobs" element={<JobsPage />} />
                {/* Users page */}
                <Route path="/users" element={<UsersPage />}></Route>
                <Route
                  path="/users/edit/:userId"
                  element={<EditUserPage />}
                ></Route>
              </Route>
              {/* Datasets */}
              <Route element={<StepperProvider />}>
                <Route
                  path="/datasets/new"
                  element={<NewDatasetPage />}
                ></Route>
                <Route element={<ProtectedStepper />}>
                  <Route
                    path="datasets/:datasetId/step/:stepNumber"
                    element={<EditDatasetPage />}
                  ></Route>
                </Route>
              </Route>
              <Route path="/datasets" element={<DatasetsPage />}></Route>
              {/* Projects */}
              <Route path="/projects" element={<ProjectsPage />}></Route>
              <Route path="/projects/new" element={<NewProjectPage />}></Route>
              <Route element={<ProtectedProject />}>
                <Route
                  path="/projects/edit/:projectId"
                  element={<EditProjectPage />}
                ></Route>
              </Route>
              <Route element={<ProtectedProject />}>
                <Route element={<ProtectedVersion />}>
                  <Route element={<ProjectProvider />}>
                    <Route
                      path="/projects/edit/:projectId/version/:versionId"
                      element={<EditVersionPage />}
                    ></Route>
                  </Route>
                </Route>
                <Route element={<ProtectedProject />}>
                  <Route element={<ProjectProvider />}>
                    <Route
                      path="/projects/:projectId/new-version"
                      element={<NewVersionPage />}
                    ></Route>
                  </Route>
                </Route>
                <Route element={<ProtectedProject />}>
                  <Route element={<ProtectedVersion />}>
                    <Route element={<ProjectProvider />}>
                      <Route element={<VersionsProvider />}>
                        <Route
                          path="/projects/:projectId/fork-version/:versionId"
                          element={<ForkVersionPage />}
                        ></Route>
                      </Route>
                    </Route>
                  </Route>
                </Route>
              </Route>
              <Route element={<ProtectedProject />}>
                <Route element={<ProjectProvider />}>
                  <Route
                    path="/projects/:projectId"
                    element={<ProjectVersionsPage />}
                  ></Route>
                </Route>
              </Route>
              <Route element={<ProtectedProject />}>
                <Route element={<ProtectedVersion />}>
                  <Route element={<VersionsProvider />}>
                    <Route
                      path="/projects/:projectId/version/:versionId"
                      element={<AddBlocks />}
                    ></Route>
                    {/* To add blocks */}
                    <Route
                      path="/projects/:projectId/version/:versionId/:sectionType/add/:blockType/:subBlockType"
                      element={<AddBlocks />}
                    />
                    <Route
                      path="/projects/:projectId/version/:versionId/:sectionType/add"
                      element={<AddBlocks />}
                    />
                    {/* To edit blocks */}
                    <Route
                      path="/projects/:projectId/version/:versionId/:sectionType/:subBlockType/edit/:blockId"
                      element={<EditBlocks />}
                    />
                    {/* Run blocks page */}
                    <Route
                      path="/projects/:projectId/version/:versionId/run/:sectionType/"
                      element={<RunBlocksPage />}
                    />
                    {/* Show blocks page */}
                    <Route
                      path="/projects/:projectId/version/:versionId/show/:sectionType/:blockType"
                      element={<ShowBlocksPage />}
                    />
                  </Route>
                </Route>
              </Route>
              {/* Stopwords management */}
              <Route path="/stopwords" element={<StopwordsManagementPage />} />
              <Route path="/stopwords/add" element={<AddStopwordsFilePage />} />
              <Route element={<ProtectedForAdminsOrManager />}>
                <Route
                  path="/stopwords/edit/:stopwordsFileId"
                  element={<EditStopwordsFilePage />}
                />
              </Route>
              {/* Lemmas management */}
              <Route path="/lemmas" element={<LemmasManagementPage />} />
              <Route path="/lemmas/add" element={<AddLemmasFilePage />} />
              <Route element={<ProtectedForAdminsOrManager />}>
                <Route
                  path="/lemmas/edit/:lemmaFileId"
                  element={<EditLemmasFilePage />}
                />
              </Route>
              {/* Masks management */}
              <Route path="/masks" element={<MasksManagementPage />} />
              <Route path="/masks/add" element={<AddMasksFilePage />} />
              <Route element={<ProtectedForAdminsOrManager />}>
                <Route
                  path="/masks/edit/:masksFileId"
                  element={<EditMasksFilePage />}
                />
              </Route>
              {/* Parameters management */}
              <Route
                path="/parameters"
                element={<ParametersManagementPage />}
              />
              <Route element={<ProtectedForAdminsOrManager />}>
                <Route
                  path="/parameters/edit/:parametersFileId"
                  element={<EditParametersFilePage />}
                />
              </Route>
              {/* Topic management */}
              <Route path="/topics" element={<TopicsManagementPage />} />
              <Route element={<ProtectedForAdminsOrManager />}>
                <Route
                  path="/topics/edit/:topicFileId"
                  element={<EditTopicFilePage />}
                />
              </Route>
              {/* Show block output for process blocks */}
              <Route element={<ProjectProvider />}>
                <Route element={<VersionsProvider />}>
                  <Route
                    path="/show/output/:sectionType/:blockType/:projectId/:versionId"
                    element={<ShowBlockOutputPage />}
                  />
                </Route>
              </Route>
            </Route>
          </Routes>
        </AuthProvider>
      </BrowserRouter>
    </Fragment>
  );
}

export default App;
