import { useAuth } from "common/hooks/useAuth";

import { useCallback, useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import Switch from "common/components/Switch";
import TopicModelingOpenAIForm from "common/containers/Forms/Blocks/TopicModelingOpenAIForm";
import { BACKEND_URL_CONFIG } from "config";
import { BLOCK_TYPE_DISPLAY_NAME } from "core/blocks-config";
import { ERRORS } from "errors";
import { toast } from "react-toastify";

export default function AddTopicModelingOpenAI() {
  // Contexts
  const { sectionType, projectId, versionId, blockType, subBlockType } =
    useParams();
  const { currentUser } = useAuth();
  const navigate = useNavigate();

  // States
  const [needAdvancedOptions, setNeedAdvancedOptions] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [createNewBlockFromSavedTopics, setCreateNewBlockFromSavedTopics] =
    useState(false);

  // Refs
  const formIdRef = useId();

  const saveBlockIntoDB = useCallback(
    async (payload, controller) => {
      const addBlockEndpoint = createNewBlockFromSavedTopics
        ? BACKEND_URL_CONFIG.addBlockFromSavedTopics
        : BACKEND_URL_CONFIG.addBlock;
      try {
        setLoading(true);
        const response = await axios.post(addBlockEndpoint, payload, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
          signal: controller.signal,
        });
        if (response.status === 201) {
          toast.success("Votre block a été enregistré");
          navigate(
            `/projects/${projectId}/version/${versionId}/show/${sectionType}/${blockType}`
          );
        }
      } catch (error) {
        if (
          error.response.status === 400 &&
          error.response.data.detail.includes("ERR_1053")
        ) {
          toast.error(ERRORS.error_145);
        } else if (error.response.status === 403) {
          toast.error(ERRORS.error_129);
          return;
        } else {
          toast.error(ERRORS.error_127);
        }
      } finally {
        setLoading(false);
      }
    },
    [
      createNewBlockFromSavedTopics,
      navigate,
      projectId,
      versionId,
      currentUser,
      blockType,
      sectionType,
    ]
  );

  useEffect(() => {
    const controller = new AbortController();
    if (formData) {
      const payload = {
        type: blockType,
        subtype: subBlockType,
        section_type: sectionType,
        name: formData.blockDisplayName,
        version_id: versionId,
        project_id: projectId,
        user_id: currentUser.uid,
        parameters: formData.parameters,
      };
      saveBlockIntoDB(payload, controller);
    }
    return () => controller.abort();
  }, [
    formData,
    sectionType,
    blockType,
    subBlockType,
    versionId,
    projectId,
    currentUser,
    saveBlockIntoDB,
  ]);

  return (
    <BlockFormContainer>
      <BlockFormTitleContainer
        title={`Ajouter un bloc : ${BLOCK_TYPE_DISPLAY_NAME[subBlockType]}`}
        formId={formIdRef}
        loading={loading}
        createNewBlockFromSavedParams={createNewBlockFromSavedTopics}
        needAdvancedOptions={needAdvancedOptions}
        isSubmitting={isSubmitting}
        setNeedAdvancedOptions={setNeedAdvancedOptions}
      />
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "5px",
          marginBottom: "10px",
          justifyContent: "flex-end",
        }}
      >
        <Switch
          onChange={() =>
            setCreateNewBlockFromSavedTopics(!createNewBlockFromSavedTopics)
          }
        >
          À partir des param. sauvegardés
        </Switch>
      </div>
      <BlockFormFieldsContainer>
        {loading ? (
          <LoadingSpinner iconCustomStyle={{ fontSize: "25px" }} />
        ) : (
          <TopicModelingOpenAIForm
            setFormData={setFormData}
            formId={formIdRef}
            needAdvancedOptions={needAdvancedOptions}
            defaultBlockDisplayName={subBlockType}
            setIsSubmitting={setIsSubmitting}
            createNewBlockFromSavedTopics={createNewBlockFromSavedTopics}
          />
        )}
      </BlockFormFieldsContainer>
    </BlockFormContainer>
  );
}
