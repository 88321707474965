import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import Stepper from "common/containers/Stepper";
import { useAuth } from "common/hooks/useAuth";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./NewDataset.module.css";
import CreateDataset from "./containers/CreateDataset";

export default function NewDataset() {
  const { isUserManager, isUserAdmin, currentUser } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isUserManager && !isUserAdmin) {
      navigate("/datasets");
      toast.error(ERRORS.error_129);
      return;
    }
  }, [isUserManager, isUserAdmin, navigate, currentUser]);

  const initStep = 1;
  const steps = [
    "Création d'un dataset",
    "Uplaod vos fichiers",
    "Parametres des fichiers",
    "Configuration des colonnes",
  ];

  if (isUserManager || isUserAdmin) {
    return (
      <Fragment>
        <MainLayout>
          <div className={styles.container}>
            <div className={styles["breadcrumbs-wrapper"]}>
              <Breadcrumbs separator="›" aria-label="breadcrumb">
                <CustomBreadcrumb
                  component="a"
                  href="/"
                  label="Page d'accueil"
                  icon={<FontAwesomeIcon icon={faHome} />}
                />
                <CustomBreadcrumb
                  component="a"
                  href="/datasets"
                  label="Datasets"
                />
                <CustomBreadcrumb label="Nouveau dataset" href="#" />
              </Breadcrumbs>
            </div>
            <div className={styles["stepper-wrapper"]}>
              <Stepper currentStep={initStep} steps={steps} />
            </div>
            <div>
              <CreateDataset title="Création d'un dataset" />
            </div>
          </div>
        </MainLayout>
      </Fragment>
    );
  }
}
