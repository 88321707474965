import { db } from "core/firebase";
import { doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { createContext, useEffect, useMemo, useState } from "react";
import { Outlet, useParams } from "react-router-dom";

export const StepperContext = createContext();

const StepperProvider = () => {
  const [loading, setLoading] = useState(true);
  const [datasetInfo, setDatasetInfo] = useState(null);
  const { datasetId } = useParams();
  const [previewArray, setPreviewArray] = useState([]);

  const memoizedPreviewArray = useMemo(() => {
    return Object.entries(previewArray)?.sort((a, b) => a[1].id - b[1].id);
  }, [previewArray]);

  useEffect(() => {
    // If the datasetId is undefined, that means we're creating a new dataset
    // because datasetId information comes from the URL
    if (datasetId === undefined) {
      setLoading(false);
      return;
    }
    // Connection to db with real time option
    // The goal is to update the frontend on real-time for verbatim preview table
    // when the user changes a parameter and refresh ingest dataset function
    const docRef = doc(db, "datasets", datasetId);
    const unsubscribe = onSnapshot(docRef, (doc) => {
      const document = doc.data();
      setDatasetInfo(document);
      setPreviewArray(document?.preview);
      setLoading(false);
    });
    return unsubscribe;
  }, [datasetId]);

  const updateCurrentStep = async (currentStep, datasetIdToUpdate) => {
    const datasetRef = doc(db, "datasets", datasetIdToUpdate);
    const docRef = await getDoc(datasetRef);

    // Update the document if it exists
    if (docRef.exists()) {
      await updateDoc(datasetRef, {
        current_step: currentStep,
      });
    }
  };

  const updateSensitiveColumns = async (
    sensitiveColumnsIdsList,
    datasetIdToUpdate
  ) => {
    const datasetRef = doc(db, "datasets", datasetIdToUpdate);
    const docRef = await getDoc(datasetRef);

    if (docRef.exists()) {
      const documentData = docRef.data();

      // We update the document with the new set of sensitive columns
      await updateDoc(datasetRef, {
        sensitive_columns: sensitiveColumnsIdsList,
      });

      const allColumns = documentData.stats.columns;

      // We update the columns with the new sensitive status
      const columnsToUpdate = Object.entries(allColumns).reduce(
        (acc, [columnId, columnInfo]) => {
          let fieldToUpdate = `stats.columns.${columnId}`;
          let sensitive = sensitiveColumnsIdsList.includes(columnId);
          return {
            ...acc,
            [fieldToUpdate]: {
              ...columnInfo,
              sensitive: sensitive,
            },
          };
        },
        {}
      );
      await updateDoc(datasetRef, columnsToUpdate);
      return true;
    }
  };

  const value = {
    datasetInfo,
    updateCurrentStep,
    updateSensitiveColumns,
    memoizedPreviewArray,
  };

  return (
    <StepperContext.Provider value={value}>
      {!loading && <Outlet />}
    </StepperContext.Provider>
  );
};

export default StepperProvider;
