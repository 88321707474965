import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./TopicDetectionAnalysis.module.css";

export default function TopicDetectionAnalysisContainer() {
  // States
  const [detectedTopics, setDetectedTopics] = useState([]);
  const [loading, setLoading] = useState(false);

  //   Contexts
  const { currentUser } = useAuth();
  const { projectId, versionId } = useParams();

  useEffect(() => {
    const fetchDetectedTopics = async (currentUser) => {
      try {
        setLoading(true);
        const url = BACKEND_URL_CONFIG.getDetectedTopics
          .replace("{projectId}", projectId)
          .replace("{versionId}", versionId);
        const response = await axios.get(url, {
          headers: {
            "X-KEY": currentUser.accessToken,
          },
        });
        if (response.status === 200) {
          setDetectedTopics(response.data || []);
        }
      } catch (error) {
        toast.error(ERRORS.error_183);
      } finally {
        setLoading(false);
      }
    };

    fetchDetectedTopics(currentUser);
  }, [currentUser, projectId, versionId]);

  return (
    <div className={styles["topic-detection-analysis-container"]}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {detectedTopics && detectedTopics.length === 0 ? (
            <ItemNotFoundContainer message="Aucune analyse n’a été trouvée." />
          ) : (
            <>
              <div className={styles["topic-blocks-wrapper"]}>
                {detectedTopics?.map((topic, index) => (
                  <TopicBlock key={index} topic={topic} />
                ))}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

function TopicBlock({ topic }) {
  return <div className={styles["topic-block"]}>{topic}</div>;
}
