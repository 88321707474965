import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styles from "./SuccessIcon.module.css";

export default function SuccessIcon({ className }) {
  const customClassName = className ? className : styles["success-icon-style"];
  return (
    <>
      <FontAwesomeIcon icon={faCircleCheck} className={customClassName} />
    </>
  );
}
