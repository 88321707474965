import {
  faEllipsisVertical,
  faEyeSlash,
  faGear,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Dropdown, Menu, MenuButton, MenuItem } from "@mui/joy";

import Button from "common/components/Button/Button";
import SubmitButton from "common/components/Button/SubmitButton";
import LoadingSpinner from "common/components/LoadingSpinner";
import styles from "./BlockFormLayout.module.css";

export default function BlockFormContainer({ children }) {
  return <div className={styles.container}>{children}</div>;
}

export function BlockFormTitleContainer({
  title,
  formId,
  loading,
  needAdvancedOptions,
  createNewBlockFromSavedParams,
  isSubmitting,
  setNeedAdvancedOptions,
  isModalOpen,
  setIsModalOpen,
}) {
  const isModalNeeded =
    isModalOpen !== undefined && setIsModalOpen !== undefined;

  return (
    <div className={styles["title-wrapper"]}>
      <div className={styles.title}>
        <span>{title}</span>
        {loading && <LoadingSpinner />}
      </div>

      <div className={styles["actions-buttons-wrapper"]}>
        {/* We don't need advanced options if it's about a creation from saved params */}
        {!createNewBlockFromSavedParams &&
          // If advanced options are needed
          // because we don't need advanced options for each form
          needAdvancedOptions !== undefined && (
            <Button
              className={styles["advanced-options-button"]}
              onClick={() => setNeedAdvancedOptions(!needAdvancedOptions)}
            >
              {!needAdvancedOptions ? (
                <>
                  <span style={{ margin: "0 5px" }}>
                    <FontAwesomeIcon icon={faGear} />
                  </span>
                  <span>Options avancées</span>
                </>
              ) : (
                <>
                  <span style={{ margin: "0 5px" }}>
                    <FontAwesomeIcon icon={faEyeSlash} />
                  </span>
                  <span>Cacher</span>
                </>
              )}
            </Button>
          )}
        <SubmitButton
          disabled={isSubmitting}
          formId={formId}
          value="Enregistrer"
        />
        {isModalNeeded && (
          <div>
            <Dropdown>
              <MenuButton variant="outlined" style={{ padding: "11px 16px" }}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
              </MenuButton>
              <Menu>
                <MenuItem onClick={() => setIsModalOpen(!isModalOpen)}>
                  Enregistrer les paramètres
                </MenuItem>
              </Menu>
            </Dropdown>
          </div>
        )}
      </div>
    </div>
  );
}

export function BlockFormFieldsContainer({ children }) {
  return (
    <div className={styles["form-container"]}>
      <div className={styles["form-container__fields"]}>{children}</div>
    </div>
  );
}
