import Navbar from "common/containers/Navbar/Navbar";
import { Fragment } from "react";

const MainLayout = ({ children }) => {
  return (
    <Fragment>
      <Navbar />
      <main>
        <div
          style={{
            width: "100%",
            display: "flex",
            maxWidth: "1300px",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {children}
        </div>
      </main>
    </Fragment>
  );
};

export default MainLayout;
