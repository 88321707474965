import { Option, Select } from "@mui/joy";
import axios from "axios";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ClearButton from "common/components/Button/ClearButton";
import { useAuth } from "common/hooks/useAuth";
import { useVersions } from "common/hooks/useVersions";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import styles from "./SentimentAnalysisAWSForm.module.css";

const customSelectStyle = {
  "--Select-placeholderOpacity": "unset",
  backgroundColor: "white",
  fontSize: "13px",
  fontFamily: "inherit",
};

export default function SentimentAnalysisAWSForm({
  block,
  formId,
  setFormData,
  setIsSubmitting,
  defaultBlockDisplayName,
}) {
  // States
  const [loading, setLoading] = useState(false);
  const [availableColumnsInfo, setAvailableColumnsInfo] = useState(null);

  // Contexts
  const { versionsInfo } = useVersions();
  const { currentUser } = useAuth();
  const { versionId } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    formState: { isSubmitting },
    control,
  } = useForm({
    defaultValues: {
      blockDisplayName: block ? block.name : defaultBlockDisplayName,
      language_column_id: block ? block.parameters.language_column_id : null,
      verbatim_column_id: block ? block.parameters.verbatim_column_id : null,
    },
  });

  useEffect(() => {
    if (!block && versionsInfo?.parameters?.language_column_id) {
      setValue(
        "language_column_id",
        versionsInfo.parameters.language_column_id
      );
    }
  }, [versionsInfo, block, setValue]);

  useEffect(() => {
    fetchAvailableColumns();
  }, []);

  useEffect(() => {
    setIsSubmitting(isSubmitting);
  }, [isSubmitting]);

  const onSubmit = (data) => {
    const submittedData = {
      parameters: {
        model: data.model,
        language_column_id: data.language_column_id,
        language_column_id_set_by_user: !!data.language_column_id,
        verbatim_column_id: data.verbatim_column_id,
        verbatim_column_id_set_by_user: !!data.verbatim_column_id,
      },
      blockDisplayName: data.blockDisplayName,
    };

    setFormData(submittedData);
  };

  const fetchAvailableColumns = async () => {
    setLoading(true);
    const url = BACKEND_URL_CONFIG.getAvailableColumnsInfo.replace(
      "{versionId}",
      versionId
    );
    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setAvailableColumnsInfo(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_130);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form
      id={formId}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.container}
    >
      <div className={styles["field-container"]}>
        <label className={styles.label}>Nom du bloc</label>
        <input
          {...register("blockDisplayName", { required: true })}
          type="text"
          required={true}
          className={styles["text-input"]}
          placeholder="Saisir le nom de votre bloc"
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Colonne des verbatims</label>
        <Controller
          name="verbatim_column_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisir la colonne des verbatims..."
              style={customSelectStyle}
              disabled={loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              {...(field.value && {
                endDecorator: (
                  <ClearButton
                    onClick={() => {
                      field.onChange(null);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ClearButton>
                ),
                indicator: null,
              })}
            >
              {Object.entries(availableColumnsInfo || {}).map(
                ([columnId, columnName]) => (
                  <Option key={columnId} value={columnId}>
                    {columnName}
                  </Option>
                )
              )}
            </Select>
          )}
        />
      </div>
      <div className={styles["field-container"]}>
        <label className={styles.label}>Colonne de langue à choisir</label>
        <Controller
          name="language_column_id"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Select
              {...field}
              placeholder="Choisir la colonne de langue..."
              style={customSelectStyle}
              required={true}
              disabled={loading}
              onChange={(e, newValue) => {
                field.onChange(newValue);
              }}
              {...(field.value && {
                endDecorator: (
                  <ClearButton
                    onClick={() => {
                      field.onChange(null);
                    }}
                  >
                    <FontAwesomeIcon icon={faXmark} />
                  </ClearButton>
                ),
                indicator: null,
              })}
            >
              {Object.entries(availableColumnsInfo || {}).map(
                ([columnId, columnName]) => (
                  <Option key={columnId} value={columnId}>
                    {columnName}
                  </Option>
                )
              )}
            </Select>
          )}
        />
      </div>
    </form>
  );
}
