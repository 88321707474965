import axios from "axios";
import LoadingSpinner from "common/components/LoadingSpinner";
import ItemNotFoundContainer from "common/containers/ItemNotFoundContainer";
import TabsContainer from "common/containers/Tabs";
import { useAuth } from "common/hooks/useAuth";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import styles from "./GlobalStatsAnalysis.module.css";

const STATS_CATEGORY_REFS = {
  chars_per_document: "Caractères par document",
  words_per_document: "Mots par document",
  chars_per_verbatim: "Caractères par verbatim",
  words_per_verbatim: "Mots par verbatim",
  documents_per_verbatim: "Documents par verbatim",
};

const STATS_TYPE_REFS = {
  mean: "Moyenne",
  std: "Écart type",
  median: "Médiane",
  min: "Minimum",
  max: "Maximum",
  d1: "D1",
  d9: "D9",
};

export default function GlobalStatsAnalysisContainer() {
  // States
  const [currentTab, setCurrentTab] = useState(0);
  const [globalStats, setGlobalStats] = useState([]);
  const [loading, setLoading] = useState(false);

  //   Contexts
  const { currentUser } = useAuth();
  const { projectId, versionId } = useParams();

  // Memo
  const filteredGlobalStats = useMemo(() => {
    const keys = Object.keys(globalStats);
    return globalStats?.[keys[currentTab]];
  }, [currentTab, globalStats]);

  useEffect(() => {
    fetchGlobalStats();
  }, []);

  const fetchGlobalStats = async () => {
    try {
      setLoading(true);
      const url = BACKEND_URL_CONFIG.getGlobalStats
        .replace("{projectId}", projectId)
        .replace("{versionId}", versionId);
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
      });
      if (response.status === 200) {
        setGlobalStats(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_183);
    } finally {
      setLoading(false);
    }
  };

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <div className={styles["global-stats-container"]}>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <>
          {Object.keys(globalStats || {}).length === 0 ? (
            <ItemNotFoundContainer message="Aucune analyse n’a été trouvée." />
          ) : (
            <>
              <TabsContainer
                tabsData={Object.keys(globalStats)}
                handleTabChange={handleTabChange}
              />
              <div className={styles["global-stats-wrapper"]}>
                {Object.entries(filteredGlobalStats || {})?.map(
                  ([categoryName, data], index) => (
                    <BoxPlot
                      key={index}
                      categoryName={categoryName}
                      data={data}
                    />
                  )
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

function BoxPlot({ categoryName, data }) {
  return (
    <div className="stats-container">
      <div className="stat-card" key={categoryName}>
        <h3>{STATS_CATEGORY_REFS[categoryName]}</h3>
        <div className="stat-values">
          {Object.entries(data).map(([key, value]) => (
            <div className="stat-value" key={key}>
              <span className="stat-key">{STATS_TYPE_REFS[key]} : </span>
              <span className="stat-value-number">{value.toFixed(2)}</span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
