import { useAuth } from "common/hooks/useAuth";

import { useEffect, useId, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import BlockFormContainer, {
  BlockFormFieldsContainer,
  BlockFormTitleContainer,
} from "layouts/BlockFormLayout";

import { faHome } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Breadcrumbs } from "@mui/material";
import axios from "axios";
import CustomBreadcrumb from "common/components/CustomBreadcrumb";
import LoadingSpinner from "common/components/LoadingSpinner";
import UserForm from "common/containers/Forms/Users";
import { BACKEND_URL_CONFIG } from "config";
import { ERRORS } from "errors";
import MainLayout from "layouts/MainLayout";
import { Fragment } from "react";
import { toast } from "react-toastify";
import styles from "./EditUserPage.module.css";

export default function EditUserPage() {
  // Contexts
  const { currentUser } = useAuth();
  const { userId } = useParams();
  const navigate = useNavigate();

  // States
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState(null);
  const [userInformation, setUserInformation] = useState(null);

  // Refs
  const formIdRef = useId();

  useEffect(() => {
    const controller = new AbortController();
    fetchUserInformation(controller);
    return () => controller.abort();
  }, []);

  const fetchUserInformation = async (controller) => {
    setLoading(true);
    const url = BACKEND_URL_CONFIG.getUserInformation.replace(
      "{userId}",
      userId
    );
    try {
      const response = await axios.get(url, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
        signal: controller.signal,
      });
      if (response.status === 200) {
        setUserInformation(response.data);
      }
    } catch (error) {
      toast.error(ERRORS.error_174);
    } finally {
      setLoading(false);
    }
  };

  const saveUser = async (controller) => {
    const payload = {
      firstname: formData.firstname,
      lastname: formData.lastname,
      display_name: formData.display_name,
      email: formData.email,
      is_admin: formData.is_admin,
      role: formData.role,
      available_credits: formData.available_credits,
    };
    updateUser(payload, controller);
  };

  const updateUser = async (payload, controller) => {
    setLoading(true);
    try {
      const url = BACKEND_URL_CONFIG.updateUser.replace("{userId}", userId);
      const response = await axios.put(url, payload, {
        headers: {
          "X-KEY": currentUser.accessToken,
        },
        signal: controller.signal,
      });
      if (response.status === 200) {
        toast.success("Votre utilisateur a été modifié");
        navigate("/users");
      }
    } catch (error) {
      toast.error(ERRORS.error_173);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (formData) {
      saveUser(controller);
    }
    return () => controller.abort();
  }, [formData]);

  return (
    <Fragment>
      <MainLayout>
        <div className={styles.container}>
          <div className={styles["breadcrumbs-wrapper"]}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <CustomBreadcrumb
                component="a"
                href="/"
                label="Page d'accueil"
                icon={<FontAwesomeIcon icon={faHome} />}
              />
              <CustomBreadcrumb
                component="a"
                href="/users"
                label="Utilisateurs"
              />
              <CustomBreadcrumb component="a" href="#" label="Modifier" />
            </Breadcrumbs>
          </div>
          <div className={styles["main-section"]}>
            <BlockFormContainer>
              <BlockFormTitleContainer
                title="Modifier l'utilisateur"
                formId={formIdRef}
                loading={loading}
                isSubmitting={isSubmitting}
              />
              <BlockFormFieldsContainer>
                {loading ? (
                  <LoadingSpinner />
                ) : (
                  <UserForm
                    user={userInformation}
                    setFormData={setFormData}
                    formId={formIdRef}
                    setIsSubmitting={setIsSubmitting}
                  />
                )}
              </BlockFormFieldsContainer>
            </BlockFormContainer>
          </div>
        </div>
      </MainLayout>
    </Fragment>
  );
}
